import React from 'react';
import CHLandingAction from './CHLandingAction';
import {
  CHScenes,
  StreamConnectionStatus,
  StreamPlayerStatus
} from '../PSInterface';
import CHLandingVideo from './CHLandingVideo';
import { useDispatch } from 'react-redux';
import { actionDidGoToScene } from '../../../redux/actions';
import CHLoadingIcon from './CHLoadingIcon';
import { landingVideoUrl, landingVideoUrlPortrait } from './CHConfig';
import { logEvent } from '../../../analytics';
import { DID_GO_TO_SCENE, VIDEO_ERROR } from '../../../utils/constants';
import { requestFullScreen } from '../../../utils/window';

let skipVideoTimeout;

export enum LandingFrame {
  LanguageOption = 'LanguageOption',
  Intro = 'Intro',
  TurnLeft = 'TurnLeft',
  TurnRight = 'TurnRight',
  ZoomIn = 'ZoomIn',
  ZoomOut = 'ZoomOut',
  Outro = 'Outro'
}

const landingFrameSequence = [
  LandingFrame.LanguageOption,
  LandingFrame.Intro,
  LandingFrame.Outro
];

const CHLanding = ({
  connectToStream,
  onClose,
  streamStatus,
  skipTutorial,
  isPortrait = true,
  entrance,
  playerStatus,
  delayFadeOut
}: {
  connectToStream: () => void;
  onClose: () => void;
  streamStatus?: StreamConnectionStatus;
  skipTutorial: boolean;
  isPortrait?: boolean;
  entrance: () => void;
  playerStatus?: StreamPlayerStatus;
  delayFadeOut?: boolean;
}) => {
  const videoUrls = isPortrait ? landingVideoUrlPortrait : landingVideoUrl;

  const dispatch = useDispatch();
  const [landingFrame, setLandingFrame] = React.useState(
    landingFrameSequence[0]
  );
  const [nextLandingFrame, setNextLandingFrame] =
    React.useState<LandingFrame>(null);

  const [shouldSkipVideo, setShouldSkipVideo] = React.useState(skipTutorial);
  const [showAction, setShowAction] = React.useState(false);
  const [loadedVideos, setLoadedVideos] = React.useState(0);

  const canPlay = loadedVideos === landingFrameSequence.length;

  const [isExiting, setIsExiting] = React.useState(false);
  const isConnecting = streamStatus === StreamConnectionStatus.CONNECTING;
  const isStreamPlaying = playerStatus === StreamPlayerStatus.PLAYING;

  React.useEffect(() => {
    setLoadedVideos(0);

    if (skipVideoTimeout) {
      clearTimeout(skipVideoTimeout);
    }

    skipVideoTimeout = setTimeout(() => {
      handleError();
      logEvent(VIDEO_ERROR, VIDEO_ERROR, {
        error: 'Request timed out: landing page video '
      })
    }, 8000);
  }, [isPortrait]);

  React.useEffect(() => {
    if (canPlay) {
      clearTimeout(skipVideoTimeout);
    }
  }, [canPlay]);

  const handleExit = () => {
    setShowAction(false);
    entrance();
    setTimeout(() => {
      setIsExiting(true);
    }, 1000);
    setTimeout(() => {
      onClose();
    }, 2000);
  };

  React.useEffect(() => {
    if (isStreamPlaying) {
      if (shouldSkipVideo) {
        setTimeout(
          () => {
            setShowAction(false);
            onClose();
            setIsExiting(true);
          },
          delayFadeOut ? 2500 : 0
        );
      } else {
        handleExit();
      }
    }

    if (!isStreamPlaying && shouldSkipVideo) {
      setShowAction(true);
    }
  }, [isStreamPlaying, shouldSkipVideo]);

  const isLoading = landingFrame === LandingFrame.LanguageOption;
  const isLanding = landingFrame === LandingFrame.Intro;

  const handleError = () => {
    setShouldSkipVideo(true);
    setLoadedVideos(0);
    setShowAction(true);
  };

  const handlePlay = (frame: LandingFrame) => {
    switch (frame) {
      case LandingFrame.LanguageOption: {
        setShowAction(true);
        break;
      }
      case LandingFrame.Intro: {
        setTimeout(() => {
          setShowAction(true);
          setNextLandingFrame(LandingFrame.Outro);
        }, 43000);
        break;
      }
    }
  };

  const extraClass = `${isLoading ? 'loading ' : ''}${
    isLanding ? 'landing ' : ''
  }${isExiting ? 'exiting' : ''} ${landingFrame}`;

  const shouldShowPS =
    (nextLandingFrame === LandingFrame.Outro || shouldSkipVideo) &&
    streamStatus === StreamConnectionStatus.PAGE_LOADED &&
    !isConnecting &&
    !isExiting;

  React.useEffect(() => {
    if (shouldShowPS) {
      dispatch(actionDidGoToScene(CHScenes.SCENE_ENTER_TO_CONNECT));
    }
  }, [shouldShowPS]);

  React.useEffect(() => {
    if (landingFrame && landingFrame !== LandingFrame.LanguageOption) {
      logEvent(DID_GO_TO_SCENE, DID_GO_TO_SCENE, {
        id: CHScenes.SCENE_TUTORIAL,
        frame: landingFrame
      });
    }
  }, [landingFrame]);

  React.useEffect(() => {
    dispatch(actionDidGoToScene(CHScenes.SCENE_LANDING));
  }, []);

  return (
    <>
      <div className={`landing-video noselect ${extraClass}`}>
        {!shouldSkipVideo &&
          landingFrameSequence.map((frame) => (
            <CHLandingVideo
              key={frame + (isPortrait ? 'portrait' : 'landscape')}
              shouldPlay={landingFrame === frame && canPlay}
              onError={handleError}
              videoUrl={videoUrls[frame]}
              fade={true}
              onPlay={() => {
                handlePlay(frame);
              }}
              onLoaded={() => setLoadedVideos(state => state + 1)}
              loop={frame === LandingFrame.Outro}
              onEnded={() => {
                if (frame === LandingFrame.Intro) {
                  setLandingFrame(LandingFrame.Outro);
                  setShowAction(true)
                }
              }}
            />
          ))}
      </div>

      {!showAction && !canPlay && !shouldSkipVideo && (
        <div className="loading-icon">
          <CHLoadingIcon />
        </div>
      )}

      <CHLandingAction
        title="HAPPY SPORT"
        subTitle="TALE OF AN ICON"
        show={showAction}
        useImage={shouldSkipVideo}
        skipTutorial={skipTutorial}
        onEnter={() => {
          connectToStream();
          setShowAction(shouldSkipVideo);
          setLandingFrame(landingFrameSequence[1]);
          dispatch(actionDidGoToScene(CHScenes.SCENE_TUTORIAL));
          !shouldSkipVideo && requestFullScreen({});
          shouldSkipVideo && setNextLandingFrame(LandingFrame.Outro);
        }}
        loading={
          [
            StreamConnectionStatus.INITIAL,
            StreamConnectionStatus.FINDING_SERVER,
            StreamConnectionStatus.SERVER_FOUND,
            StreamConnectionStatus.CONNECTING,
            StreamConnectionStatus.CONNECTED
          ].includes(streamStatus) || isStreamPlaying
        }
        landingFrame={landingFrame}
        nextLandingFrame={nextLandingFrame}
        isPortrait={isPortrait}
        onSkip={() => {
          setShowAction(true);
          setNextLandingFrame(LandingFrame.Outro);
        }}
      />
      <style jsx>{`
        .loading-icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .landing-video {
          position: fixed;
          left: 0;
          bottom: 0;
          right: 0;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          opacity: 1;
          transition: all 1s linear;
        }

        .landing-video.exiting {
          opacity: 0;
        }
        .landing-video.loading .loading-screen :global(video) {
          opacity: 1;
        }
        .landing-video :global(video) {
          width: 100%;
          object-fit: cover;
        }

        :global(#pixelStreamWrapper) {
          background: #172337;
        }

        :global(iframe.pixel-stream-layer) {
          z-index: ${shouldShowPS ? '2' : '0'};
          transition: opacity 0.3s ease;
        }
      `}</style>
    </>
  );
};

export default CHLanding;
