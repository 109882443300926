import React, { useMemo } from 'react';
import { chopardHappySportGenevaHQ, chopardHappySportKorea } from '../config';
import { IProduct } from '../interfaces';
import { PageView } from '../utils/pageView';
import { AppContext } from './AppContext';
import HeaderChopard from './HeaderChopard';
import HeaderDefault from './HeaderDefault';
import { objectSpotMap } from './PixelStream/Chopard/CHConfig';

export interface ShareHeaderProps {
  title: string;
  shareTitle: string;
  type: PageView;
  id: string;
  shareImage?: string;
  showShare?: boolean;
  storeId?: string;
  product?: IProduct;
}

const Header = ({
  title,
  shareTitle,
  shareImage,
  type,
  id,
  showShare = true,
  storeId = '',
  product
}: ShareHeaderProps) => {
  const { store } = React.useContext(AppContext);
  const _storeId = storeId || store?.id;
  const props = {
    title,
    shareTitle,
    shareImage,
    type,
    id,
    showShare,
    product
  };
  
  const productSpotConfig = useMemo(() => {
    if (!product) {
      return;
    }
    return objectSpotMap[product.modelCode];
  }, [product]);

  return _storeId === chopardHappySportKorea ||
    _storeId === chopardHappySportGenevaHQ ? (
    <HeaderChopard
      {...props}
      storeId={_storeId}
      productSpotConfig={productSpotConfig}
      productModelCode={product?.modelCode}
    />
  ) : (
    <HeaderDefault {...props} />
  );
};

export default Header;
