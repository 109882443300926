import React from 'react';
import { logEvent } from '../../../analytics';
import { assetBaseUrl } from '../../../config';
import { DID_CLICK_POWERED_BY_INSPIFY } from '../../../utils/constants';
const CHStatusScreen = ({
  actionElement,
  show,
  useImage,
  children,
  isPortrait = false,
  centered,
  backdrop
}: {
  actionElement?: React.ReactNode;
  show?: boolean;
  useImage?: boolean;
  children?: React.ReactNode;
  isPortrait?: boolean;
  centered?: boolean;
  backdrop?: string;
}) => {
  const title = 'HAPPY SPORT';
  const subTitle = 'TALE OF AN ICON';

  const placeHolder = `${assetBaseUrl}/content/converted/a18fcbbe-48a0-423b-ba7b-b766f63de9fb/chopard-${
    isPortrait ? 'portrait' : 'landscape'
  }-intro-part01-end.png`;

  const totalElement = title.length + subTitle.length + 4;
  const totalComponentArr = Array.apply(null, [...Array(totalElement)]).map(
    (_, i) => i
  );

  return (
    <>
      <div
        className={`landing-container ${!show ? 'hide' : ''}  ${
          useImage ? 'useImage' : ''
        } ${centered ? 'centered' : ''} ${backdrop ? 'backdrop' : ''}`}
      >
        <div className="landing chopard-sans noselect">
          <div className="content">
            <img
              className="logo animated a0 noselect"
              src="https://panos2.inspify.io/virtual_boutique/5fe1729a-b700-40c2-b377-24596fd78264/logo-light.png"
            />
            {title && (
              <h1 className="noselect">
                {title.split('').map((c, i) => (
                  <span key={i} className={`title-c animated a${i + 1}`}>
                    {c}
                  </span>
                ))}
              </h1>
            )}
            {subTitle && (
              <h2 className="noselect">
                {subTitle.split('').map((c, i) => (
                  <span
                    key={i}
                    className={`sub-c animated a${i + title.length + 2}`}
                  >
                    {c}
                  </span>
                ))}
              </h2>
            )}
            <div
              className={`animated a${title.length + subTitle.length + 2}`}
            />

            <div
              className={`animated action-container a${
                title.length + subTitle.length + 3
              }`}
            >
              {actionElement}
            </div>
          </div>
        </div>
        <div className="chopard-content copyright noselect">
          <span>&copy; 2022 Chopard - All rights reserved | Powered by</span>{' '}
          <a
            href={'http://inspify.com/'}
            target="_blank"
            onClick={() => logEvent(DID_CLICK_POWERED_BY_INSPIFY)}
          >
            INSPIFY
          </a>
        </div>
        {children && (
          <div
            className={`animated children a${
              title.length + subTitle.length + 5
            }`}
          ></div>
        )}
      </div>
      <style jsx global>{`
        :global(.client:not(.popup-page-opened)) .veil {
          display: none;
        }
      `}</style>
      <style jsx>
        {`
          .action-container {
            padding: 0 20px;
            height: 150px;
          }
          .centered .action-container {
            padding-top: 8vh;
          }
          .please-wait {
            font-size: 0.7em;
            letter-spacing: 0.2em;
          }
          .children {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
          .landing-container {
            z-index: 1;
            background: red;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: ${useImage
              ? `url(${placeHolder}) center center`
              : 'transparent'};
            background-size: cover;
            opacity: 1;
            transition: opacity 2s linear;
          }
          .landing-container.hide {
            pointer-events: none;
            opacity: 0;
          }
          .animated {
            opacity: ${useImage ? '1' : '0'};
            transition: ${useImage ? 'none' : 'all 0.3s linear'};
          }
          ${useImage
            ? ``
            : totalComponentArr
                .map(
                  (order, i) =>
                    `.animated.a${order} { animation: ${
                      i === 0 ? 'slideFadeIn' : `fadeIn`
                    } ${i === totalElement - 1 ? 1 : 0.3}s linear ${
                      0.05 * (order + 1)
                    }s both; }
                .hide .animated.a${order} { animation: ${
                      i === 0 ? 'slideFadeOut' : 'fadeOut'
                    } 0.3s linear ${
                      0.05 * (totalElement / (order + 1))
                    }s both; }
                `
                )
                .join('')}

          .useImage .animated {
            opacity: 0;
          }
          .landing .show {
            opacity: 1;
          }
          .landing {
            margin: auto;
            color: #fff;
            background: ${backdrop || 'transparent'};
            height: 100%;
          }

          .centered .landing {
            position: fixed;
            left: 50%;
            top: 50%;
            right: auto;
            bottom: auto;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
          }

          .logo {
            width: 12em;
            height: auto;
            transform: translateY(-20%);
          }
          h1 {
            letter-spacing: 0.03em;
            font-size: 2em;
          }
          h2 {
            font-size: 1em;
            letter-spacing: 0.28em;
          }
          h2 + div {
            height: 1px;
            background: #ccc;
            width: 140px;
          }
          :global(.btn-action) {
            border-radius: 0;
            font-size: min(18px, 0.9em);
            padding: 0.6em 2em;
            letter-spacing: 0.2em;
            margin-bottom: 20px;
            width: auto;
          }
          .action-container :global(p) {
            letter-spacing: 0.2em;
          }
          .tstc {
            font-size: 0.5em;
            max-width: 300px;
            margin: auto;
          }
          .copyright {
            font-size: 12px;
            bottom: 20px;
            top: auto;
            left: 0;
            right: 0;
            position: absolute;
            text-align: center;
            color: #fff;
          }
          .copyright a {
            color: #fff;
            text-decoration: underline;
          }

          .centered .copyright {
            display: none;
          }
          @media (orientation: portrait) {
            .landing {
              padding-top: 1.4em;
            }
            h1 {
              margin: 0.2em 0 0.2em;
            }
            h2 + div {
              margin: 1.2em auto;
            }

            .content {
              max-width: 90%;
              margin: auto;
            }
            .please-wait {
              margin-bottom: 1.2em;
            }
          }
          @media (orientation: portrait) and (max-width: 430px) {
            .copyright {
              bottom: 70px;
            }
          }
          @media (orientation: landscape) and (min-width: 1200px) {
            .logo {
              width: min(20em, 70vh);
            }
          }
          @media (orientation: landscape) {
            .landing {
              font-size: min(1.5em, 1.8vw);
              left: 50%;
              right: 0;
              top: 0;
              bottom: 0;
              position: fixed;
              display: flex;
              flex-direction: column;
              text-align: center;
            }
            .centered .landing {
              left: 50%;
              top: 50%;
              right: auto;
              bottom: auto;
              transform: translate(-50%, -50%);
              width: 700px;
              max-width: 90vw;
              height: 900px;
              max-height: 80vh;
              font-size: min(1.3em, 1.6vw);
              border: 3px solid #fff;
            }
            .content {
              margin: auto;
              max-width: 600px;
            }
            .copyright {
              left: ${centered ? '0' : '50%'};
              top: auto;
            }

            :global(.MDLandscape) .copyright {
              text-align: right;
              padding-right: 20px;
            }
            h1 {
              margin: 0 0 -0.1em;
            }

            .please-wait {
              margin-bottom: 1.9em;
            }

            h2 + div {
              margin: 1.2em auto;
            }
          }
          @media (orientation: landscape) and (min-aspect-ratio: 1920/1080) {
            .landing {
              font-size: min(2em, 4vh);
            }
          }
          @media (orientation: landscape) and (max-height: 600px) {
            .copyright {
              left: 10px;
              bottom: 10px;
              position: fixed;
              text-align: left;
            }
            .centered .landing {
              max-height: 95%;
            }
            .centered .copyright {
              text-align: center;
              bottom: 8%;
            }

            .centered .landing {
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              max-width: 100%;
              height: 100%;
              max-height: 100%;
              transform: none;
              border: none;
            }
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          @keyframes slideFadeIn {
            from {
              opacity: 0;
              transform: translateY(-20%);
            }
            to {
              opacity: 1;
              transform: translateY(0%);
            }
          }
          @keyframes fadeOut {
            from {
              opacity: 1;
            }
            to {
              opacity: 0;
            }
          }
          @keyframes slideFadeOut {
            from {
              opacity: 1;
              transform: translateY(0%);
            }
            to {
              opacity: 0;
              transform: translateY(-20%);
            }
          }
        `}
      </style>
    </>
  );
};

export default CHStatusScreen;
