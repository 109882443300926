import React from 'react';
import ReactPlayer from 'react-player';
import { logEvent } from '../../../analytics';
import {
  DID_START_FULL_SCREEN_VIDEO,
  VIDEO_ENDED,
  VIDEO_ERROR,
  VIDEO_PAUSED,
  VIDEO_PLAYED
} from '../../../utils/constants';
import { handleRetryPlayVideo } from '../../../utils/video';
import CSRComponentWrapper from '../../CSRComponentWrapper';

interface PlayerProps {
  videoUrl: string;
  onProgress?: ({ playedSeconds, loaded }) => void;
  onEnded?: () => void;
  shouldPlay: boolean;
  onError: () => void;
  loop?: boolean;
  playSoon?: boolean;
  fade?: boolean;
  onPlay?: () => void;
  onLoaded?: () => void;
}

const CHLandingVideo = ({
  videoUrl,
  onProgress,
  shouldPlay,
  onEnded,
  loop,
  onError,
  playSoon,
  fade,
  onPlay,
  onLoaded
}: PlayerProps) => {
  const [ready, setReady] = React.useState(playSoon);
  const [retryCount, setRetryCount] = React.useState(0);

  const play = shouldPlay && ready;

  const throwError = (e, data, reloadPage = false) => {
    logEvent(
      VIDEO_ERROR,
      VIDEO_ERROR,
      {
        error: e,
        details: data,
        videoUrl,
        retryCount,
        reloadPage
      },
      true
    );
    if (reloadPage) {
      setTimeout(() => {
        window.location.reload();
      }, 300);
    } else {
      onError();
    }
  };

  const handleError = (e, data, hlsInstance, hlsGlobal) => {
    setReady(false);
    if (retryCount < 20) {
      const currentRetry = retryCount + 1;
      setTimeout(() => {
        setRetryCount(currentRetry);
        handleRetryPlayVideo({
          error: e,
          data,
          hlsInstance,
          hlsGlobal,
          videoUrl,
          retryCount: currentRetry,
          onRetry: () => setReady(true)
        });
      }, 100);
    } else {
      throwError(e, data, e.line && e.column);
    }
  };

  return (
    <CSRComponentWrapper
      onRenderFailed={() => throwError('failed to render', 'failed to render')}
    >
      <div className={`video-wrapper ${play ? 'play' : ''}`}>
        <ReactPlayer
          playing={play}
          width="100%"
          height="100%"
          playsinline
          muted={true}
          url={videoUrl}
          onStart={() => {
            logEvent(DID_START_FULL_SCREEN_VIDEO, DID_START_FULL_SCREEN_VIDEO, {
              videoUrl
            });
          }}
          onProgress={onProgress}
          onPlay={() => {
            logEvent(VIDEO_PLAYED, VIDEO_PLAYED, {
              videoUrl,
              retryCount
            });
            onPlay?.();
          }}
          onPause={() => {
            logEvent(VIDEO_PAUSED, VIDEO_PAUSED, {
              videoUrl
            });
          }}
          onEnded={() => {
            logEvent(VIDEO_ENDED, VIDEO_ENDED, {
              videoUrl
            });
            onEnded?.();
          }}
          onError={handleError}
          onDuration={() => {
            if (!playSoon) {
              setReady(true);
              onLoaded?.();
            }
          }}
          loop={loop || false}
          config={{
            file: {
              hlsOptions: {
                startFragPrefetch: true
              }
            }
          }}
        />
        <style jsx>{`
          .video-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            transition: ${fade ? 'opacity 1s linear' : 'none'};
            z-index: 0;
          }
          .video-wrapper.play {
            opacity: 1;
            z-index: 1;
          }
        `}</style>
      </div>
    </CSRComponentWrapper>
  );
};

export default CHLandingVideo;
