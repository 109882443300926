import { CHScenes } from './../PSInterface';
import { IMultilingualVideoUrls } from '../../../interfaces';
import { PageView } from '../../../utils/pageView';
import { IPSHotspot } from '../PSInterface';

import { assetBaseUrl, videoBaseUrl, staticBaseUrl } from '../../../config';
import { LandingFrame } from './CHLanding';

type ARConfig = {
  version: string;
  model?: string;
};
const defaultArConfig: ARConfig = {
  version: 'v4'
};
export interface IObjectSpotMap extends IPSHotspot {
  hidePrevNext?: boolean;
  showPrevNext?: boolean;
  customizable?: boolean;
  title?: string;
  arConfig?: ARConfig;
}
interface ICHTranslation {
  en: string;
  ko: string;
}

const musicAssetPath = `${assetBaseUrl}/chopard/chsps/musics/v9`;
const arViewerURL = `${staticBaseUrl}/3d-model-viewer/ar-ch.html`;

export const getARModelViewerUrl = (
  modeCode: string,
  arConfig: ARConfig
): string => {
  return `${arViewerURL}?mc=${modeCode}&mv=${arConfig.version}&model=${
    arConfig.model || modeCode
  }`;
};

export const chMusics: {
  url: string;
  volume?: number;
  scenes: CHScenes[];
  delay?: number;
}[] = [
  {
    scenes: [
      CHScenes.SCENE_CHAPTER1,
      CHScenes.SCENE_ENTRANCE,
      CHScenes.SCENE_TUTORIAL,
      CHScenes.SCENE_ENTER_TO_CONNECT
    ],
    url: `${musicAssetPath}/chap1.mp3`,
    volume: 0.3
  },
  {
    scenes: [CHScenes.SCENE_CHAPTER2],
    url: `${musicAssetPath}/chap2.mp3`,
    volume: 0.2
  },
  {
    scenes: [CHScenes.SCENE_CHAPTER3],
    url: `${musicAssetPath}/chap3.mp3`,
    volume: 0.3
  },
  {
    scenes: [CHScenes.SCENE_CHAPTER4],
    url: `${musicAssetPath}/chap4.mp3`,
    volume: 0.3
  },
  {
    scenes: [CHScenes.SCENE_CHAPTER5],
    url: `${musicAssetPath}/chap5.mp3`,
    volume: 0.15
  },
  {
    scenes: [CHScenes.SCENE_CHAPTER6],
    url: `${musicAssetPath}/chap7.mp3`,
    volume: 0.3
  },
  {
    scenes: [CHScenes.SCENE_LOBBY],
    url: `${musicAssetPath}/lobby.mp3`,
    volume: 0.2
  }
];

export const CHTranslation: { [key: string]: ICHTranslation } = {
  museumCollection: {
    en: 'Museum Collection',
    ko: '박물관 컬렉션'
  },
  pleaseSelectStrap: {
    en: 'Please select strap',
    ko: '스트랩을 선택하십시오'
  }
};

export const objectSpotMap: { [key: string]: IObjectSpotMap } = {
  pixelEvent: {
    type: PageView.PRODUCT,
    id: 'cbcc2123-ad90-485d-88d7-ae62a616d2f7'
  },
  story1001: {
    type: PageView.INSPIRATION,
    id: '85871b10-cb7c-11ec-a1d3-356a9e057903',
    idKo: '6f73f7d0-cb86-11ec-a1d3-356a9e057903',
    hidePrevNext: true
  },
  cubeClicked: {
    type: PageView.PRODUCT,
    id: 'cbcc2123-ad90-485d-88d7-ae62a616d2f7'
  },
  '212286-0001': {
    type: PageView.PRODUCT,
    id: '2c7975eb-de1b-4b7c-af41-231d2a380fe3'
  },
  '212561-0001': {
    type: PageView.PRODUCT,
    id: '2877526e-ebfa-45ea-ad42-67a69831209c',
    title: 'museumCollection'
  },
  '212564-0001': {
    type: PageView.PRODUCT,
    id: '758362ab-ee7b-4491-8a42-26464987e8fb',
    title: 'museumCollection'
  },
  story2001: {
    type: PageView.INSPIRATION,
    id: '2c7051b0-c47c-11ec-bf88-f19dba0774ef',
    idKo: 'e3874e80-c486-11ec-bf88-f19dba0774ef'
  },
  '7946744-0001': {
    type: PageView.PRODUCT,
    id: '8b9ef1d5-3628-4a4e-86f0-04648efcca00',
    title: 'museumCollection'
  },
  '795053-0001': {
    type: PageView.PRODUCT,
    id: '30fc2ec9-29e9-45ab-bbc1-bb99b0747665'
  },
  photo3001: {
    type: PageView.INSPIRATION,
    id: '5b6c04e0-d5b7-11ec-84ed-b760f1ee81d6',
    idKo: 'f57d36a0-d5ba-11ec-84ed-b760f1ee81d6'
  },
  story3001: {
    type: PageView.INSPIRATION,
    id: 'f95ec750-c216-11ec-8679-e387a8cb36e3',
    idKo: '4f853170-c21a-11ec-8679-e387a8cb36e3'
  },
  story3002: {
    type: PageView.INSPIRATION,
    id: '5b6c04e0-d5b7-11ec-84ed-b760f1ee81d6',
    idKo: 'f57d36a0-d5ba-11ec-84ed-b760f1ee81d6'
  },
  story3003: {
    type: PageView.INSPIRATION,
    id: '9b740c20-cb87-11ec-a1d3-356a9e057903',
    idKo: '190b8200-cb8b-11ec-a1d3-356a9e057903'
  },
  story3004: {
    type: PageView.INSPIRATION,
    id: '9fba4e90-d68e-11ec-8a57-99cb9de3b371',
    idKo: '95a37e30-d68f-11ec-8a57-99cb9de3b371'
  },
  '278236-3005': {
    type: PageView.PRODUCT,
    id: 'cbcc2123-ad90-485d-88d7-ae62a616d2f7',
    hidePrevNext: true
  },

  '274189-5009': {
    type: PageView.PRODUCT,
    id: '408e0beb-8b3a-4185-87ee-716ad95790a0'
  },
  '274462-1001': {
    type: PageView.PRODUCT,
    id: 'c18e5902-36ac-4e22-8fde-3256bbcb5ef4'
  },
  '274808-5007': {
    type: PageView.PRODUCT,
    id: '8dd3161b-7840-4ed4-9d73-18a59cc46416'
  },
  '274893-5009': {
    type: PageView.PRODUCT,
    id: '030c2e5a-d20f-499e-8f94-577b50dd773b'
  },
  '275322-5003': {
    type: PageView.PRODUCT,
    id: '54bcf596-5d5a-482a-a3ea-99e8a4520169'
  },
  '275362-1003': {
    type: PageView.PRODUCT,
    id: 'b4d9df40-1e09-4740-8a52-658300543e0e'
  },
  '275362-5005': {
    type: PageView.PRODUCT,
    id: 'fdb15189-0123-47bc-a699-c09c58c13e8a'
  },
  '276244-9004': {
    type: PageView.PRODUCT,
    id: '1120deec-46c1-45ad-b85c-0c252e8985cf'
  },
  '276730-1013': {
    type: PageView.PRODUCT,
    id: 'add6c509-087d-484d-aae7-0420a8b00fc3'
  },
  '276851-0007': {
    type: PageView.PRODUCT,
    id: '40a47cd4-ad35-4680-8755-eab5cf968c38'
  },
  '277000-0008': {
    type: PageView.PRODUCT,
    id: 'ab6d086d-6a0f-41ae-ae8e-403c22a09f25'
  },
  '277473-5011': {
    type: PageView.PRODUCT,
    id: 'c990069c-3535-4d3b-8fc2-d784f6b3bc6c'
  },
  '277777-9001': {
    type: PageView.PRODUCT,
    id: '9395ef4f-ddcb-421b-94d1-a88bf8d246ac'
  },
  '278239-4015': {
    type: PageView.PRODUCT,
    id: '6966c00e-b04e-4c20-9f19-75ec85cbac1f'
  },
  '278245-3007': {
    type: PageView.PRODUCT,
    id: '9b75df75-d2f1-4961-94f6-e590155bbffc'
  },
  '278250-3006': {
    type: PageView.PRODUCT,
    id: 'c353355c-48f6-44f5-970d-71dc8648af41'
  },
  '278323-3003': {
    type: PageView.PRODUCT,
    id: 'd45e1123-225a-494d-93bc-31d51b1a0830'
  },
  '278349-3006': {
    type: PageView.PRODUCT,
    id: '60c295c2-1802-411f-a77c-ccc13e751bdf'
  },
  '278419-2001': {
    type: PageView.PRODUCT,
    id: '8b3ca16c-0417-46e2-9a03-4fdc13946a55'
  },
  '278475-2003': {
    type: PageView.PRODUCT,
    id: '9d88b600-3b12-45ad-8cee-653a2135b465'
  },
  '278475-3007': {
    type: PageView.PRODUCT,
    id: 'c505b4cc-46e7-46c6-a9f8-576888e4ff02'
  },
  '278475-3021': {
    type: PageView.PRODUCT,
    id: 'fad8c2c7-f335-4eff-a52e-5c72809582e9'
  },
  '278475-3033': {
    type: PageView.PRODUCT,
    id: '9ccc03ff-381d-49df-a628-ceb461a248db'
  },
  '278477-3001': {
    type: PageView.PRODUCT,
    id: '0900eb0a-967c-4e4e-95be-763d11100a5b'
  },
  '278509-3001': {
    type: PageView.PRODUCT,
    id: '58b22b22-307b-42b9-864d-1b492c73212f'
  },
  '278516-6002': {
    type: PageView.PRODUCT,
    id: '13e53a6a-5fd1-43d3-bb39-ac45acfe2c86'
  },
  '278546-3006': {
    type: PageView.PRODUCT,
    id: 'f5a544f3-51ee-4ccc-b0f5-1d01519926a9'
  },
  '278551-3002': {
    type: PageView.PRODUCT,
    id: 'e375a3c5-9ad1-4e3b-81a6-2532b2452215'
  },
  '278559-3011': {
    type: PageView.PRODUCT,
    id: '3b4605e9-00e3-461c-9288-e14331fac19e',
    arConfig: defaultArConfig
  },
  '278559-3020': {
    type: PageView.PRODUCT,
    id: '285cd7eb-2618-49e3-8930-ee9d6f90d274'
  },
  '278573-3002': {
    type: PageView.PRODUCT,
    id: '405ba760-f054-4f4a-8547-3c3f06ae8bf9'
  },
  '278573-6013': {
    type: PageView.PRODUCT,
    id: 'bd6b0d13-6137-4065-923e-fd774c0cfe72',
    arConfig: defaultArConfig
  },
  '278578-3001': {
    type: PageView.PRODUCT,
    id: 'ebda65a3-bc26-4f46-8ab7-693cc174e62c'
  },
  '278578-4001': {
    type: PageView.PRODUCT,
    id: '6e975cd3-9a27-4310-8b11-c9fda1de32da'
  },
  '278578-6001': {
    type: PageView.PRODUCT,
    id: '1d6325f5-6190-4a24-8954-0ec32142b74e'
  },
  '278582-3005': {
    type: PageView.PRODUCT,
    id: 'b653011e-68df-4d11-ba0a-1c30802f8255'
  },
  '278587-3002': {
    type: PageView.PRODUCT,
    id: '66136b2a-0aed-4b3b-a4ec-a563f7728d44'
  },
  '278896-3004': {
    type: PageView.PRODUCT,
    id: 'e759d75c-affd-43b2-8120-d5a499302940'
  },
  '278924-2001': {
    type: PageView.PRODUCT,
    id: 'ba6ae90e-7169-4c2b-9dc0-a21e23e12c84'
  },
  '278943-2001': {
    type: PageView.PRODUCT,
    id: 'a435acd5-67b6-4ea4-ba39-25ef70d402d5'
  },
  '283259-0004': {
    type: PageView.PRODUCT,
    id: '6c0562a9-5023-4d63-8b88-5c7518a527d7'
  },
  '288448-2001': {
    type: PageView.PRODUCT,
    id: '5d3662bb-4fb7-4fbd-84a9-81455e215fea'
  },
  '288499-3009': {
    type: PageView.PRODUCT,
    id: 'cbedc9c4-23b3-4847-afa5-f461e4b95aa9'
  },
  '288507-9002': {
    type: PageView.PRODUCT,
    id: '46ed1454-1ef8-48b1-9dc7-4b4c81ba8517'
  },
  '288515-9001': {
    type: PageView.PRODUCT,
    id: 'ba93a701-f59b-4a1d-af1d-7abf6c0a3e22'
  },
  '288524-3007': {
    type: PageView.PRODUCT,
    id: '9a46bf42-0714-4ae2-b77e-7f166fcbfb71'
  },
  '288950-3001': {
    type: PageView.PRODUCT,
    id: '7f7077f3-f684-431d-9cd0-d1492742e26c'
  },
  story4001: {
    type: PageView.STORY_JOURNEY,
    id: '1E3D0F8445824CC6AB4E4D164A561C65',
    idKo: '929562BDD24D4088A5D8B8F977E0D53B'
  },
  story4002: {
    type: PageView.INSPIRATION,
    id: 'f25bed50-cb91-11ec-a1d3-356a9e057903',
    idKo: '68264070-cbb1-11ec-a1d3-356a9e057903',
    showPrevNext: true
  },
  story4003: {
    type: PageView.INSPIRATION,
    id: 'e79a2200-cbb5-11ec-a1d3-356a9e057903',
    idKo: '2beed190-cc0f-11ec-aa67-77dc0269bd5b',
    showPrevNext: true
  },
  story4004: {
    type: PageView.INSPIRATION,
    id: 'b2615190-cc13-11ec-aa67-77dc0269bd5b',
    idKo: '20b994b0-cc17-11ec-aa67-77dc0269bd5b',
    showPrevNext: true
  },
  '274891-1014': {
    type: PageView.PRODUCT,
    id: 'e7c22368-6556-433e-a827-32ffe4e3a666'
  },
  '274891-5019': { type: PageView.PRODUCT, id: 'dummy' },
  '275366-0001': {
    type: PageView.PRODUCT,
    id: 'cff0e0a6-0bea-41ad-85f0-e4a9f7919cfb'
  },
  '278608-6001': {
    type: PageView.PRODUCT,
    id: '0d3bb34f-4a5b-4c09-89f1-59500630aae2'
  },
  '278610-3002': {
    type: PageView.PRODUCT,
    id: '64fe9c7d-bdaa-4d66-a149-b226b9c249f3',
    arConfig: defaultArConfig
  },
  '278610-3001': {
    type: PageView.PRODUCT,
    id: 'df5b020f-54d9-4883-92b0-9df486a8484a',
    arConfig: defaultArConfig
  },
  story5001: {
    type: PageView.INSPIRATION,
    id: '22d3f3c0-cc18-11ec-aa67-77dc0269bd5b',
    idKo: 'c9be3840-cc1c-11ec-aa67-77dc0269bd5b'
  },
  story5002: {
    type: PageView.INSPIRATION,
    id: '8c91fd30-cc3a-11ec-aa67-77dc0269bd5b',
    idKo: 'ced5f5d0-cc3e-11ec-aa67-77dc0269bd5b'
  },
  story5003: {
    type: PageView.INSPIRATION,
    id: '4ca23eb0-cc3f-11ec-aa67-77dc0269bd5b',
    idKo: 'b6dac7c0-cc3f-11ec-aa67-77dc0269bd5b'
  },
  story5004: {
    type: PageView.INSPIRATION,
    id: '1726c6a0-ed70-11ec-8e9c-bb720fa357a9',
    idKo: 'eba2d540-ed70-11ec-8e9c-bb720fa357a9'
  },
  story5005: {
    type: PageView.INSPIRATION,
    id: 'b0a11510-cc41-11ec-aa67-77dc0269bd5b',
    idKo: '22168140-cc46-11ec-aa67-77dc0269bd5b'
  },
  text6001: {
    type: PageView.INSPIRATION,
    id: 'f821b6b0-d74f-11ec-b9b7-e9175e5d5cbb',
    idKo: '7038d110-d750-11ec-b9b7-e9175e5d5cbb'
  },
  story6001: {
    type: PageView.STORY_JOURNEY,
    id: 'B69A61E68F8F44879817EB02A1B25178',
    idKo: '915C05C49FAF494E80B5A1114DB4CFA9'
  },
  story6002: {
    type: PageView.INSPIRATION,
    id: 'fad1d5a0-d71a-11ec-b9b7-e9175e5d5cbb',
    idKo: 'cd450a00-d71d-11ec-b9b7-e9175e5d5cbb'
  },
  story6003: {
    type: PageView.INSPIRATION,
    id: 'e5c7f430-d6a4-11ec-8a57-99cb9de3b371',
    idKo: 'b20c6530-d713-11ec-b9b7-e9175e5d5cbb'
  },
  story6004: {
    type: PageView.INSPIRATION,
    id: '0c4b9240-d71f-11ec-b9b7-e9175e5d5cbb',
    idKo: '8f545730-d71f-11ec-b9b7-e9175e5d5cbb'
  },
  '82a085-1900': {
    type: PageView.PRODUCT,
    id: '39d6c442-c434-405d-abaf-6d77088002d3'
  },
  '82a611-5000': {
    type: PageView.PRODUCT,
    id: '044f7873-bd81-4b81-b2dd-9858b4b14160'
  },
  '857482-5300': {
    type: PageView.PRODUCT,
    id: 'aef24838-6248-4645-b76f-c71cd7c2a183'
  },
  '857482-5700': {
    type: PageView.PRODUCT,
    id: '9724d8d9-f245-4d10-819e-9d8f43a0c37f'
  },
  '857482-5900': {
    type: PageView.PRODUCT,
    id: 'cf843fc8-92c9-4090-adf9-1b247e3c0525'
  },
  '858230-9001': {
    type: PageView.PRODUCT,
    id: '2e0f61d5-dbf4-4a4b-9aa8-e0111c90504e'
  },
  '85a007-5020': {
    type: PageView.PRODUCT,
    id: '1270ff13-1bf6-4e4f-b344-e0c0a9d75e71'
  },
  '85a074-5200': {
    type: PageView.PRODUCT,
    id: 'db7178f8-ef0a-4548-9c0c-b76bab675c6d'
  },
  '85a074-5300': {
    type: PageView.PRODUCT,
    id: 'bf5ba255-f01a-4acb-a905-fd5996653132'
  },
  '85a083-5300': {
    type: PageView.PRODUCT,
    id: 'b7ab49bb-fcb1-4fa6-b21b-22bb34f722d8'
  },
  '797482-5301': {
    type: PageView.PRODUCT,
    id: 'ec0cfbe6-8de2-46a4-ada3-3b21ccd67c52'
  },
  '798233-9001': {
    type: PageView.PRODUCT,
    id: 'ae13beb8-2e96-4a65-b27b-7b2d3fab041d'
  },
  '799434-1401': {
    type: PageView.PRODUCT,
    id: '2c07ef85-5f14-4407-9bea-fa013b59245f'
  },
  '799450-5201': {
    type: PageView.PRODUCT,
    id: 'c60864bd-0308-4292-9715-539f0746caea'
  },
  '79a039-5201': {
    type: PageView.PRODUCT,
    id: 'cf8c40d4-9e76-46a0-896b-ec7a270bc693'
  },
  '79a074-5201': {
    type: PageView.PRODUCT,
    id: '106f4dde-f2e3-4b4c-a556-ac1c1692cd8f'
  },
  '79a074-5301': {
    type: PageView.PRODUCT,
    id: '898b9743-fb8c-4321-bd55-1153cccc44ee'
  },
  '79a074-5801': {
    type: PageView.PRODUCT,
    id: 'bfd9c54d-3234-4c44-9071-ddf95b93da9d'
  },
  '79a085-1901': {
    type: PageView.PRODUCT,
    id: '97962e81-0a7d-4c17-975c-ffc4b784d87c'
  },
  '81a083-5311': {
    type: PageView.PRODUCT,
    id: '07faccd1-834a-432c-8043-11a8d6e0eabb'
  },
  '83a054-5401': {
    type: PageView.PRODUCT,
    id: 'f848c04b-014a-4919-b241-83fd1badec22'
  },
  '83a083-5301': {
    type: PageView.PRODUCT,
    id: '9871c682-9db8-47ca-b234-40c5c8f22c26'
  },
  '84a085-1901': {
    type: PageView.PRODUCT,
    id: '7f4dd0b9-bfbf-4539-a382-08d0ae22bd0c'
  },
  c7_white: {
    type: PageView.INSPIRATION,
    id: '3b86de00-4b98-11ed-9cd5-b7597ddd9cba',
    idKo: 'a7b3ab10-4b9a-11ed-9cd5-b7597ddd9cba'
  },
  '274808-5003': {
    type: PageView.PRODUCT,
    id: '9fb5731b-a88b-478d-99d9-fe8643c967d2',
    arConfig: defaultArConfig
  },
  '274808-5006': {
    type: PageView.PRODUCT,
    id: '2b9b568d-1eb1-45b0-bf19-15a177aada03',
    arConfig: defaultArConfig
  },
  '278559-3001': {
    type: PageView.PRODUCT,
    id: 'e12895ae-49a4-4bd9-9088-83578f1fb5ce',
    arConfig: defaultArConfig
  },
  '278559-6001': {
    type: PageView.PRODUCT,
    id: 'c1e77e4c-4f6d-4106-8e96-efe3feb2fc36',
    arConfig: defaultArConfig
  },
  '278559-6008': {
    type: PageView.PRODUCT,
    id: '772229bf-ab1b-4c02-9cd6-1850925030a8',
    arConfig: defaultArConfig
  },
  '278582-3001': {
    type: PageView.PRODUCT,
    id: '0e87f582-3196-4aa0-9068-7c1c15dfb4f2',
    arConfig: defaultArConfig
  },
  '278582-6005': {
    type: PageView.PRODUCT,
    id: '871e8ac3-bd82-4325-ac3d-cec6dfddd219',
    arConfig: defaultArConfig
  },
  '275378-5001': {
    type: PageView.PRODUCT,
    id: '4ddb130c-6e1c-45d2-bb67-dfe04b95b0f8',
    arConfig: defaultArConfig
  },
  '275378-5003': {
    type: PageView.PRODUCT,
    id: '60f8d13e-f154-4736-9a5f-5f79579b5fac',
    arConfig: defaultArConfig
  },
  '275378-5005': {
    type: PageView.PRODUCT,
    id: '4d5a7624-fe3e-4aad-8bb8-30200b9962b0',
    arConfig: defaultArConfig
  },
  '278608-3001': {
    type: PageView.PRODUCT,
    id: 'bb9ea927-7499-4364-90e8-af7fa5f75e60',
    arConfig: defaultArConfig
  },
  '278608-6003': {
    type: PageView.PRODUCT,
    id: '151f470f-a03c-4dfb-9724-0f09ea25f212',
    arConfig: defaultArConfig
  },
  '278602-6001': {
    type: PageView.PRODUCT,
    id: '935cac0f-b73c-4700-9f65-d4d867428d55',
    arConfig: defaultArConfig
  },
  '278602-6003': {
    type: PageView.PRODUCT,
    id: '191feada-3ee2-44a1-8b07-cd6fa0907654',
    arConfig: defaultArConfig
  },
  '278573-3011': {
    type: PageView.PRODUCT,
    id: '6df603a1-8da0-4bec-b362-0348245e6b58',
    arConfig: defaultArConfig
  },
  '278573-3012': {
    type: PageView.PRODUCT,
    id: '9bf5b54a-3bc8-494b-bc67-c69bc7a0146d',
    arConfig: defaultArConfig
  },
  '278573-6011': {
    type: PageView.PRODUCT,
    id: 'add45a56-27ec-4a0a-b782-7d9fae397a3b',
    arConfig: defaultArConfig
  },
  '278573-6017': {
    type: PageView.PRODUCT,
    id: '42853778-70af-4c03-8f46-be11c2d684a9',
    arConfig: defaultArConfig
  },
  '278573-6018': {
    type: PageView.PRODUCT,
    id: '67f6f69d-3205-4d56-9633-5ee21094dd2d',
    arConfig: defaultArConfig
  },
  '278573-6026': {
    type: PageView.PRODUCT,
    id: 'e8a53d08-75a7-4ea6-9e98-fa93bd4a7dc0',
    arConfig: defaultArConfig
  },
  '278573-6027': {
    type: PageView.PRODUCT,
    id: '0e874d14-294b-4aa2-aede-e85bfcd87d53',
    arConfig: defaultArConfig
  },
  '278590-3001': {
    type: PageView.PRODUCT,
    id: 'fd7ab173-6930-4a89-ad54-5c60becb1d30',
    arConfig: defaultArConfig
  },
  '278590-6001': {
    type: PageView.PRODUCT,
    id: 'd570784f-1dca-416b-8e35-27f1bb4cdb90',
    arConfig: defaultArConfig
  },
  '278590-6002': {
    type: PageView.PRODUCT,
    id: 'd570784f-1dca-416b-8e35-27f1bb4cdb90',
    arConfig: defaultArConfig
  },
  '791315-5001': {
    type: PageView.PRODUCT,
    id: '87a77341-4162-45ea-b914-7f182628efc6',
    title: 'museumCollection'
  },

  spot1001: {
    type: PageView.PRODUCT,
    id: '2c7975eb-de1b-4b7c-af41-231d2a380fe3'
  },
  spot1002: {
    type: PageView.PRODUCT,
    id: '2877526e-ebfa-45ea-ad42-67a69831209c'
  },
  spot1003: {
    type: PageView.PRODUCT,
    id: '758362ab-ee7b-4491-8a42-26464987e8fb'
  },
  spot2001: {
    type: PageView.PRODUCT,
    id: '8b9ef1d5-3628-4a4e-86f0-04648efcca00'
  },
  spot2002: {
    type: PageView.PRODUCT,
    id: '30fc2ec9-29e9-45ab-bbc1-bb99b0747665'
  },
  spot3001: {
    type: PageView.PRODUCT,
    id: 'cbcc2123-ad90-485d-88d7-ae62a616d2f7',
    hidePrevNext: true
  },

  spot4001: {
    type: PageView.PRODUCT,
    id: '408e0beb-8b3a-4185-87ee-716ad95790a0'
  },
  spot4002: {
    type: PageView.PRODUCT,
    id: 'c18e5902-36ac-4e22-8fde-3256bbcb5ef4'
  },
  spot4003: {
    type: PageView.PRODUCT,
    id: '8dd3161b-7840-4ed4-9d73-18a59cc46416'
  },
  spot4004: {
    type: PageView.PRODUCT,
    id: '030c2e5a-d20f-499e-8f94-577b50dd773b'
  },
  spot4005: {
    type: PageView.PRODUCT,
    id: '54bcf596-5d5a-482a-a3ea-99e8a4520169'
  },
  spot4006: {
    type: PageView.PRODUCT,
    id: 'b4d9df40-1e09-4740-8a52-658300543e0e'
  },
  spot4007: {
    type: PageView.PRODUCT,
    id: 'fdb15189-0123-47bc-a699-c09c58c13e8a'
  },
  spot4008: {
    type: PageView.PRODUCT,
    id: '1120deec-46c1-45ad-b85c-0c252e8985cf'
  },
  spot4009: {
    type: PageView.PRODUCT,
    id: 'add6c509-087d-484d-aae7-0420a8b00fc3'
  },
  spot4010: {
    type: PageView.PRODUCT,
    id: '40a47cd4-ad35-4680-8755-eab5cf968c38'
  },
  spot4011: {
    type: PageView.PRODUCT,
    id: 'ab6d086d-6a0f-41ae-ae8e-403c22a09f25'
  },
  spot4012: {
    type: PageView.PRODUCT,
    id: 'c990069c-3535-4d3b-8fc2-d784f6b3bc6c'
  },
  spot4013: {
    type: PageView.PRODUCT,
    id: '9395ef4f-ddcb-421b-94d1-a88bf8d246ac'
  },
  spot4014: {
    type: PageView.PRODUCT,
    id: '6966c00e-b04e-4c20-9f19-75ec85cbac1f'
  },
  spot4015: {
    type: PageView.PRODUCT,
    id: '9b75df75-d2f1-4961-94f6-e590155bbffc'
  },
  spot4016: {
    type: PageView.PRODUCT,
    id: 'c353355c-48f6-44f5-970d-71dc8648af41'
  },
  spot4017: {
    type: PageView.PRODUCT,
    id: 'd45e1123-225a-494d-93bc-31d51b1a0830'
  },
  spot4018: {
    type: PageView.PRODUCT,
    id: '60c295c2-1802-411f-a77c-ccc13e751bdf'
  },
  spot4019: {
    type: PageView.PRODUCT,
    id: '8b3ca16c-0417-46e2-9a03-4fdc13946a55'
  },
  spot4020: {
    type: PageView.PRODUCT,
    id: '9d88b600-3b12-45ad-8cee-653a2135b465'
  },
  spot4021: {
    type: PageView.PRODUCT,
    id: 'c505b4cc-46e7-46c6-a9f8-576888e4ff02'
  },
  spot4022: {
    type: PageView.PRODUCT,
    id: 'fad8c2c7-f335-4eff-a52e-5c72809582e9'
  },
  spot4023: {
    type: PageView.PRODUCT,
    id: '9ccc03ff-381d-49df-a628-ceb461a248db'
  },
  spot4024: {
    type: PageView.PRODUCT,
    id: '0900eb0a-967c-4e4e-95be-763d11100a5b'
  },
  spot4025: {
    type: PageView.PRODUCT,
    id: '58b22b22-307b-42b9-864d-1b492c73212f'
  },
  spot4026: {
    type: PageView.PRODUCT,
    id: '13e53a6a-5fd1-43d3-bb39-ac45acfe2c86'
  },
  spot4027: {
    type: PageView.PRODUCT,
    id: 'f5a544f3-51ee-4ccc-b0f5-1d01519926a9'
  },
  spot4028: {
    type: PageView.PRODUCT,
    id: 'e375a3c5-9ad1-4e3b-81a6-2532b2452215'
  },
  spot4029: {
    type: PageView.PRODUCT,
    id: '3b4605e9-00e3-461c-9288-e14331fac19e'
  },
  spot4030: {
    type: PageView.PRODUCT,
    id: '285cd7eb-2618-49e3-8930-ee9d6f90d274'
  },
  spot4031: {
    type: PageView.PRODUCT,
    id: '405ba760-f054-4f4a-8547-3c3f06ae8bf9'
  },
  spot4032: {
    type: PageView.PRODUCT,
    id: 'bd6b0d13-6137-4065-923e-fd774c0cfe72'
  },
  spot4033: {
    type: PageView.PRODUCT,
    id: 'ebda65a3-bc26-4f46-8ab7-693cc174e62c'
  },
  spot4034: {
    type: PageView.PRODUCT,
    id: '6e975cd3-9a27-4310-8b11-c9fda1de32da'
  },
  spot4035: {
    type: PageView.PRODUCT,
    id: '1d6325f5-6190-4a24-8954-0ec32142b74e'
  },
  spot4036: {
    type: PageView.PRODUCT,
    id: 'b653011e-68df-4d11-ba0a-1c30802f8255'
  },
  spot4037: {
    type: PageView.PRODUCT,
    id: '66136b2a-0aed-4b3b-a4ec-a563f7728d44'
  },
  spot4038: {
    type: PageView.PRODUCT,
    id: 'e759d75c-affd-43b2-8120-d5a499302940'
  },
  spot4039: {
    type: PageView.PRODUCT,
    id: 'ba6ae90e-7169-4c2b-9dc0-a21e23e12c84'
  },
  spot4040: {
    type: PageView.PRODUCT,
    id: 'a435acd5-67b6-4ea4-ba39-25ef70d402d5'
  },
  spot4041: {
    type: PageView.PRODUCT,
    id: '6c0562a9-5023-4d63-8b88-5c7518a527d7'
  },
  spot4042: {
    type: PageView.PRODUCT,
    id: '5d3662bb-4fb7-4fbd-84a9-81455e215fea'
  },
  spot4043: {
    type: PageView.PRODUCT,
    id: 'cbedc9c4-23b3-4847-afa5-f461e4b95aa9'
  },
  spot4044: {
    type: PageView.PRODUCT,
    id: '46ed1454-1ef8-48b1-9dc7-4b4c81ba8517'
  },
  spot4045: {
    type: PageView.PRODUCT,
    id: 'ba93a701-f59b-4a1d-af1d-7abf6c0a3e22'
  },
  spot4046: {
    type: PageView.PRODUCT,
    id: '9a46bf42-0714-4ae2-b77e-7f166fcbfb71'
  },
  spot4047: {
    type: PageView.PRODUCT,
    id: '7f7077f3-f684-431d-9cd0-d1492742e26c'
  },
  text4001: {
    type: PageView.STORY_JOURNEY,
    id: '1E3D0F8445824CC6AB4E4D164A561C65',
    idKo: '929562BDD24D4088A5D8B8F977E0D53B'
  },
  text4002: {
    type: PageView.INSPIRATION,
    id: 'f25bed50-cb91-11ec-a1d3-356a9e057903',
    idKo: '68264070-cbb1-11ec-a1d3-356a9e057903'
  },
  text4003: {
    type: PageView.INSPIRATION,
    id: 'e79a2200-cbb5-11ec-a1d3-356a9e057903',
    idKo: '2beed190-cc0f-11ec-aa67-77dc0269bd5b'
  },
  text4004: {
    type: PageView.INSPIRATION,
    id: 'b2615190-cc13-11ec-aa67-77dc0269bd5b',
    idKo: '20b994b0-cc17-11ec-aa67-77dc0269bd5b'
  },
  spotfocus4001: {
    type: PageView.PRODUCT,
    id: 'e7c22368-6556-433e-a827-32ffe4e3a666'
  },
  spotfocus4002: { type: PageView.PRODUCT, id: 'dummy' },
  spotfocus4003: {
    type: PageView.PRODUCT,
    id: 'cff0e0a6-0bea-41ad-85f0-e4a9f7919cfb'
  },
  spot5003: {
    type: PageView.PRODUCT,
    id: '0d3bb34f-4a5b-4c09-89f1-59500630aae2'
  },
  spot5004: {
    type: PageView.PRODUCT,
    id: '64fe9c7d-bdaa-4d66-a149-b226b9c249f3'
  },
  spot5005: {
    type: PageView.PRODUCT,
    id: 'df5b020f-54d9-4883-92b0-9df486a8484a'
  },
  text5001: {
    type: PageView.INSPIRATION,
    id: 'b0a11510-cc41-11ec-aa67-77dc0269bd5b',
    idKo: '22168140-cc46-11ec-aa67-77dc0269bd5b'
  },
  spot7001: {
    type: PageView.PRODUCT,
    id: '39d6c442-c434-405d-abaf-6d77088002d3'
  },
  spot7002: {
    type: PageView.PRODUCT,
    id: '044f7873-bd81-4b81-b2dd-9858b4b14160'
  },
  spot7003: {
    type: PageView.PRODUCT,
    id: 'aef24838-6248-4645-b76f-c71cd7c2a183'
  },
  spot7004: {
    type: PageView.PRODUCT,
    id: '9724d8d9-f245-4d10-819e-9d8f43a0c37f'
  },
  spot7005: {
    type: PageView.PRODUCT,
    id: 'cf843fc8-92c9-4090-adf9-1b247e3c0525'
  },
  spot7006: {
    type: PageView.PRODUCT,
    id: '2e0f61d5-dbf4-4a4b-9aa8-e0111c90504e'
  },
  spot7007: {
    type: PageView.PRODUCT,
    id: '1270ff13-1bf6-4e4f-b344-e0c0a9d75e71'
  },
  spot7008: {
    type: PageView.PRODUCT,
    id: 'db7178f8-ef0a-4548-9c0c-b76bab675c6d'
  },
  spot7009: {
    type: PageView.PRODUCT,
    id: 'bf5ba255-f01a-4acb-a905-fd5996653132'
  },
  spot7010: {
    type: PageView.PRODUCT,
    id: 'b7ab49bb-fcb1-4fa6-b21b-22bb34f722d8'
  },
  spot7011: {
    type: PageView.PRODUCT,
    id: 'ec0cfbe6-8de2-46a4-ada3-3b21ccd67c52'
  },
  spot7012: {
    type: PageView.PRODUCT,
    id: 'ec0cfbe6-8de2-46a4-ada3-3b21ccd67c52'
  },
  spot7013: {
    type: PageView.PRODUCT,
    id: '2c07ef85-5f14-4407-9bea-fa013b59245f'
  },
  spot7014: {
    type: PageView.PRODUCT,
    id: 'c60864bd-0308-4292-9715-539f0746caea'
  },
  spot7015: {
    type: PageView.PRODUCT,
    id: 'cf8c40d4-9e76-46a0-896b-ec7a270bc693'
  },
  spot7016: {
    type: PageView.PRODUCT,
    id: '106f4dde-f2e3-4b4c-a556-ac1c1692cd8f'
  },
  spot7017: {
    type: PageView.PRODUCT,
    id: 'bfd9c54d-3234-4c44-9071-ddf95b93da9d'
  },
  spot7018: {
    type: PageView.PRODUCT,
    id: '97962e81-0a7d-4c17-975c-ffc4b784d87c'
  },
  spot7019: {
    type: PageView.PRODUCT,
    id: '07faccd1-834a-432c-8043-11a8d6e0eabb'
  },
  spot7020: {
    type: PageView.PRODUCT,
    id: 'f848c04b-014a-4919-b241-83fd1badec22'
  },
  spot7021: {
    type: PageView.PRODUCT,
    id: '9871c682-9db8-47ca-b234-40c5c8f22c26'
  },
  spot7022: {
    type: PageView.PRODUCT,
    id: '7f4dd0b9-bfbf-4539-a382-08d0ae22bd0c'
  },
  spotlobby1001: {
    type: PageView.PRODUCT,
    id: '9fb5731b-a88b-478d-99d9-fe8643c967d2'
  },
  spotlobby1002: {
    type: PageView.PRODUCT,
    id: '2b9b568d-1eb1-45b0-bf19-15a177aada03'
  },
  spotlobby1003: {
    type: PageView.PRODUCT,
    id: 'e12895ae-49a4-4bd9-9088-83578f1fb5ce'
  },
  spotlobby1004: {
    type: PageView.PRODUCT,
    id: '3b4605e9-00e3-461c-9288-e14331fac19e'
  },
  spotlobby1005: {
    type: PageView.PRODUCT,
    id: 'c1e77e4c-4f6d-4106-8e96-efe3feb2fc36'
  },
  spotlobby1006: {
    type: PageView.PRODUCT,
    id: '772229bf-ab1b-4c02-9cd6-1850925030a8'
  },
  spotlobby1007: {
    type: PageView.PRODUCT,
    id: '0e87f582-3196-4aa0-9068-7c1c15dfb4f2'
  },
  spotlobby1008: {
    type: PageView.PRODUCT,
    id: '871e8ac3-bd82-4325-ac3d-cec6dfddd219'
  },
  spotlobby1009: {
    type: PageView.PRODUCT,
    id: '4ddb130c-6e1c-45d2-bb67-dfe04b95b0f8'
  },
  spotlobby1010: {
    type: PageView.PRODUCT,
    id: '60f8d13e-f154-4736-9a5f-5f79579b5fac'
  },
  spotlobby1011: {
    type: PageView.PRODUCT,
    id: '4d5a7624-fe3e-4aad-8bb8-30200b9962b0'
  },
  spotlobby1012: {
    type: PageView.PRODUCT,
    id: 'bb9ea927-7499-4364-90e8-af7fa5f75e60'
  },
  spotlobby1013: {
    type: PageView.PRODUCT,
    id: '0d3bb34f-4a5b-4c09-89f1-59500630aae2'
  },
  spotlobby1014: {
    type: PageView.PRODUCT,
    id: '151f470f-a03c-4dfb-9724-0f09ea25f212'
  },
  spotlobby1015: {
    type: PageView.PRODUCT,
    id: 'df5b020f-54d9-4883-92b0-9df486a8484a'
  },
  spotlobby1016: {
    type: PageView.PRODUCT,
    id: '64fe9c7d-bdaa-4d66-a149-b226b9c249f3'
  },
  spotlobby1017: {
    type: PageView.PRODUCT,
    id: '935cac0f-b73c-4700-9f65-d4d867428d55'
  },
  spotlobby1018: {
    type: PageView.PRODUCT,
    id: '191feada-3ee2-44a1-8b07-cd6fa0907654'
  },
  spotlobby1019: {
    type: PageView.PRODUCT,
    id: '6df603a1-8da0-4bec-b362-0348245e6b58'
  },
  spotlobby1020: {
    type: PageView.PRODUCT,
    id: '9bf5b54a-3bc8-494b-bc67-c69bc7a0146d'
  },
  spotlobby1021: {
    type: PageView.PRODUCT,
    id: 'add45a56-27ec-4a0a-b782-7d9fae397a3b'
  },
  spotlobby1022: {
    type: PageView.PRODUCT,
    id: 'bd6b0d13-6137-4065-923e-fd774c0cfe72'
  },
  spotlobby1023: {
    type: PageView.PRODUCT,
    id: '42853778-70af-4c03-8f46-be11c2d684a9'
  },
  spotlobby1024: {
    type: PageView.PRODUCT,
    id: '67f6f69d-3205-4d56-9633-5ee21094dd2d'
  },
  spotlobby1025: {
    type: PageView.PRODUCT,
    id: 'e8a53d08-75a7-4ea6-9e98-fa93bd4a7dc0'
  },
  spotlobby1026: {
    type: PageView.PRODUCT,
    id: '0e874d14-294b-4aa2-aede-e85bfcd87d53'
  },
  spotlobby1027: {
    type: PageView.PRODUCT,
    id: 'fd7ab173-6930-4a89-ad54-5c60becb1d30'
  },
  spotlobby1028: {
    type: PageView.PRODUCT,
    id: 'd570784f-1dca-416b-8e35-27f1bb4cdb90'
  },
  spotlobby1029: { type: PageView.PRODUCT, id: 'dummy' },
  spotlobby1030: {
    type: PageView.PRODUCT,
    id: '87a77341-4162-45ea-b914-7f182628efc6'
  },
  spotlobby1031: {
    type: PageView.PRODUCT,
    id: '8b9ef1d5-3628-4a4e-86f0-04648efcca00'
  }
};

export const ueSceneMap: { [key: string]: CHScenes } = {
  chapter1loading: CHScenes.SCENE_LOADING_CHAPTER,
  chapter2loading: CHScenes.SCENE_LOADING_CHAPTER,
  chapter3loading: CHScenes.SCENE_LOADING_CHAPTER,
  chapter4loading: CHScenes.SCENE_LOADING_CHAPTER,
  chapter5loading: CHScenes.SCENE_LOADING_CHAPTER,
  chapter6loading: CHScenes.SCENE_LOADING_CHAPTER,
  chapter7loading: CHScenes.SCENE_LOADING_CHAPTER,
  chapterlobbyloading: CHScenes.SCENE_LOADING_CHAPTER,
  chapter1: CHScenes.SCENE_CHAPTER1,
  chapter2: CHScenes.SCENE_CHAPTER2,
  chapter3: CHScenes.SCENE_CHAPTER3,
  chapter4: CHScenes.SCENE_CHAPTER4,
  chapter5: CHScenes.SCENE_CHAPTER5,
  chapter6: CHScenes.SCENE_CHAPTER6,
  chapterlobby: CHScenes.SCENE_LOBBY
};

export const EOLProducts = [
  '2c7975eb-de1b-4b7c-af41-231d2a380fe3',
  '2877526e-ebfa-45ea-ad42-67a69831209c',
  '758362ab-ee7b-4491-8a42-26464987e8fb',
  '30fc2ec9-29e9-45ab-bbc1-bb99b0747665',
  '8b9ef1d5-3628-4a4e-86f0-04648efcca00',
  'cbcc2123-ad90-485d-88d7-ae62a616d2f7',
  '408e0beb-8b3a-4185-87ee-716ad95790a0',
  'c18e5902-36ac-4e22-8fde-3256bbcb5ef4',
  '8dd3161b-7840-4ed4-9d73-18a59cc46416',
  '030c2e5a-d20f-499e-8f94-577b50dd773b',
  '54bcf596-5d5a-482a-a3ea-99e8a4520169',
  'b4d9df40-1e09-4740-8a52-658300543e0e',
  'fdb15189-0123-47bc-a699-c09c58c13e8a',
  '1120deec-46c1-45ad-b85c-0c252e8985cf',
  'add6c509-087d-484d-aae7-0420a8b00fc3',
  '40a47cd4-ad35-4680-8755-eab5cf968c38',
  'ab6d086d-6a0f-41ae-ae8e-403c22a09f25',
  'c990069c-3535-4d3b-8fc2-d784f6b3bc6c',
  '9395ef4f-ddcb-421b-94d1-a88bf8d246ac',
  '6966c00e-b04e-4c20-9f19-75ec85cbac1f',
  '9b75df75-d2f1-4961-94f6-e590155bbffc',
  'c353355c-48f6-44f5-970d-71dc8648af41',
  'd45e1123-225a-494d-93bc-31d51b1a0830',
  '60c295c2-1802-411f-a77c-ccc13e751bdf',
  '8b3ca16c-0417-46e2-9a03-4fdc13946a55',
  '9d88b600-3b12-45ad-8cee-653a2135b465',
  'c505b4cc-46e7-46c6-a9f8-576888e4ff02',
  'fad8c2c7-f335-4eff-a52e-5c72809582e9',
  '9ccc03ff-381d-49df-a628-ceb461a248db',
  '0900eb0a-967c-4e4e-95be-763d11100a5b',
  '58b22b22-307b-42b9-864d-1b492c73212f',
  '13e53a6a-5fd1-43d3-bb39-ac45acfe2c86',
  'f5a544f3-51ee-4ccc-b0f5-1d01519926a9',
  'e375a3c5-9ad1-4e3b-81a6-2532b2452215',
  '285cd7eb-2618-49e3-8930-ee9d6f90d274',
  '405ba760-f054-4f4a-8547-3c3f06ae8bf9',
  'ebda65a3-bc26-4f46-8ab7-693cc174e62c',
  '6e975cd3-9a27-4310-8b11-c9fda1de32da',
  '1d6325f5-6190-4a24-8954-0ec32142b74e',
  'b653011e-68df-4d11-ba0a-1c30802f8255',
  '66136b2a-0aed-4b3b-a4ec-a563f7728d44',
  'e759d75c-affd-43b2-8120-d5a499302940',
  'ba6ae90e-7169-4c2b-9dc0-a21e23e12c84',
  'a435acd5-67b6-4ea4-ba39-25ef70d402d5',
  '6c0562a9-5023-4d63-8b88-5c7518a527d7',
  '5d3662bb-4fb7-4fbd-84a9-81455e215fea',
  'cbedc9c4-23b3-4847-afa5-f461e4b95aa9',
  '46ed1454-1ef8-48b1-9dc7-4b4c81ba8517',
  'ba93a701-f59b-4a1d-af1d-7abf6c0a3e22',
  '9a46bf42-0714-4ae2-b77e-7f166fcbfb71',
  '7f7077f3-f684-431d-9cd0-d1492742e26c',
  'e7c22368-6556-433e-a827-32ffe4e3a666',
  'cff0e0a6-0bea-41ad-85f0-e4a9f7919cfb',
  '87a77341-4162-45ea-b914-7f182628efc6'
];

export const storyWithVideo = [
  'text6001',
  'story6001',
  'story6002',
  'story6003',
  'story6004',
  'text4001',
  'story4001',
  'story5002',
  'c7_white'
];

export const fullScrVideoMap: { [key: string]: IMultilingualVideoUrls } = {
  video1001: {
    ko: 'content/converted/85d486c6-3845-4be9-a11c-3bf4934b8755/chopard_legacy_ep1_diamonds_are_happier_2021_2m20_1080p25_sub_ko.mp4/index.m3u8',
    en: 'content/converted/85d486c6-3845-4be9-a11c-3bf4934b8755/chopard_legacy_ep1_diamonds_are_happier_2021_2m20_1080p25_sub_en.mp4/index.m3u8'
  },
  video2001: {
    ko: 'content/converted/72e3ef29-9262-48c0-9c70-afac73846b0d/chopard_legacy_ep2_little_diamonds_do_great_things_2021_2m36_1080p25_sub_ko.mp4/index.m3u8',
    en: 'content/converted/72e3ef29-9262-48c0-9c70-afac73846b0d/chopard_legacy_ep2_little_diamonds_do_great_things_2021_2m36_1080p25_sub_en.mp4/index.m3u8'
  },
  video3001: {
    ko: 'content/converted/88f32933-4be2-439f-9325-fe6492add3d3/chopard_legacy_ep3_free_spirited_women_2021_2m03_1080p25_sub_ko.mp4/index.m3u8',
    en: 'content/converted/88f32933-4be2-439f-9325-fe6492add3d3/chopard_legacy_ep3_free_spirited_women_2021_2m03_1080p25_sub_en.mp4/index.m3u8'
  },
  video5001: {
    ko: 'content/converted/ae66587a-ff03-4e32-9377-af1e7c4aa409/chopard_legacy_ep5_happy_sport_the_first_2021_30s_1080p25_sub_ko.mp4/index.m3u8',
    en: 'content/converted/ae66587a-ff03-4e32-9377-af1e7c4aa409/chopard_legacy_ep5_happy_sport_the_first_2021_30s_1080p25_sub_en.mp4/index.m3u8'
  },
  cosmos: {
    ko: 'content/converted/e8f5a3a0-f528-404c-8f04-e1b9827c71d4/chopard_legacy_ep4_forever_happy_sport_2021_30s_1080p25_sub_ko.mp4/index.m3u8',
    en: 'content/converted/e8f5a3a0-f528-404c-8f04-e1b9827c71d4/chopard_legacy_ep4_forever_happy_sport_2021_30s_1080p25_sub_en.mp4/index.m3u8'
  },
  julia_video: {
    en: 'content/converted/2962e2aa-8256-4f02-bf44-892c31a74b7a/278608-6001_chopard_happy_hero_bico_produit_2021_60s_1080p24.mp4/index.m3u8'
  },
  c7_black: {
    en: 'content/converted/ca5bac39-72b2-478e-9916-391146b1617c/chopard_main_happy%20collection_30s_16-9_v2.mp4/index.m3u8'
  }
};

//dialModecode_StrapModelcode: buckleModelCode
export const strapBucklePairs = {
  '278559-3001_C960201-0440': 'C200064-301',
  '278559-3001_C960201-0441': 'C200064-301',
  '278559-3001_C960201-0542': 'C200064-301',
  '278559-3001_C960202-0124': 'C200064-301',
  '278559-3001_C960207-0141': 'C200064-301',
  '278559-3001_C960208-0451': 'C200064-301',
  '278559-3001_C960213-0224': 'C200064-301',
  '278559-3001_C962800-0129': 'C200064-301',
  '278559-3001_C962801-0146': 'C200064-301',
  '278559-3001_C962802-0096': 'C200064-301',
  '278559-3001_C962812-0073': 'C200064-301',
  '278559-3001_C962817-0013': 'C200064-301',
  '278559-3003_C960201-0440': 'C200064-301',
  '278559-3003_C960201-0441': 'C200064-301',
  '278559-3003_C960201-0542': 'C200064-301',
  '278559-3003_C960202-0124': 'C200064-301',
  '278559-3003_C960207-0141': 'C200064-301',
  '278559-3003_C960208-0451': 'C200064-301',
  '278559-3003_C960213-0224': 'C200064-301',
  '278559-3003_C962800-0129': 'C200064-301',
  '278559-3003_C962801-0146': 'C200064-301',
  '278559-3003_C962802-0096': 'C200064-301',
  '278559-3003_C962812-0073': 'C200064-301',
  '278559-3003_C962817-0013': 'C200064-301',
  '278559-6001_C960201-0440': 'C200064-301',
  '278559-6001_C960201-0441': 'C200064-301',
  '278559-6001_C960201-0542': 'C200064-301',
  '278559-6001_C960202-0124': 'C200064-301',
  '278559-6001_C960207-0141': 'C200064-301',
  '278559-6001_C960208-0451': 'C200064-301',
  '278559-6001_C960213-0224': 'C200064-301',
  '278559-6001_C962800-0129': 'C200064-301',
  '278559-6001_C962801-0146': 'C200064-301',
  '278559-6001_C962802-0096': 'C200064-301',
  '278559-6001_C962812-0073': 'C200064-301',
  '278559-6001_C962817-0013': 'C200064-301',
  '278559-6003_C960201-0440': 'C200064-301',
  '278559-6003_C960201-0441': 'C200064-301',
  '278559-6003_C960201-0542': 'C200064-301',
  '278559-6003_C960202-0124': 'C200064-301',
  '278559-6003_C960207-0141': 'C200064-301',
  '278559-6003_C960208-0451': 'C200064-301',
  '278559-6003_C960213-0224': 'C200064-301',
  '278559-6003_C962800-0129': 'C200064-301',
  '278559-6003_C962801-0146': 'C200064-301',
  '278559-6003_C962802-0096': 'C200064-301',
  '278559-6003_C962812-0073': 'C200064-301',
  '278559-6003_C962817-0013': 'C200064-301',
  '274808-5001_C960201-0440': 'C200064-501',
  '274808-5001_C960201-0441': 'C200064-501',
  '274808-5001_C960201-0542': 'C200064-501',
  '274808-5001_C960202-0124': 'C200064-501',
  '274808-5001_C960207-0141': 'C200064-501',
  '274808-5001_C960208-0451': 'C200064-501',
  '274808-5001_C960213-0224': 'C200064-501',
  '274808-5001_C962800-0129': 'C200064-501',
  '274808-5001_C962801-0146': 'C200064-501',
  '274808-5001_C962802-0096': 'C200064-501',
  '274808-5001_C962812-0073': 'C200064-501',
  '274808-5001_C962817-0013': 'C200064-501',
  '274808-5003_C960201-0440': 'C200064-501',
  '274808-5003_C960201-0441': 'C200064-501',
  '274808-5003_C960201-0542': 'C200064-501',
  '274808-5003_C960202-0124': 'C200064-501',
  '274808-5003_C960207-0141': 'C200064-501',
  '274808-5003_C960208-0451': 'C200064-501',
  '274808-5003_C960213-0224': 'C200064-501',
  '274808-5003_C962800-0129': 'C200064-501',
  '274808-5003_C962801-0146': 'C200064-501',
  '274808-5003_C962802-0096': 'C200064-501',
  '274808-5003_C962812-0073': 'C200064-501',
  '274808-5003_C962817-0013': 'C200064-501',
  '278559-6008_C960201-0440': 'C200064-301',
  '278559-6008_C960201-0441': 'C200064-301',
  '278559-6008_C960201-0542': 'C200064-301',
  '278559-6008_C960202-0124': 'C200064-301',
  '278559-6008_C960207-0141': 'C200064-301',
  '278559-6008_C960208-0451': 'C200064-301',
  '278559-6008_C960213-0224': 'C200064-301',
  '278559-6008_C962800-0129': 'C200064-301',
  '278559-6008_C962801-0146': 'C200064-301',
  '278559-6008_C962802-0096': 'C200064-301',
  '278559-6008_C962812-0073': 'C200064-301',
  '278559-6008_C962817-0013': 'C200064-301',
  '278559-6006_C960201-0440': 'C200064-301',
  '278559-6006_C960201-0441': 'C200064-301',
  '278559-6006_C960201-0542': 'C200064-301',
  '278559-6006_C960202-0124': 'C200064-301',
  '278559-6006_C960207-0141': 'C200064-301',
  '278559-6006_C960208-0451': 'C200064-301',
  '278559-6006_C960213-0224': 'C200064-301',
  '278559-6006_C962800-0129': 'C200064-301',
  '278559-6006_C962801-0146': 'C200064-301',
  '278559-6006_C962802-0096': 'C200064-301',
  '278559-6006_C962812-0073': 'C200064-301',
  '278559-6006_C962817-0013': 'C200064-301',
  '274808-5008_C960201-0440': 'C200064-501',
  '274808-5008_C960201-0441': 'C200064-501',
  '274808-5008_C960201-0542': 'C200064-501',
  '274808-5008_C960202-0124': 'C200064-501',
  '274808-5008_C960207-0141': 'C200064-501',
  '274808-5008_C960208-0451': 'C200064-501',
  '274808-5008_C960213-0224': 'C200064-501',
  '274808-5008_C962800-0129': 'C200064-501',
  '274808-5008_C962801-0146': 'C200064-501',
  '274808-5008_C962802-0096': 'C200064-501',
  '274808-5008_C962812-0073': 'C200064-501',
  '274808-5008_C962817-0013': 'C200064-501',
  '274808-5006_C960201-0440': 'C200064-501',
  '274808-5006_C960201-0441': 'C200064-501',
  '274808-5006_C960201-0542': 'C200064-501',
  '274808-5006_C960202-0124': 'C200064-501',
  '274808-5006_C960207-0141': 'C200064-501',
  '274808-5006_C960208-0451': 'C200064-501',
  '274808-5006_C960213-0224': 'C200064-501',
  '274808-5006_C962800-0129': 'C200064-501',
  '274808-5006_C962801-0146': 'C200064-501',
  '274808-5006_C962802-0096': 'C200064-501',
  '274808-5006_C962812-0073': 'C200064-501',
  '274808-5006_C962817-0013': 'C200064-501',
  '278608-3001_C960201-0440': 'C200064-301',
  '278608-3001_C960201-0441': 'C200064-301',
  '278608-3001_C960201-0542': 'C200064-301',
  '278608-3001_C960202-0124': 'C200064-301',
  '278608-3001_C960207-0141': 'C200064-301',
  '278608-3001_C960208-0451': 'C200064-301',
  '278608-3001_C960213-0224': 'C200064-301',
  '278608-3001_C962800-0129': 'C200064-301',
  '278608-3001_C962801-0146': 'C200064-301',
  '278608-3001_C962802-0096': 'C200064-301',
  '278608-3001_C962812-0073': 'C200064-301',
  '278608-3001_C962817-0013': 'C200064-301',
  '278608-3003_C960201-0440': 'C200064-301',
  '278608-3003_C960201-0441': 'C200064-301',
  '278608-3003_C960201-0542': 'C200064-301',
  '278608-3003_C960202-0124': 'C200064-301',
  '278608-3003_C960207-0141': 'C200064-301',
  '278608-3003_C960208-0451': 'C200064-301',
  '278608-3003_C960213-0224': 'C200064-301',
  '278608-3003_C962800-0129': 'C200064-301',
  '278608-3003_C962801-0146': 'C200064-301',
  '278608-3003_C962802-0096': 'C200064-301',
  '278608-3003_C962812-0073': 'C200064-301',
  '278608-3003_C962817-0013': 'C200064-301',
  '278608-6001_C960201-0440': 'C200064-301',
  '278608-6001_C960201-0441': 'C200064-301',
  '278608-6001_C960201-0542': 'C200064-301',
  '278608-6001_C960202-0124': 'C200064-301',
  '278608-6001_C960207-0141': 'C200064-301',
  '278608-6001_C960208-0451': 'C200064-301',
  '278608-6001_C960213-0224': 'C200064-301',
  '278608-6001_C962800-0129': 'C200064-301',
  '278608-6001_C962801-0146': 'C200064-301',
  '278608-6001_C962802-0096': 'C200064-301',
  '278608-6001_C962812-0073': 'C200064-301',
  '278608-6001_C962817-0013': 'C200064-301',
  '278608-6003_C960201-0440': 'C200064-301',
  '278608-6003_C960201-0441': 'C200064-301',
  '278608-6003_C960201-0542': 'C200064-301',
  '278608-6003_C960202-0124': 'C200064-301',
  '278608-6003_C960207-0141': 'C200064-301',
  '278608-6003_C960208-0451': 'C200064-301',
  '278608-6003_C960213-0224': 'C200064-301',
  '278608-6003_C962800-0129': 'C200064-301',
  '278608-6003_C962801-0146': 'C200064-301',
  '278608-6003_C962802-0096': 'C200064-301',
  '278608-6003_C962812-0073': 'C200064-301',
  '278608-6003_C962817-0013': 'C200064-301',
  '275378-5001_C960201-0440': 'C200064-501',
  '275378-5001_C960201-0441': 'C200064-501',
  '275378-5001_C960201-0542': 'C200064-501',
  '275378-5001_C960202-0124': 'C200064-501',
  '275378-5001_C960207-0141': 'C200064-501',
  '275378-5001_C960208-0451': 'C200064-501',
  '275378-5001_C960213-0224': 'C200064-501',
  '275378-5001_C962800-0129': 'C200064-501',
  '275378-5001_C962801-0146': 'C200064-501',
  '275378-5001_C962802-0096': 'C200064-501',
  '275378-5001_C962812-0073': 'C200064-501',
  '275378-5001_C962817-0013': 'C200064-501',
  '275378-5003_C960201-0440': 'C200064-501',
  '275378-5003_C960201-0441': 'C200064-501',
  '275378-5003_C960201-0542': 'C200064-501',
  '275378-5003_C960202-0124': 'C200064-501',
  '275378-5003_C960207-0141': 'C200064-501',
  '275378-5003_C960208-0451': 'C200064-501',
  '275378-5003_C960213-0224': 'C200064-501',
  '275378-5003_C962800-0129': 'C200064-501',
  '275378-5003_C962801-0146': 'C200064-501',
  '275378-5003_C962802-0096': 'C200064-501',
  '275378-5003_C962812-0073': 'C200064-501',
  '275378-5003_C962817-0013': 'C200064-501',
  '278602-6001_C960201-0440': 'C201065-301',
  '278602-6001_C960201-0441': 'C201065-301',
  '278602-6001_C960201-0542': 'C201065-301',
  '278602-6001_C960202-0124': 'C201065-301',
  '278602-6001_C960207-0141': 'C201065-301',
  '278602-6001_C960208-0451': 'C201065-301',
  '278602-6001_C960213-0224': 'C201065-301',
  '278602-6001_C962800-0129': 'C201065-301',
  '278602-6001_C962801-0146': 'C201065-301',
  '278602-6001_C962802-0096': 'C201065-301',
  '278602-6001_C962812-0073': 'C201065-301',
  '278602-6001_C962817-0013': 'C201065-301',
  '278602-6003_C960201-0440': 'C201065-301',
  '278602-6003_C960201-0441': 'C201065-301',
  '278602-6003_C960201-0542': 'C201065-301',
  '278602-6003_C960202-0124': 'C201065-301',
  '278602-6003_C960207-0141': 'C201065-301',
  '278602-6003_C960208-0451': 'C201065-301',
  '278602-6003_C960213-0224': 'C201065-301',
  '278602-6003_C962800-0129': 'C201065-301',
  '278602-6003_C962801-0146': 'C201065-301',
  '278602-6003_C962802-0096': 'C201065-301',
  '278602-6003_C962812-0073': 'C201065-301',
  '278602-6003_C962817-0013': 'C201065-301',
  '278573-3011_C960201-0440': 'C200064-301',
  '278573-3011_C960201-0441': 'C200064-301',
  '278573-3011_C960201-0542': 'C200064-301',
  '278573-3011_C960202-0124': 'C200064-301',
  '278573-3011_C960207-0141': 'C200064-301',
  '278573-3011_C960208-0451': 'C200064-301',
  '278573-3011_C960213-0224': 'C200064-301',
  '278573-3011_C962800-0129': 'C200064-301',
  '278573-3011_C962801-0146': 'C200064-301',
  '278573-3011_C962802-0096': 'C200064-301',
  '278573-3011_C962812-0073': 'C200064-301',
  '278573-3011_C962817-0013': 'C200064-301',
  '278573-3013_C960201-0440': 'C200064-301',
  '278573-3013_C960201-0441': 'C200064-301',
  '278573-3013_C960201-0542': 'C200064-301',
  '278573-3013_C960202-0124': 'C200064-301',
  '278573-3013_C960207-0141': 'C200064-301',
  '278573-3013_C960208-0451': 'C200064-301',
  '278573-3013_C960213-0224': 'C200064-301',
  '278573-3013_C962800-0129': 'C200064-301',
  '278573-3013_C962801-0146': 'C200064-301',
  '278573-3013_C962802-0096': 'C200064-301',
  '278573-3013_C962812-0073': 'C200064-301',
  '278573-3013_C962817-0013': 'C200064-301',
  '278573-6013_C960201-0440': 'C200064-301',
  '278573-6013_C960201-0441': 'C200064-301',
  '278573-6013_C960201-0542': 'C200064-301',
  '278573-6013_C960202-0124': 'C200064-301',
  '278573-6013_C960207-0141': 'C200064-301',
  '278573-6013_C960208-0451': 'C200064-301',
  '278573-6013_C960213-0224': 'C200064-301',
  '278573-6013_C962800-0129': 'C200064-301',
  '278573-6013_C962801-0146': 'C200064-301',
  '278573-6013_C962802-0096': 'C200064-301',
  '278573-6013_C962812-0073': 'C200064-301',
  '278573-6013_C962817-0013': 'C200064-301',
  '278573-6015_C960201-0440': 'C200064-301',
  '278573-6015_C960201-0441': 'C200064-301',
  '278573-6015_C960201-0542': 'C200064-301',
  '278573-6015_C960202-0124': 'C200064-301',
  '278573-6015_C960207-0141': 'C200064-301',
  '278573-6015_C960208-0451': 'C200064-301',
  '278573-6015_C960213-0224': 'C200064-301',
  '278573-6015_C962800-0129': 'C200064-301',
  '278573-6015_C962801-0146': 'C200064-301',
  '278573-6015_C962802-0096': 'C200064-301',
  '278573-6015_C962812-0073': 'C200064-301',
  '278573-6015_C962817-0013': 'C200064-301',
  '278573-6018_C960201-0440': 'C200064-301',
  '278573-6018_C960201-0441': 'C200064-301',
  '278573-6018_C960201-0542': 'C200064-301',
  '278573-6018_C960202-0124': 'C200064-301',
  '278573-6018_C960207-0141': 'C200064-301',
  '278573-6018_C960208-0451': 'C200064-301',
  '278573-6018_C960213-0224': 'C200064-301',
  '278573-6018_C962800-0129': 'C200064-301',
  '278573-6018_C962801-0146': 'C200064-301',
  '278573-6018_C962802-0096': 'C200064-301',
  '278573-6018_C962812-0073': 'C200064-301',
  '278573-6018_C962817-0013': 'C200064-301',
  '278573-6020_C960201-0440': 'C200064-301',
  '278573-6020_C960201-0441': 'C200064-301',
  '278573-6020_C960201-0542': 'C200064-301',
  '278573-6020_C960202-0124': 'C200064-301',
  '278573-6020_C960207-0141': 'C200064-301',
  '278573-6020_C960208-0451': 'C200064-301',
  '278573-6020_C960213-0224': 'C200064-301',
  '278573-6020_C962800-0129': 'C200064-301',
  '278573-6020_C962801-0146': 'C200064-301',
  '278573-6020_C962802-0096': 'C200064-301',
  '278573-6020_C962812-0073': 'C200064-301',
  '278573-6020_C962817-0013': 'C200064-301',
  '278559-3011_C960201-0440': 'C200064-301',
  '278559-3011_C960201-0441': 'C200064-301',
  '278559-3011_C960201-0542': 'C200064-301',
  '278559-3011_C960202-0124': 'C200064-301',
  '278559-3011_C960207-0141': 'C200064-301',
  '278559-3011_C960208-0451': 'C200064-301',
  '278559-3011_C960213-0224': 'C200064-301',
  '278559-3011_C962800-0129': 'C200064-301',
  '278559-3011_C962801-0146': 'C200064-301',
  '278559-3011_C962802-0096': 'C200064-301',
  '278559-3011_C962812-0073': 'C200064-301',
  '278559-3011_C962817-0013': 'C200064-301',
  '278573-6011_C960201-0440': 'C200064-301',
  '278573-6011_C960201-0441': 'C200064-301',
  '278573-6011_C960201-0542': 'C200064-301',
  '278573-6011_C960202-0124': 'C200064-301',
  '278573-6011_C960207-0141': 'C200064-301',
  '278573-6011_C960208-0451': 'C200064-301',
  '278573-6011_C960213-0224': 'C200064-301',
  '278573-6011_C962800-0129': 'C200064-301',
  '278573-6011_C962801-0146': 'C200064-301',
  '278573-6011_C962802-0096': 'C200064-301',
  '278573-6011_C962812-0073': 'C200064-301',
  '278573-6011_C962817-0013': 'C200064-301',
  '278573-6026_C960201-0440': 'C200064-301',
  '278573-6026_C960201-0441': 'C200064-301',
  '278573-6026_C960201-0542': 'C200064-301',
  '278573-6026_C960202-0124': 'C200064-301',
  '278573-6026_C960207-0141': 'C200064-301',
  '278573-6026_C960208-0451': 'C200064-301',
  '278573-6026_C960213-0224': 'C200064-301',
  '278573-6026_C962800-0129': 'C200064-301',
  '278573-6026_C962801-0146': 'C200064-301',
  '278573-6026_C962802-0096': 'C200064-301',
  '278573-6026_C962812-0073': 'C200064-301',
  '278573-6026_C962817-0013': 'C200064-301',
  '278573-6027_C960201-0440': 'C200064-301',
  '278573-6027_C960201-0441': 'C200064-301',
  '278573-6027_C960201-0542': 'C200064-301',
  '278573-6027_C960202-0124': 'C200064-301',
  '278573-6027_C960207-0141': 'C200064-301',
  '278573-6027_C960208-0451': 'C200064-301',
  '278573-6027_C960213-0224': 'C200064-301',
  '278573-6027_C962800-0129': 'C200064-301',
  '278573-6027_C962801-0146': 'C200064-301',
  '278573-6027_C962802-0096': 'C200064-301',
  '278573-6027_C962812-0073': 'C200064-301',
  '278573-6027_C962817-0013': 'C200064-301',
  '275378-5005_C960201-0440': 'C200064-501',
  '275378-5005_C960201-0441': 'C200064-501',
  '275378-5005_C960201-0542': 'C200064-501',
  '275378-5005_C960202-0124': 'C200064-501',
  '275378-5005_C960207-0141': 'C200064-501',
  '275378-5005_C960208-0451': 'C200064-501',
  '275378-5005_C960213-0224': 'C200064-501',
  '275378-5005_C962800-0129': 'C200064-501',
  '275378-5005_C962801-0146': 'C200064-501',
  '275378-5005_C962802-0096': 'C200064-501',
  '275378-5005_C962812-0073': 'C200064-501',
  '275378-5005_C962817-0013': 'C200064-501',
  '278590-6003_C960200-0817': 'C200064-301',
  '278590-6003_C960201-0932': 'C200064-301',
  '278590-6003_C960201-1194': 'C200064-301',
  '278590-6003_C960202-0329': 'C200064-301',
  '278590-6003_C960207-0312': 'C200064-301',
  '278590-6003_C960212-0316': 'C200064-301',
  '278590-6003_C960217-0239': 'C200064-301',
  '278590-6003_C960400-0077': 'C200064-301',
  '278590-6003_C960417-0027': 'C200064-301',
  '278590-6001_C960200-0817': 'C200064-301',
  '278590-6001_C960201-0932': 'C200064-301',
  '278590-6001_C960201-1194': 'C200064-301',
  '278590-6001_C960202-0329': 'C200064-301',
  '278590-6001_C960207-0312': 'C200064-301',
  '278590-6001_C960212-0316': 'C200064-301',
  '278590-6001_C960217-0239': 'C200064-301',
  '278590-6001_C960400-0077': 'C200064-301',
  '278590-6001_C960417-0027': 'C200064-301',
  '278590-3003_C960200-0817': 'C200064-301',
  '278590-3003_C960201-0932': 'C200064-301',
  '278590-3003_C960201-1194': 'C200064-301',
  '278590-3003_C960202-0329': 'C200064-301',
  '278590-3003_C960207-0312': 'C200064-301',
  '278590-3003_C960212-0316': 'C200064-301',
  '278590-3003_C960217-0239': 'C200064-301',
  '278590-3003_C960400-0077': 'C200064-301',
  '278590-3003_C960417-0027': 'C200064-301',
  '278590-3001_C960200-0817': 'C200064-301',
  '278590-3001_C960201-0932': 'C200064-301',
  '278590-3001_C960201-1194': 'C200064-301',
  '278590-3001_C960202-0329': 'C200064-301',
  '278590-3001_C960207-0312': 'C200064-301',
  '278590-3001_C960212-0316': 'C200064-301',
  '278590-3001_C960217-0239': 'C200064-301',
  '278590-3001_C960400-0077': 'C200064-301',
  '278590-3001_C960417-0027': 'C200064-301',
  '278582-6007_C960200-0817': 'C200064-301',
  '278582-6007_C960201-0932': 'C200064-301',
  '278582-6007_C960201-1194': 'C200064-301',
  '278582-6007_C960202-0329': 'C200064-301',
  '278582-6007_C960207-0312': 'C200064-301',
  '278582-6007_C960212-0316': 'C200064-301',
  '278582-6007_C960217-0239': 'C200064-301',
  '278582-6007_C960400-0077': 'C200064-301',
  '278582-6007_C960417-0027': 'C200064-301',
  '278582-6005_C960200-0817': 'C200064-301',
  '278582-6005_C960201-0932': 'C200064-301',
  '278582-6005_C960201-1194': 'C200064-301',
  '278582-6005_C960202-0329': 'C200064-301',
  '278582-6005_C960207-0312': 'C200064-301',
  '278582-6005_C960212-0316': 'C200064-301',
  '278582-6005_C960217-0239': 'C200064-301',
  '278582-6005_C960400-0077': 'C200064-301',
  '278582-6005_C960417-0027': 'C200064-301',
  '278582-3003_C960200-0817': 'C200064-301',
  '278582-3003_C960201-0932': 'C200064-301',
  '278582-3003_C960201-1194': 'C200064-301',
  '278582-3003_C960202-0329': 'C200064-301',
  '278582-3003_C960207-0312': 'C200064-301',
  '278582-3003_C960212-0316': 'C200064-301',
  '278582-3003_C960217-0239': 'C200064-301',
  '278582-3003_C960400-0077': 'C200064-301',
  '278582-3003_C960417-0027': 'C200064-301',
  '278582-3001_C960200-0817': 'C200064-301',
  '278582-3001_C960201-0932': 'C200064-301',
  '278582-3001_C960201-1194': 'C200064-301',
  '278582-3001_C960202-0329': 'C200064-301',
  '278582-3001_C960207-0312': 'C200064-301',
  '278582-3001_C960212-0316': 'C200064-301',
  '278582-3001_C960217-0239': 'C200064-301',
  '278582-3001_C960400-0077': 'C200064-301',
  '278582-3001_C960417-0027': 'C200064-301'
};

export const landingVideoUrl = {
  [LandingFrame.LanguageOption]: `${videoBaseUrl}/content/converted/a18fcbbe-48a0-423b-ba7b-b766f63de9fb/chopard-landscape-intro-part01.mp4/index_high.m3u8`,
  [LandingFrame.Intro]: `${videoBaseUrl}/content/converted/a18fcbbe-48a0-423b-ba7b-b766f63de9fb/chopard-landscape-intro-part02.mp4/index_high.m3u8`,
  [LandingFrame.Outro]: `${videoBaseUrl}/content/converted/69a7649d-4eb0-4eae-932d-650f7d3a1e53/chopard-landscape-intro-part03.mp4/index_high.m3u8`
};

export const landingVideoUrlPortrait = {
  [LandingFrame.LanguageOption]: `${videoBaseUrl}/content/converted/a18fcbbe-48a0-423b-ba7b-b766f63de9fb/chopard-portrait-intro-part01.mp4/index_medium.m3u8`,
  [LandingFrame.Intro]: `${videoBaseUrl}/content/converted/a18fcbbe-48a0-423b-ba7b-b766f63de9fb/chopard-portrait-intro-part02.mp4/index_medium.m3u8`,
  [LandingFrame.Outro]: `${videoBaseUrl}/content/converted/69a7649d-4eb0-4eae-932d-650f7d3a1e53/chopard-portrait-intro-part03.mp4/index_medium.m3u8`
};

export const commandForAnalytics = [
  'CLICK_212561-0001',
  'CLICK_212564-0001',
  'CLICK_TEXT1001',
  'CLICK_STORY1001',
  'CLICK_VIDEO1001',
  'CLICK_CHAPTER 2 DOOR',
  'CAMERA_TURN'
];
