import { useRouter } from 'next/router';
import React from 'react';
import { logEvent } from '../../../analytics';
import { useTranslation } from '../../../i18n';
import {
  DID_CLICK_PRIVACY_POLICY,
  DID_CLICK_TERMS_OF_USE,
  DID_SELECT_LANGUAGE
} from '../../../utils/constants';
import { LandingFrame } from './CHLanding';
import CHLoadingIcon from './CHLoadingIcon';
import CHStatusScreen from './CHStatusScreen';
import CHTermsPrivacy from './CHTermsPrivacy';

const TextSequence = ({
  subTitle,
  text,
  show
}: {
  subTitle?: string;
  text: string;
  show: boolean;
}) => {
  return (
    <div className={show ? '' : 'hide'}>
      <button className="btn btn-action btn-outline-light noselect">
        {text}
      </button>
      {subTitle && <p className="chopard-sans">{subTitle}</p>}
      <style jsx>{`
        div {
          opacity: 1;
          transition: opacity 0.5s linear;
        }
        .btn {
          background: rgba(58, 58, 58, 0.9);
        }
        .chopard-sans {
          opacity: 1;
          transition: opacity 0.5s linear;
        }
        .hide {
          opacity: 0;
        }
        .chopard-content {
          font-size: 12px;
          letter-spacing: 0 !important;
        }
        .dotstep {
          margin: -10px auto 20px;
        }
        .dot {
          display: inline-block;
          width: 15px;
          height: 15px;
          border: 1px solid #fff;
          margin: 0 6px;
          border-radius: 100px;
        }
        .dot.filled {
          background: #fff;
        }
      `}</style>
    </div>
  );
};
const LoadingWait = ({ subTitle }: { subTitle?: string }) => {
  const { t } = useTranslation();
  return (
    <div className="loading-icon">
      <span className="please-wait">{t('pleaseWait')}...</span>
      <CHLoadingIcon />
      {subTitle && <p className="chopard-content">{subTitle}.</p>}
      <style jsx>{`
        .loading-icon {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .please-wait {
          font-size: 0.7em;
          letter-spacing: 0.2em;
        }
        .chopard-content {
          font-size: 12px;
          letter-spacing: 0 !important;
        }
      `}</style>
    </div>
  );
};

const EnterJourneyButton = ({ loading }: { loading?: boolean }) => {
  const { t } = useTranslation();
  return loading ? (
    <LoadingWait />
  ) : (
    <button className="btn btn-action btn-outline-light noselect">
      {t('enter_journey')}
    </button>
  );
};

function CHLandingAction({
  show,
  onEnter,
  useImage,
  loading,
  landingFrame,
  nextLandingFrame,
  isPortrait,
  skipTutorial,
  onSkip
}: {
  show: boolean;
  title: string;
  subTitle: string;
  onEnter: () => void;
  useImage: boolean;
  loading: boolean;
  landingFrame: LandingFrame;
  nextLandingFrame: LandingFrame;
  isPortrait?: boolean;
  skipTutorial?: boolean;
  onSkip?: () => void;
}) {
  const router = useRouter();
  const debug = router.query.debug as string;
  const { i18n, t } = useTranslation();
  const language = i18n.language || 'en';

  const [termPrivacy, setTermPrivacy] = React.useState<'terms' | 'privacy'>(
    null
  );

  const [skip, setSkip] = React.useState(false);

  const handleChangeLanguage = (lang: string) => {
    logEvent(DID_SELECT_LANGUAGE, DID_SELECT_LANGUAGE, { language: lang });
    i18n.changeLanguage(lang);
    onEnter();
  };

  const shouldShowEnterButton =
    (useImage && landingFrame !== LandingFrame.LanguageOption) || skipTutorial;

  const loadingScene = (
    <div>
      {!skipTutorial && (
        <>
          <p className="chopard-content">Select your language to begin</p>
          <div className="d-flex">
            <button
              onClick={() => handleChangeLanguage('en')}
              className="btn btn-action btn-outline-light noselect"
            >
              ENGLISH
            </button>
            <button
              onClick={() => handleChangeLanguage('ko')}
              className="btn btn-action btn-outline-light noselect"
            >
              한국어
            </button>
          </div>
          <p className="chopard-content tstc noselect">
            By entering the experience, you agree to the following{' '}
            <span
              onClick={() => {
                setTermPrivacy('terms');
                logEvent(DID_CLICK_TERMS_OF_USE, DID_CLICK_TERMS_OF_USE);
              }}
            >
              terms of use
            </span>{' '}
            and{' '}
            <span
              onClick={() => {
                setTermPrivacy('privacy');
                logEvent(DID_CLICK_PRIVACY_POLICY, DID_CLICK_PRIVACY_POLICY);
              }}
            >
              privacy policy
            </span>
            .
          </p>
        </>
      )}
      {shouldShowEnterButton && <EnterJourneyButton loading={loading} />}
      <CHTermsPrivacy
        type={termPrivacy}
        language={language}
        onClose={() => setTermPrivacy(null)}
      />
      <style jsx>{`
        span {
          text-decoration: underline;
          cursor: pointer;
        }
        div {
          margin: auto;
          max-width: 400px;
        }
        .btn {
          width: 46% !important;
          min-width: auto !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        .btn:first-child {
          margin-right: 2%;
        }
        .btn:last-child {
          margin-left: 2%;
        }
        .chopard-content {
          font-size: 12px;
          font-weight: normal;
          letter-spacing: 0 !important;
          max-width: 300px;
          margin: 10px auto;
        }
      `}</style>
    </div>
  );

  const actionElements =
    landingFrame === LandingFrame.LanguageOption ? (
      loadingScene
    ) : loading ? (
      <LoadingWait subTitle={t('dont_refresh')} />
    ) : (
      <TextSequence
        show={!!nextLandingFrame || useImage}
        text={t('enter_journey')}
      />
    );

  return (
    <>
      <CHStatusScreen
        show={show}
        useImage={useImage}
        actionElement={actionElements}
        isPortrait={isPortrait}
        centered={nextLandingFrame === LandingFrame.Outro && !useImage}
        backdrop={
          nextLandingFrame === LandingFrame.Outro && !useImage
            ? 'rgba(0,0,0,.9)'
            : 'transparent'
        }
      >
        {null}
      </CHStatusScreen>

      {debug &&
        !loading &&
        !skip &&
        landingFrame !== LandingFrame.LanguageOption && (
          <button
            className="btn btn-action btn-sm btn-outline-light noselect"
            onClick={() => {
              onSkip();
              setSkip(true);
            }}
          >
            {t('skip')}
          </button>
        )}
      {landingFrame === LandingFrame.Intro && (
        <div className="bar-top chopard-sans">
          {language === 'ko'
            ? '경험을 로드하는 중입니다. 몇 초 동안 기다리시고 브라우저를 닫지 마십시오.'
            : 'THE EXPERIENCE IS LOADING, PLEASE HOLD FEW SECONDS AND DO NOT CLOSE YOUR BROWSER.'}
        </div>
      )}
      <style jsx>{`
        :global(.NavSound) {
          left: 0 !important;
        }
        .bar-top {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          padding: 10px;
          pointer-events: none;
          color: #fff;
          font-size: 0.9em;
          text-align: center;
          font-weight: normal;
          letter-spacing: 0.05em;
        }
        .btn-action {
          position: fixed;
          right: 20px;
          bottom: 40px;
          left: auto;
          display: inline-block;
          z-index: 2;
          width: auto;
          min-width: auto;
          text-align: right;
          margin: 0;
        }
        .actions {
          z-index: 1;
        }
        .pulsating {
          position: absolute;
          height: auto;
          transform: translate(-50%, -50%);
          pointer-events: none;
          z-index: 1;
          opacity: 0;
          transition: opacity 0.3s linear;
        }

        .pulsating.ZoomOut {
          left: 50%;
          bottom: 0;
          width: 200px;
          transform: translate(-50%, 40px);
        }
        .pulsating.Outro {
          pointer-events: none;
        }

        .pulsating.show {
          opacity: 1;
          pointer-events: all;
          cursor: pointer;
        }

        .btn-back {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          gap: 7px;
          pointer-events: none;
          font-size: 14px;
          justify-content: center;
          color: #fff;
          outline: none;
          box-shadow: none;
        }
        .nav-back {
          position: absolute;
          bottom: 40px;
          left: 0;
          right: 0;
          pointer-events: none;
        }
        .turnLeft {
          pointer-events: none;
          position: absolute;
          width: 80vw;
          max-width: 400px;
          left: 50%;
          bottom: 50px;
          transform: translateX(-50%);
        }
        .pulsating.ZoomIn {
          top: calc(50% + 13.5vh);
          width: 18vh;
          left: calc(50% + 12vh);
        }
        @media (orientation: portrait) {
          :global(.ZoomIn + .landing-container .action-container) {
            position: fixed;
            left: 40%;
            text-align: left;
            top: 50%;
            transform: translateY(-35%);
          }
          .pulsating.Outro {
            left: calc(50% + 17.4vh);
            top: calc(50% + 12vh);
            width: 12vh;
          }
        }
        @media (orientation: landscape) {
          .pulsating.Outro {
            left: calc(50% + 3vh);
            width: 10vh;
            top: calc(50% - 1vh);
          }
          .pulsating.ZoomIn {
            left: calc(50% - 4.5vh);
            top: calc(50% + 1vh);
            width: 25vh;
          }
          .turnLeft {
            transform: translateX(-100%);
          }
        }
        @media (orientation: landscape) and (max-height: 500px) {
          .nav-back {
            bottom: 20px;
          }
          .pulsating.ZoomOut {
            left: 50%;
            bottom: 0;
            width: 150px;
            transform: translate(-50%, 40px);
          }
          .turnLeft {
            bottom: 0;
            max-width: 300px;
          }
        }
        @media (orientation: landscape) and (min-aspect-ratio: 192/108) {
          .pulsating.ZoomIn {
            left: calc(50% - 2.5vw);
            top: calc(50% + 1vw);
            width: 16vw;
          }
          .pulsating.Outro {
            left: calc(50% + 1.7vw);
            top: calc(50% - 0.5vw);
            width: 12vh;
          }
        }
      `}</style>
    </>
  );
}

export default CHLandingAction;
