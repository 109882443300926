import React from 'react';
import { BsX } from 'react-icons/bs';

const CHTermsPrivacy = ({
  language,
  onClose,
  type
}: {
  language?: string;
  onClose: () => void;
  type?: 'terms' | 'privacy';
}) => {
  const [isKorean, setIsKorean] = React.useState(language !== 'en');

  const enTerm = (
    <div>
      <h1>TERMS OF WEBSITE USE</h1>
      <h2 className="chopard-serif">
        Please read the following information carefully before using the site
      </h2>

      <div className="termcontent chopard-content">
        <div>
          <h3>1. LEGAL INFORMATION&nbsp;&amp; ACCEPTANCE OF TERMS</h3>
          <p>
            1.1 The website at www.chopard.com (the “Website” or the “Site”) is
            operated by Le petit-fils de L.U. Chopard &amp; Cie SA, a Swiss
            corporation (“Chopard”, “it”, “its”, “we”, “us”, “our”), and use
            thereof is governed by the following terms of use ( “Terms of Use”
            or “Terms”). We have drafted these Terms to tell you how you may use
            our Website, our products, and other services we provide you
            (collectively, “Services”).
          </p>
          <p>
            1.2 Please take the time to read these Terms of Use carefully before
            you access our Website or use any of our other Services. If you
            disagree with any clause of these Terms of Use, please exit the
            Website immediately and do not use our other Services.
          </p>
          <p>
            1.3 Chopard may from time to time revise, change, modify and/or
            remove part of these Terms of Use at its sole discretion. The new
            dated version of these Terms of Use takes effect as soon as it is
            published on-line.
          </p>
          <p>
            1.4 These Terms, together with our&nbsp;
            <a
              href="https://www.chopard.com/en-intl/legal-privacy-policy.html"
              target="_blank"
              data-content-page-id="legal-privacy-policy"
            >
              Privacy Policy
            </a>
            , govern Chopard’s relationship to you in relation to this Website.
          </p>
          <h4>2. DESCRIPTION OF SERVICES</h4>
          <p>
            2.1 Chopard provides its Website and Services to help bring its
            quality, sustainable luxury goods to Chopard’s customers, and
            sharing information about one of the last family-run watchmaking and
            jewelry maisons, its members, news, events, services and other
            corporate information to its Website users and others.
          </p>
          <p>
            2.2 Please note that all features, content, specifications of our
            Site or other Services, and any products or prices described or
            depicted, are subject to change without notice. Also, the inclusion
            of any product or service does not imply or warrant that it will be
            available at any time.
          </p>
          <p>
            2.3 While we strive to ensure that our Services and all other
            information we provide are complete, accurate, and current, some
            information provided may sometimes be inaccurate, incomplete, or
            out-of-date. We make no representation regarding the completeness or
            accuracy of any Services, or other information, content, advice, or
            recommendations made through the Site or Services. We also do not
            make any representations or warranties regarding the quality or
            safety of any products or Services, or third party products or
            services offered or made available.
          </p>
          <h4>3. YOUR USE OF OUR SERVICES</h4>
          <p>
            <em>What We Grant You</em>
          </p>
          <p>
            3.1 Chopard grants you a personal, worldwide, royalty-free,
            non-assignable, non-exclusive, revocable, and limited license to
            access and use our Services, for the sole purposes of letting you,
            the user, use and enjoy the benefits of the Website for a personal
            non-commercial use as permitted by these Terms and our other
            policies. Please see Section 6, “Use of the Website,” below for more
            information on how we permit you to use our Website.
          </p>
          <p>
            <em>What You Grant to Us</em>
          </p>
          <p>
            3.2 In using our Services, you may provide us with or otherwise
            allow us to use certain content or other information from you– for
            instance, when you provide us photos or other information about your
            experience with Chopard watches, jewelry, and more. In doing so, you
            grant Chopard an unrestricted, irrevocable, worldwide, royalty-free,
            perpetual, sub-licensable, and transferable license to host, store,
            use, display, reproduce, modify, adapt, edit, publish, and
            distribute the content or other information you provide. Consistent
            with our&nbsp;
            <a
              href="https://www.chopard.com/en-intl/legal-privacy-policy.html"
              target="_blank"
              data-content-page-id="legal-privacy-policy"
            >
              Privacy Policy
            </a>
            <a
              href="https://www.chopard.com/en-intl/legal-privacy-policy.html"
              target="_blank"
              data-content-page-id="legal-privacy-policy"
            >
              ,
            </a>{' '}
            we may use any content or other information that you provide in a
            number of ways, including but not limited to facilitating your
            Chopard experience and for operating, developing, providing,
            promoting, and improving the Services, as well as for researching
            and creating new Chopard services.
          </p>
          <p>
            3.3 In addition, you understand that any questions, comments,
            suggestions, ideas, testimonials, endorsements, drawings, concepts,
            photos or feedback (collectively, “Submissions”) you provide are not
            confidential, and become our sole property. If you provide feedback,
            suggestions, or any other Submission, including but not limited to
            business ideas and original works, you are granting Chopard a
            worldwide, royalty-free, perpetual, fully sub-licensable, and
            irrevocable right to use, distribute, reproduce, modify, adapt,
            display, create works from and otherwise utilize such Submissions.
            This means we can use the ideas you provide to us without
            compensating you or mentioning you.
          </p>
          <p>
            3.4 Though we are not required to do so, we may, in our sole
            discretion, monitor or review the Website, and access, review,
            screen, remove, and delete content at any time and for any reason,
            including if we believe that content violates these Terms.
          </p>
          <p>
            <em>Your Behavior</em>
          </p>
          <p>
            3.5 You are ultimately responsible for all content provided and
            activity by you or that otherwise occurs under your account (even if
            that content or activity occurs from other individuals who have
            accessed the Site and other Services through your account).
          </p>
          <p>3.6 By using our Services, you agree that:</p>
          <ul>
            <li>
              You will not use the Site or any Services for any purpose that is
              illegal or otherwise prohibited in these Terms.
            </li>
            <li>
              You will not use any automated means – such as any robot, spider,
              etc. – to access the Services or extract other users’ information.
            </li>
            <li>
              You will not copy, modify, distribute, sell, or lease any part of
              our Site or Services.
            </li>
            <li>
              You will not reverse engineer or attempt to extract the source
              code of the software.
            </li>
            <li>
              You will not use or develop any third-party applications to
              interact with the Site or Services or other users’ content or
              information, unless you have our written consent.
            </li>
            <li>
              You will not use the Site or any Services in a way that could
              interfere with, disrupt, negatively affect, or inhibit other
              Chopard users in fully using the Services, or our ability to
              maintain our Services.
            </li>
            <li>
              You will not use the Site or any Services in a way that could
              damage, overburden, or impair the functioning of the Services.
            </li>
            <li>
              You will not use or attempt to use another Chopard user’s account
              without their permission.
            </li>
            <li>You will not solicit other users’ login credentials.</li>
            <li>
              You will not create an account using the name of another person
              with the intent of impersonating the other person, or create an
              account using any name that is offensive, vulgar, or obscene.
            </li>
            <li>
              You will not provide content that is offensive or that contains
              pornography, graphic violence, threats, hate speech, or incites
              violence, or promotes any unlawful activity.
            </li>
            <li>
              You will not use the Services in any way that violates Chopard’s
              rights or those of any third party. You will not use the Services
              to harass, abuse, defame, stalk, or threaten others, including
              other users.
            </li>
            <li>
              You will not compromise the security of the Services, including
              but not limited to uploading viruses or other malicious code.
            </li>
            <li>
              You will not attempt to circumvent any content-filters or
              content-filtering techniques used by Chopard.
            </li>
            <li>
              You will access only those areas or features of the Services that
              we have authorized you to access, and you will not attempt to
              access any areas or features that you are not authorized to
              access. You will not probe, scan, or test the security or
              vulnerability of our Services, including any Chopard system or
              network.
            </li>
            <li>
              You will not send unsolicited or spam emails to other users or to
              Chopard. You will not engage in any activity that violates these
              Terms, and you will not encourage any acts that violate these
              Terms.
            </li>
          </ul>
          <p>
            3.7 Chopard allows you to share information about yourself with
            Chopard – including your photos showing your experiences with our
            products. We also allow you to communicate with us – for instance,
            to ask questions, provide feedback and suggestions, and the like.
            Any communication or other material that you send to us through the
            Internet is and will be deemed to be non-confidential. Chopard has
            no obligation of any kind with respect to such information provided.
            Consequently, any Personal Information (as defined in our&nbsp;
            <a
              href="https://www.chopard.com/en-intl/legal-privacy-policy.html"
              target="_blank"
              data-content-page-id="legal-privacy-policy"
            >
              Privacy Policy
            </a>
            ) or other sensitive information that you choose to share may be
            viewable to others. You agree that any information you share may be
            viewable by others. Please do not share information that you would
            not want to be viewed by others, or otherwise be publicly available,
            such as your Personal Information (as defined in our&nbsp;
            <a
              href="https://www.chopard.com/en-intl/legal-privacy-policy.html"
              target="_blank"
              data-content-page-id="legal-privacy-policy"
            >
              Privacy Policy
            </a>
            ).
          </p>
          <p>
            3.8 There may be times when we ask you for your Personal Information
            in order to facilitate certain Services – for instance, when you
            contact us with feedback or questions, we may ask for your contact
            information so that we can contact you further if needed. When
            communicating with us, please do not share your Personal Information
            with Chopard unless we specifically request it.
          </p>
          <p>
            3.9 We take reasonable and appropriate technical, organizational and
            administrative security measures to protect any Personal Information
            we process from loss, misuse, unauthorized access, disclosure,
            alteration and destruction. Nevertheless, even if we strive to
            protect your Personal Information as much as possible, no
            transmission system or data network can be guaranteed to be 100%
            secure. As a result, we cannot guarantee the security of the
            information you transmit on the Website when transferred through the
            Internet on unsecured networks. You therefore acknowledge that there
            are data security and privacy limitations inherent to the Internet,
            and that the security, integrity, and privacy of any and all
            information exchanged between you and Chopard, and between you and
            other Chopard users, cannot be guaranteed and we cannot be held
            liable. For more information about how we use and protect your
            Personal Information please view our&nbsp;
            <a
              href="https://www.chopard.com/en-intl/legal-privacy-policy.html"
              target="_blank"
              data-content-page-id="legal-privacy-policy"
            >
              Privacy Policy
            </a>
            <a
              href="https://www.chopard.com/en-intl/legal-privacy-policy.html"
              target="_blank"
              data-content-page-id="legal-privacy-policy"
            >
              .
            </a>
          </p>
          <h4>4. PRIVACY</h4>
          <p>
            4.1 Your privacy is important – both to you and to us. Please view
            our&nbsp;
            <a
              href="https://www.chopard.com/en-intl/legal-privacy-policy.html"
              target="_blank"
              data-content-page-id="legal-privacy-policy"
            >
              Privacy Policy
            </a>
            &nbsp;to learn how your information is handled when you register for
            and use our Services.
          </p>
          <h4>5. COPYRIGHT &amp; INTELLECTUAL PROPERTY RIGHTS</h4>
          <p>
            5.1 The name and mark Chopard, the Chopard logos and all other
            Chopard-related marks depicted in this Website, whether registered
            or unregistered, are trademarks or service marks of Chopard and its
            subsidiaries and affiliates. Nothing contained herein or on this
            Website shall be construed as conferring by implication, estoppel,
            or otherwise any license or right under any patent, copyright,
            trademark or other intellectual property of Chopard or any of its
            subsidiaries or affiliates.
          </p>
          <p>
            5.2 The information, photos, text, graphics, videos, audio, tools,
            images, and all other content and materials provided by or made
            available on this Website (“Content”), and the Website itself, are
            protected by copyrights, trademarks and/or other intellectual
            property rights. This Content is owned or licensed by Chopard or its
            subsidiaries or affiliates, or used with permission of their owners.
            This Content is provided for your personal, non-commercial, and
            informational purposes only. This Content also includes, but is not
            limited to the text, graphics, photos, interactive features,
            trademarks, service marks, trade dress, and logos in our Services
            (“Marks”). You may not use, manipulate, copy, reproduce, transmit,
            distribute, broadcast, display, sell, or otherwise use our Content
            and Marks for any purpose whatsoever not permitted by these Terms.
            All rights are reserved, worldwide.
          </p>
          <p>
            5.3 We respect the intellectual property rights of others –
            including copyrights – and prohibit our users from using our
            Services in a way that violates those rights. Some of the content
            available through our Services comes from other users and third
            parties. All other content, trademarks and copyrights that appear
            through our Website are the property of their respective owners. You
            agree that you will not use the Services in a way that violates or
            infringes upon someone else’s rights of publicity, privacy,
            copyright, trademark, or other intellectual property rights.
          </p>
          <p>
            5.4 You may not use the Content, Marks, or Services, or the content
            of others, in ways that are not authorized by these Terms. We may
            terminate your use or prohibit you from using our Services if we
            believe that you are violating the intellectual property rights of
            others in your use of our Site or Services.
          </p>
          <p>
            5.5 We also have the right to disclose your identity to any third
            party who is claiming that any content shared or uploaded by you to
            our Site constitutes a violation of their intellectual property
            rights, or of their right to privacy. We will not be responsible or
            liable to any third party for the content or accuracy of any content
            posted by you or any other user of our Site. We have the right to
            remove any posting you make on our Site in our sole discretion. The
            views expressed by other users on our Site do not represent our
            views or values.
          </p>
          <h4>6. USE OF WEBSITE</h4>
          <p>
            6.1 The materials, information and Content contained on the Website
            is presented for information and promotion purposes only.
          </p>
          <p>
            6.2 The materials, information and Content provided is for your
            personal use only. You may download, display or print the materials,
            information and Content appearing on the Website exclusively for
            personal and non-commercial purposes, provided that the copyright
            notice is retained on each copy and you do not change any trademark
            or other notification of ownership. Downloading or reproduction of
            any document or software gives you no right, title or interest with
            respect to said document or software.
          </p>
          <p>
            6.3 Any other use, including reproduction for purposes other than
            personal non-commercial use, modification, dissemination,
            publication, distribution, transmission, creation derivative works
            form, or broadcast of the materials, information and Content of the
            Website, in whole or in part and by any means, is strictly
            prohibited, except with Chopard’s prior written consent.
          </p>
          <p>
            6.4 Chopard neither warrants nor guarantees that use of any
            materials, information and Content displayed on its Website will not
            infringe on third party rights.
          </p>
          <h4>7. INFORMATION DEEMED NON-CONFIDENTIAL AND SUBMISSIONS</h4>
          <p>
            7.1 Any personal identification or information sent via the Internet
            to the Website is protected and treated according to Chopard’s&nbsp;
            <a
              href="https://www.chopard.com/en-intl/legal-privacy-policy.html"
              target="_blank"
              data-content-page-id="legal-privacy-policy"
            >
              Privacy Policy
            </a>
            . Chopard invites you to read the&nbsp;
            <a
              href="https://www.chopard.com/en-intl/legal-privacy-policy.html"
              target="_blank"
              data-content-page-id="legal-privacy-policy"
            >
              Privacy Policy
            </a>
            &nbsp;carefully before sending any such personal identification or
            information.
          </p>
          <p>
            7.2 You hereby accept that all Submissions and any other documents
            communicated or entrusted to Chopard through its Website, in
            connection with the Website, or by any other means will be
            considered non-confidential, non-proprietary and not personal either
            to you or to anyone else.
          </p>
          <p>
            7.3 As noted in Section 3 above, any Submission made by you becomes
            our sole property. However, you will be responsible for any
            Submission made by you that infringes on the rights of a third
            party, with respect to copyright, registered trademarks, patents,
            trade secrets, private life or any other personal or property
            rights.
          </p>
          <p>
            7.4 Chopard enjoys a worldwide reputation for both the design and
            manufacture of high quality watches, jewelry and other luxury
            products. To this end, it possesses its own sources of creativity,
            in particular highly skilled teams of designers who conceive and
            perfect Chopard’s creations. As a result, Chopard will not agree to
            or accept being the receiver of unsolicited proposals of
            collaboration and it is possible that Chopard may already be working
            on similar ideas. Consequently, you are advised that Chopard is not
            interested in receiving ideas or other proposals.
          </p>
          <h4>8. DISCLAIMER OF WARRANTIES</h4>
          <p>
            8.1 Any materials, information or Content on the Website are
            supplied “as is” in terms of their availability, and with no
            guarantee of any sort, express or implied, including, amongst
            others, implied warranty of merchantability or suitability for any
            particular purpose.
          </p>
          <p>
            8.2 Chopard carries no liability and cannot guarantee that the
            Content on the Website is accurate, complete and up-to date, or that
            the Website contains no defect and that any existing defects will be
            corrected. By using this Website, you agree to do so at your own
            risk and assume full responsibility for any problems inherent in
            using it, any loss of data and costs related to any assistance or
            repair to any hardware and/or software used by you for connecting to
            the Website, and you agree that Chopard bears no liability
            whatsoever for any damage that may be caused by, result from or be
            connected to your use of the Website.
          </p>
          <p>
            8.3 While Chopard takes reasonable and appropriate technical,
            organizational and administrative security measures to protect the
            information we are entrusted with, if you communicate with us by
            e-mail, you should note that the security of Internet e-mail is
            uncertain. By sending sensitive or confidential e-mail messages, you
            accept the risk inherent to such uncertainty and possible lack of
            confidentiality over the Internet.e content of the Website may
            contain inaccuracies or typographical errors. Chopard reserves the
            right to make changes, corrections, and/or improvements to such
            content at any time without prior notice but does not assume any
            responsibility to do so.
          </p>
          <h4>9. LIMITATIONS OF LIABILITY</h4>
          <p>
            9.1 You access, use, browse and navigate on the Website at your own
            risk.
          </p>
          <p>
            9.2 You accept that, to the fullest extent permitted by applicable
            regulations, neither Chopard nor any of its affiliates shall, under
            any circumstances, be held liable for actual, direct, indirect,
            special, punitive, incidental, exemplary or consequential damage, or
            any other damage, whatever its nature, even if Chopard was
            previously informed of the possibility of such damage, whether
            during a contractual action, a prejudice or any other theory,
            resulting from, or connected to the use, incapacity to use or
            execution of information, products or documents, or other Contents
            or Services, on this Website.
          </p>
          <p>
            9.3 All materials, information or Content downloaded or obtained in
            any other manner during the use of the Website are at your own risk.
            Chopard takes no responsibility for any damage or virus which might
            affect your computer equipment or other property by reason of your
            access to, use of, or downloading of any materials, information or
            Content from its Website or for any illegal intrusion or
            intervention in the IT system.
          </p>
          <p>
            9.4 Chopard reserves the right to interrupt or discontinue any or
            all of the functionality of its Website. Chopard accepts no
            responsibility or liability whatsoever for any interruption or
            discontinuing of any or all functionality of its Website resulting
            from actions or omissions of Chopard or any third party.
          </p>
          <h4>10. CHANGE OF INFORMATION</h4>
          <p>
            10.1 The Content of the Website may contain inaccuracies or
            typographical errors. Chopard reserves the right to make changes,
            corrections, and/or improvements to such Content at any time without
            prior notice but does not assume any responsibility to do so.
          </p>
          <p>
            10.2 The style, drawing and colours of Chopard products featured on
            the Website may be modified without prior notice.. The prices of
            gem-set models are dependent on market fluctuations and therefore
            subject to variations. Chopard reserves the right to modify the
            prices and models on this website at any time.
          </p>
          <h4>11. AVAILABILITY OF PRODUCTS AND SERVICES</h4>
          <p>
            11.1 The products featured on the Website are representative of
            Chopard’s collection. However, not all Chopard products are featured
            on the Website.
          </p>
          <p>
            11.2 The Website may contain information on Chopard products and
            services, not all of which are available in every location. A
            reference to a product or service on the Website does not imply that
            such product of service is or will be available.
          </p>
          <p>
            11.3 Products and services described on this Website are available
            while supplies last. The product images on this Website are
            representative and are not necessarily photographs of the actual
            product you will receive. Chopard reserves the right to discontinue
            the production of any product, and to discontinue the offering or
            sale of any product or service at any time, without notice. Chopard
            will not guarantee that any particular product or service displayed
            on this Website will be available at any particular location, even
            if that location has been indicated as a source for that product or
            service.
          </p>
          <p>
            11.4 While every effort is taken to try to ensure that the color,
            design, style, reference number, description, size and pricing (as
            applicable) of the products featured on the Website are
            representative of the original products, variation may occur. As
            such, product and service descriptions on this Website may vary from
            time to time, and typographical errors may occur, with the result
            that product and service descriptions, including but not limited to
            pricing (as applicable), may not be complete, current, or accurate.
            Chopard will not be liable for any error or inaccuracy in the
            description, reference, pricing (where available) and in the
            photographs or graphical representations of products displayed on
            the Website, and is under no obligation to deliver the products
            and/or pay any damages whatsoever. Any questions about products can
            be directed to the Chopard Customer Service Department via our
            online “
            <a
              href="https://www.chopard.com/en-intl/contact.html"
              target="_blank"
              data-content-page-id="contact"
            >
              Contact Us
            </a>
            ” page.
          </p>
          <h4>12. CHOPARD'S RETAIL PRICE (AS APPLICABLE)</h4>
          <p>
            12.1 Chopard reserves the right to change the prices of products and
            services featured on this Website at any time, without notice.
          </p>
          <h4>13. LINKS</h4>
          <p>
            13.1 The Website may contain links to other third party Internet
            websites that are not operated or controlled by Chopard. Those
            addresses or links are provided solely as aids to assist you to
            locate other Internet websites that may be of interest. Chopard
            provides links as a convenience and does not endorse the proprietors
            or content of any linked Websites, including any linked website of
            any company related to Chopard, in control of it, or under common
            control with it.
          </p>
          <p>
            13.2 Chopard has not reviewed any of the websites which link to the
            Website. Chopard shall not be held liable for the availability or
            content of such websites or any article contained on or obtained
            through such websites. Please note that following any link to any
            third party website is at your own risk.
          </p>
          <h4>14. JURISDICTION &amp; CHOICE OF LAW</h4>
          <p>
            14.1 The present Terms of Use embody the full agreement concluded
            between Chopard and yourself concerning the access and use of the
            Website and its Content.
          </p>
          <p>
            14.2 These Terms of Use, as well as your use of the Website and any
            agreement between us, shall be governed by and construed in
            accordance with the laws of Switzerland, without giving effect of
            conflicts of law principles. Any dispute, controversy or claim
            arising out of or in connection with these Terms is subject to the
            exclusive jurisdiction of the ordinary courts of the Canton of
            Geneva, Switzerland, with the exception of an appeal to the Swiss
            Federal Court, and the parties agree and submit to the personal and
            exclusive jurisdiction and venue of this court.
          </p>
          <h4>15. SEVERABILITY</h4>
          <p>
            15.1 If any portion of these Terms is found to be unenforceable,
            that provision will be severed from these Terms, and will not affect
            the validity and enforceability of the remaining Terms.
          </p>
          <h4>16. COMPLETE TERMS</h4>
          <p>
            16.1 Please note that these Terms, along with any other documents
            incorporated by reference, make up the entire agreement between you
            and Chopard. We reserve all rights that are not expressly granted to
            you. You may not transfer any rights given or obligations borne to
            you under these Terms without our express consent. We do not waive
            any provision under these Terms even if it is not enforced. These
            Terms remain in effect until such time as modified or terminated by
            us.
          </p>
          <h4>17. CONTACT US</h4>
          <p>
            If you have any questions regarding these Terms or our practices,
            you can contact us via our online “
            <a
              href="https://www.chopard.com/en-intl/contact.html"
              target="_blank"
              data-content-page-id="contact"
            >
              Contact Us
            </a>
            ” page or at:
          </p>
          <p>Le petit-fils de L.U. Chopard &amp; Cie SA</p>
          <p>Attn: Digital Department</p>
          <p>Rte de Veyrot 8</p>
          <p>C.P. 85</p>
          <p>1217 Meyrin 1</p>
          <p>Switzerland</p>
          <p>Email: info@chopard.ch</p>
          <p>Tel: +41 (0)22 719 31 31</p>
          <p>Effective date: 03.10.2019</p>
          <p>
            <br />
          </p>
          <p>
            <strong>Le Petit Fils de L.-U. Chopard et Cie S.A., Meyrin</strong>
          </p>
          <p>
            <br />
          </p>
        </div>
      </div>
    </div>
  );

  const krTerm = (
    <div>
      <h1>이용 약관</h1>
      <h2 className="chopard-serif">
        Please read the following information carefully before using the site
      </h2>
      <div>
        <div className="chopard-content">
          <h4>1. 법적 정보 및 약관 동의</h4>
          <p>
            1.1 웹사이트 www.chopard.com (이하 " ‘웹사이트’ 또는 ‘사이트’라
            칭함)는 스위스 회사 Le petit-fils de L.U. Chopard &amp; Cie SA(이하
            “쇼파드”, “당사”, “자사”, “저희” 등으로 칭함)가 운영하는 것으로
            그것의 사용에는 다음 이용 약관(이하 “이용 약관” 또는 “약관”이라
            칭함)이 적용됩니다. 당사가 이 약관을 작성한 것은 저희 웹사이트를
            비롯해 당사가 제공하는 제품과 기타 서비스(이하 “서비스”라 통칭함)를
            어떻게 사용할 수 있는지 알려드리기 위함입니다.
          </p>
          <p>
            1.2 저희 웹사이트에 접속하거나 여타의 서비스를 이용하기 전에 이 이용
            약관을 천천히 주의 깊게 읽어보시기 바랍니다. 이 이용 약관의 내용 중
            동의하지 않는 조항이 있으면 즉시 웹사이트에서 벗어나 저희가 제공하는
            다른 서비스를 이용하지 마십시오.
          </p>
          <p>
            1.3 쇼파드는 일방적으로 본 이용약관의 일부를 수정, 변경 및/또는
            삭제할 수 있습니다. 새롭게 업데이트된 이용약관 버전은 온라인에
            게재되는 즉시 효력을 발휘합니다.
          </p>
          <p>
            1.4 이 약관은
            <a
              href="https://www.chopard.com/ko-kr/legal-privacy-policy.html"
              target="blank"
              data-content-page-id="legal-privacy-policy"
            >
              개인정보보호정책
            </a>
            과 함께 이 웹사이트에 관련된 쇼파드와 사용자의 관계에 적용됩니다.
          </p>
          <h4>2. 서비스 설명</h4>
          <p>
            2.1 쇼파드는 고객에게 고품질의 지속 가능한 명품을 원활하게 제공하고
            웹사이트 사용자와 기타 여러 사람에게 쇼파드 가문이 경영하는
            워치메이킹 및 주얼리 메종과 그 일원, 새로운 소식, 이벤트, 서비스에
            관한 정보 및 기타 기업 정보를 공유하기 위해 웹사이트와 서비스를
            제공하고 있습니다.
          </p>
          <p>
            2.2 저희 사이트나 기타 서비스의 모든 기능, 콘텐츠, 사양 및 여기에
            기술 또는 설명되어 있는 제품이나 가격은 사전 고지 없이 변경될 수
            있음을 미리 알려드립니다.&nbsp;또한 어떤 제품이나 서비스가 여기에
            포함되어 있다고 해서 그것이 언제나 판매 중이라는 뜻이거나 보장은
            아닙니다.
          </p>
          <p>
            2.3 당사는 저희 서비스와 기타 모든 정보를 완전하고 정확한 최신의
            상태로 유지하기 위해 노력하지만 제공되는 정보 중 일부는 부정확하거나
            불완전하거나 유효 기간이 지난 것일 수 있습니다. 당사는 모든 서비스
            또는 사이트나 서비스를 통해 전달되는 기타 정보, 콘텐츠, 조언, 권장
            사항의 완전성이나 정확성에 관해 어떠한 진술도 하지 않습니다. 또
            제공되거나 이용할 수 있는 당사의 제품이나 서비스 또는 타사의
            제품이나 서비스의 품질이나 안전에 관해 당사는 어떠한 진술이나 보증도
            하지 않습니다.
          </p>
          <h4>3. 사용자가 저희 서비스를 이용할 경우</h4>
          <p>
            <em>당사가 사용자에게 제공하는 것</em>
          </p>
          <p>
            3.1 쇼파드는 사용자에게 저희 서비스에 접근, 사용할 수 있는
            개인적이고 전 세계적이고 무료이고 양도가 불가능하고 비독점적이고
            취소가 가능하고 제한된 사용권을 부여합니다. 이는 사용자가 이 약관 및
            기타 정책이 허용하는 범위 안에서 웹사이트를 개인적, 비상업적 용도로
            사용하고 혜택을 누리게 한다는 단 하나의 목적 때문입니다. 당사가
            사용자에게 허용하는 웹사이트 사용 방법에 관한 자세한 내용은 아래의
            7절 “웹사이트 사용”을 참조하십시오.
          </p>
          <p>
            <em>사용자가 당사에 제공하는 것</em>
          </p>
          <p>
            3.2 사용자가 저희 서비스를 이용할 때 특정한 콘텐츠나 기타 정보를
            당사에 제공하거나 당사가 사용할 수 있도록 허용할 수 있습니다. 예를
            들면, 쇼파드 시계, 주얼리에 대한 경험이 담긴 사진이나 기타 정보를
            제공하는 것입니다. 그럴 때 사용자는 제공한 콘텐츠나 기타 정보를
            수용, 저장, 사용, 화면 표시, 복제, 변경, 각색, 편집, 게시, 배포할 수
            있는 무제한적이고 취소 불가능하고 전 세계적이고 무료이고 영구적이고
            재사용 허가 가능하고 양도 가능한 사용권을 쇼파드에 부여합니다.
            당사는
            <a
              href="https://staging-eu01-chopard.demandware.net/on/demandware.store/Sites-Site/default/ViewLdsBusinessManagerScreen-PageDesigner/https://www.chopard.com/on/demandware.store/Sites-chopard-Site/ko_KR/Page-Show?%2520%27cid%27=%2520%27legal-privacy-policy%27"
              target="blank"
              data-content-page-id="legal-privacy-policy"
            >
              개인정보보호정책
            </a>
            에&nbsp;따라 사용자가 제공한 콘텐츠나 기타 정보를 고객 경험 개선의
            목적을 비롯해 그에 국한되지 않고 서비스 운영, 개발, 제공, 홍보, 개선
            및 새로운 쇼파드 서비스를 연구개발하고 창작하는 데 다양하게 사용할
            수 있습니다.
          </p>
          <p>
            3.3 사용자는 쇼파드에 제공한 모든 질문, 의견, 제안, 아이디어, 후기,
            공개적 지지, 그림, 컨셉트, 사진, 피드백(이하 "제출물"이라 통칭함)이
            기밀로 취급되지 않고 당사의 독점적 재산이 되는 것에 합의합니다.
            사용자는 비즈니스 아이디어, 독창적인 작품을 비롯해 그에 국한되지
            않고 피드백이나 제안, 기타 여러 제출물을 제공할 때 그 제출물을 사용,
            배포, 복제, 변경, 각색, 화면 표시하고 그것으로 창작을 하고 기타
            방식으로 활용할 수 있는 전 세계적이고 무료이고 영구적이고 완벽하게
            재사용 허가 가능하고 취소 불가능한 권리를 쇼파드에 부여하는
            것입니다. 이는 당사가 사용자에게 보상을 제공하거나 사용자를 언급하지
            않고 사용자가 제공한 아이디어를 사용할 수 있다는 뜻입니다.
          </p>
          <p>
            3.4 당사는 의무 사항은 아니지만 단독의 자유재량으로 웹사이트를
            모니터링하거나 재검토할 수 있으며 콘텐츠가 이 약관에 위배된다고
            생각되는 경우를 비롯해 어떤 이유로든 아무 때라도 콘텐츠에 접근하여
            재검토, 차단, 제거, 삭제할 수 있습니다.
          </p>
          <p>
            <em>사용자의 행동</em>
          </p>
          <p>
            3.5 사용자가 제공한 모든 콘텐츠와 사용자가 한 활동 또는 사용자의
            계정에서 일어난 활동에 대한 최종 책임은 (해당 콘텐츠나 활동이
            사용자의 계정을 통해 사이트 및 기타 서비스에 접근한 타인에 의해
            행해졌어도) 사용자에게 있습니다.
          </p>
          <p>
            3.6 저희 서비스를 이용함으로써 사용자는 다음 사항에 동의하는
            것입니다:
          </p>
          <ul>
            <li>
              사이트나 서비스를 불법적인 목적이나 이 약관에서 금지하는 다른
              목적에 사용해서는 안 됩니다.
            </li>
            <li>
              로봇, 스파이더와 같이 자동화된 수단을 써서 서비스에 접근하거나
              다른 사용자 정보를 추출해서는 안 됩니다.
            </li>
            <li>
              저희 사이트나 서비스의 일부를 복사, 수정, 배포, 판매, 임대해서는
              안 됩니다.
            </li>
            <li>
              소프트웨어를 리버스 엔지니어링하거나 소스 코드 추출을 시도해서는
              안 됩니다.
            </li>
            <li>
              당사의 서면 동의를 획득하지 않는 한, 사용자는 타사의 응용
              프로그램을 이용해 사이트나 서비스나 다른 사용자들의 콘텐츠 또는
              정보와 상호 작용해서는 안 됩니다.
            </li>
            <li>
              서비스를 사용 중인 다른 쇼파드 사용자나 저희 서비스 유지보수
              능력을 간섭하거나 방해하거나 부정적인 영향을 미치거나 제약을 가할
              수 있는 방식으로 사이트나 서비스를 사용해서는 안 됩니다.
            </li>
            <li>
              서비스에 손해를 끼치거나 과부하를 일으키거나 기능을 떨어뜨릴 수
              있는 방식으로 사이트나 서비스를 사용해서는 안 됩니다.
            </li>
            <li>
              또 다른 쇼파드 사용자의 계정을 허가 없이 사용하거나 사용하려고
              시도해서는 안 됩니다.
            </li>
            <li>다른 사용자들의 로그인 인증 정보를 요구해서는 안 됩니다.</li>
            <li>
              신분을 사칭할 의도로 타인의 이름을 사용하거나 불쾌감을 주는 이름,
              저속하거나 음란한 이름을 사용해 계정을 만들어서는 안 됩니다.
            </li>
            <li>
              불쾌감을 주거나 음란물, 폭력 장면, 위협, 편파적 연설이 포함되어
              있거나 폭력 또는 불법 행위를 조장하는 콘텐츠를 제공해서는 안
              됩니다.
            </li>
            <li>
              쇼파드의 권리나 제삼자의 권리를 침해하는 방식으로 서비스를
              사용해서는 안 됩니다.
            </li>
            <li>
              서비스를 사용해 다른 사용자를 비롯해 타인을 괴롭히거나 학대하거나
              중상하거나 스토킹하거나 위협해서는 안 됩니다.
            </li>
            <li>
              서비스의 보안을 위협하는 일을 해서는 안 됩니다. 여기에는
              바이러스나 기타 악성 코드를 업로드하는 일이 포함되며 그에 국한되지
              않습니다.
            </li>
            <li>
              쇼파드가 사용하는 콘텐츠 필터나 콘텐츠 필터링 기술을 우회하려고
              해서는 안 됩니다.
            </li>
            <li>
              당사가 접근 권한을 부여한 서비스 영역이나 기능에만 접근해야 하며
              접근 권한이 없는 영역이나 기능에 접근하려고 시도해서는 안 됩니다.
            </li>
            <li>
              쇼파드 시스템이나 네트워크를 비롯해 저희 서비스의 보안이나
              취약성을 탐사, 스캔, 테스트해서는 안 됩니다.
            </li>
            <li>
              다른 사용자들이나 쇼파드에 스팸이나 불필요한 이메일을 보내서는 안
              됩니다.
            </li>
            <li>
              이 약관에 위배되는 활동에 가담하거나 이 약관에 위배되는 행위를
              조장해서는 안 됩니다.
            </li>
          </ul>
          <p>
            3.7 쇼파드는 당사의 제품에 대한 사용자의 경험을 보여주는 사진을
            비롯해 사용자에 관한 정보를 쇼파드와 공유할 수 있도록 허용합니다.
            또한 당사와의 의사소통도 허용합니다. 예를 들면 질문을 하고 피드백과
            제안과 좋아요(like)를 제공하는 것입니다. 사용자가 인터넷을 통해
            당사에 보내온 모든 커뮤니케이션 및 기타 자료는 기밀이 아닌 것으로
            간주됩니다. 제공된 위와 같은 대해 쇼파드는 아무런 의무가 없습니다.
            결과적으로 (당사의&nbsp;
            <a
              href="https://www.chopard.com/ko-kr/legal-privacy-policy.html"
              target="blank"
              data-content-page-id="legal-privacy-policy"
            >
              개인정보보호정책에
            </a>
            &nbsp;정의된) 모든 개인정보나 사용자가 당사와 공유하기로 한 기타
            민감한 정보는 타인이 볼 수 있습니다. 사용자는 자신이 공유한 모든
            정보를 타인이 볼 수 있다는 데 동의합니다. 예를 들어 (
            <a
              href="https://www.chopard.com/ko-kr/legal-privacy-policy.html"
              target="blank"
              data-content-page-id="legal-privacy-policy"
            >
              개인정보보호정책에
            </a>
            &nbsp;정의된) 개인정보와 같이 타인이 볼 수 있거나 남들에게
            공개되기를 원치 않는 정보는 당사에 공유하지 마십시오.
          </p>
          <p>
            3.8 당사는 특정한 서비스를 원활하게 하기 위해 사용자의 개인정보를
            요구할 수 있습니다. 예를 들어, 사용자가 당사에 피드백이나 질문을
            전달하면 당사는 필요할 때 추가 연락을 할 수 있도록 사용자의 연락처
            정보를 요구할 수 있습니다. 당사와 소통할 때 특별한 요청이 없는 한,
            쇼파드에 개인정보를 공유하지 마십시오.
          </p>
          <p>
            3.9 당사는 처리하는 모든 개인정보를 손실, 오용, 무단 접근, 정보
            노출, 변경, 파괴로부터 보호하기 위해 적절하고 합리적인 기술적,
            조직적, 행정적 보안 조처를 하고 있습니다. 당사가 사용자의 개인정보를
            보호하기 위해 노력하지만, 전송 시스템이나 데이터 네트워크가 100%
            안전하다고 보장할 수는 없습니다. 따라서 사용자가 웹사이트로 송신하는
            정보가 비보안 네트워크에서 인터넷을 통해 전송될 경우, 당사는 정보의
            안전을 보장할 수 없습니다. 그러므로 사용자는 인터넷 고유의 데이터
            보안 및 개인정보보호상 한계가 존재한다는 것, 사용자와 쇼파드,
            사용자와 다른 쇼파드 사용자 사이에서 주고받은 모든 정보의 보안과
            무결성과 프라이버시가 보장될 수 없다는 것을 인정하며 당사는 그에
            대해 법적 책임을 지지 않습니다. 당사가 사용자의 개인정보를 어떻게
            사용하고 보호하는지 자세한 내용을 보려면&nbsp;
            <a
              href="https://www.chopard.com/ko-kr/legal-privacy-policy.html"
              target="blank"
              data-content-page-id="legal-privacy-policy"
            >
              개인정보보호정책을
            </a>
            &nbsp;참조하십시오.
          </p>
          <h4>4. 개인정보보호정책</h4>
          <p>
            4.1 사용자의 개인정보는 사용자와 당사 모두에게 매우 중요합니다. 저희
            서비스에 회원 가입을 하고 이용할 경우, 사용자의 정보가 어떻게
            처리되는지 알고 싶으시면 당사의&nbsp;
            <a
              href="https://www.chopard.com/ko-kr/legal-privacy-policy.html"
              target="blank"
              data-content-page-id="legal-privacy-policy"
            >
              개인정보보호정책을
            </a>
            &nbsp;참조하십시오.
          </p>
          <h4>5. 저작권 및 지적재산권</h4>
          <p>
            5.1 이 웹사이트에 표시된 쇼파드 이름과 마크, 쇼파드 로고, 기타
            쇼파드 관련 마크는 등록 여부에 상관없이 모두 쇼파드와 그 자회사 및
            계열사의 상표 또는 서비스 마크입니다. 이 약관이나 이 웹사이트에
            포함된 것은 모두 묵시적, 금반언적 또는 기타 어떤 방식으로든 쇼파드나
            그 자회사나 계열사의 특허나 저작권, 상표, 기타 지적재산권에 따른
            사용권이나 권리를 부여하는 것으로 해석될 수 없습니다.
          </p>
          <p>
            5.2 이 웹사이트에서 제공되거나 사용 가능한 정보, 사진, 텍스트,
            그래픽, 비디오, 오디오, 도구, 이미지와 콘텐츠 및 자료(이하
            "콘텐츠"라 칭함)와 웹사이트 자체는 저작권, 상표 및/또는 기타
            지적재산권의 보호를 받습니다. 이 콘텐츠는 쇼파드나 그 자회사 또는
            계열사가 소유하거나 사용권을 허가받은 것이거나 그 소유자들의
            허락하에 사용됩니다. 이 콘텐츠는 사용자에게 개인적, 비상업적, 정보
            제공 목적으로만 제공됩니다. 이 콘텐츠에는 서비스에 들어있는 텍스트,
            그래픽, 사진, 인터랙티브 기능, 상표, 서비스 마크, 상품 외장,
            로고("마크")도 포함되며 그에 국한되지 않습니다. 사용자는 이 약관에
            의해 허용되지 않은 그 어떤 목적으로도 당사의 콘텐츠와 마크를 사용,
            조작, 복사, 복제, 전송, 배포, 방송, 화면 표시, 판매하거나 기타
            방식으로 사용할 수 없습니다. 전 세계 무단전재-재배포 금지.
          </p>
          <p>
            5.3 당사는 저작권을 비롯해 타인의 지적재산권을 존중하며 사용자들이
            이 권리를 침해하는 방식으로 저희 서비스를 사용하는 것을 금하고
            있습니다. 저희 서비스에서 이용 가능한 콘텐츠 중 일부는 다른 사용자와
            제삼자로부터 제공받은 것입니다. 당사 웹사이트에 나오는 다른 모든
            콘텐츠, 상표, 저작권은 각각 해당 소유자의 자산입니다. 사용자는
            타인의 홍보권, 개인정보보호권, 저작권, 상표권 또는 기타 지적재산권을
            위반하거나 침해하는 방식으로 서비스를 사용하지 않겠다고 동의합니다.
          </p>
          <p>
            5.4 사용자는 콘텐츠나 마크, 서비스 또는 타인의 콘텐츠를 이 약관에
            의해 허락되지 않은 방식으로 사용할 수 없습니다. 저희 사이트나
            서비스를 이용할 때 사용자가 타인의 지적재산권을 위반한다고 생각되면
            당사는 그 사용자의 이용을 해지하거나 서비스 이용을 금지할 수
            있습니다.
          </p>
          <p>
            5.5또한 어떤 제삼자가 사용자에 의해 사이트에 공유 또는 업로드된
            콘텐츠가 지적재산권을 위반하거나 개인정보보호권을 침해한다고 주장할
            경우, 당사는 그 제삼자에게 사용자의 신원을 공개할 권리도 갖고
            있습니다. 저희 사이트를 이용하는 사용자가 게시한 콘텐츠의 내용이나
            정확성에 대해 당사는 제삼자에게 책임을 지거나 변상하지 않습니다.
            당사는 사용자가 저희 사이트에 게시한 모든 게시물을 단독의
            자유재량으로 제거할 권리가 있습니다. 다른 사용자가 저희 사이트에서
            표한 견해는 당사의 견해나 가치관을 대표하지 않습니다.
          </p>
          <h4>6. 웹사이트 이용</h4>
          <p>
            6.1 웹사이트에 포함된 자료, 정보, 콘텐츠는 오로지 정보 제공과 홍보
            목적을 위한 것입니다.
          </p>
          <p>
            6.2 제공된 자료, 정보, 콘텐츠는 개인용으로만 사용할 수 있습니다.
            사용자는 웹사이트에 나오는 자료, 정보, 콘텐츠를 개인용 및 비상업적
            목적으로만 다운로드, 화면 표시, 인쇄할 수 있습니다. 단, 저작권
            공고가 각각의 사본에 표시되어야 하고 사용자가 상표나 기타 소유권
            알림을 변경하지 말아야 합니다. 어떤 문서나 소프트웨어를
            다운로드하거나 복제했다고 해서 해당 문서나 소프트웨어에 관한 권리나
            명의나 이권이 사용자에게 제공되는 것이 아닙니다.
          </p>
          <p>
            6.3 개인적이며 비상업적 사용 외 다른 목적의 복제, 수정, 배포, 게재,
            전달, 이차적 저작물 생성 또는 전부 또는 부분적인 웹사이트의 자료,
            정보, 콘텐츠 등은 쇼파드의 사전 서면 동의가 있는 경우를 제외하고는
            엄격하게 금지됩니다.
          </p>
          <p>
            6.4 쇼파드는 웹사이트에 게재된 자료, 정보, 콘텐츠의 사용이 제삼자
            권한을 위반하지 않는다는 점을 보장하지 않습니다.
          </p>
          <h4>7. 기밀이 아니라고 간주되는 정보 및 제출물</h4>
          <p>
            7.1 인터넷을 통해 웹사이트에 전송되는 개인 식별 또는 정보는
            쇼파드의&nbsp;
            <a
              href="https://www.chopard.com/ko-kr/legal-privacy-policy.html"
              target="blank"
              data-content-page-id="legal-privacy-policy"
            >
              개인정보 보호정책에
            </a>
            &nbsp;따라 보호되고 처리됩니다. 쇼파드는 그러한 개인 식별 또는
            정보를 전송하기 전&nbsp;
            <a
              href="https://www.chopard.com/ko-kr/legal-privacy-policy.html"
              target="blank"
              data-content-page-id="legal-privacy-policy"
            >
              개인정보 보호정책을
            </a>
            &nbsp;주의 깊게 읽을 것을 권장합니다.
          </p>
          <p>
            7.2 이로써 사용자는 웹사이트와 관련해 웹사이트를 통하거나 다른
            방법을 통해 쇼파드에 전달되거나 위임된 모든 제출물 및 기타 문서들이
            모두 기밀이 아니고 비독점적이며 사용자나 다른 사람들에게 사적인
            성격이 아닌 것으로 간주하는 데 대해 동의합니다.
          </p>
          <p>
            7.3 상기 4절에 명시된 바와 같이 사용자가 제출한 모든 제출물은 당사의
            독점적 재산이 됩니다. 그러나 저작권이나 등록 상표, 특허, 특허 기밀,
            사생활, 또는 다른 인적 권리 또는 재산권과 관련하여 제삼자의 권리를
            침해하는 제출물을 올린다면 그에 대한 책임은 모두 사용자에게
            있습니다.
          </p>
          <p>
            7.4 쇼파드는 고급 시계, 주얼리, 기타 럭셔리 제품의 디자인과 제조
            부문에서 세계적인 명성을 누리고 있습니다. 이를 위해 쇼파드는 쇼파드
            제품을 기획하고 완성하는 고도로 숙련된 디자이너 팀을 비롯해 쇼파드에
            소속된 창작 자원을 보유하고 있습니다. 그 결과, 쇼파드는 원치 않는
            제안서를 받는 것에 동의하지도 이를 수락하지도 않으며, 쇼파드는 이미
            비슷한 아이디어로 작업 중일 가능성도 있습니다. 그러므로 쇼파드는
            아이디어나 제안서를 받을 의향이 없습니다.
          </p>
          <h4>8. 보증 책임 부인</h4>
          <p>
            8.1 웹사이트에 있는 자료, 정보, 콘텐츠는 상품성이나 특정한 목적에
            대한 적합성이나 상품성 여부에 대한 보증을 포함한 어떠한 형태의
            보증도 명시적이나 묵시적으로 설정되지 않은 채 “있는 그대로의” 상태로
            가용성에 따라 제공됩니다.
          </p>
          <p>
            8.2 쇼파드는 웹사이트 콘텐츠의 정확성, 완전성, 최신성, 웹사이트의
            무결성과 이미 존재하는 결함의 수정에 대해 어떤 책임도 없고 보증도
            하지 않습니다. 사용자는 이 웹사이트를 사용할 때 스스로 전적인 책임을
            진다는 데 동의하며 사이트 사용 시 발생하는 모든 문제와 사용자가
            웹사이트에 접속하는 데 사용한 하드웨어 및/또는 소프트웨어의 지원
            또는 수리와 관련해 발생하는 데이터 손실과 비용은 사용자가
            부담합니다. 또한 웹사이트 사용으로 인해 발생하거나 거기서 기인하는
            손해에 대해 쇼파드는 아무 책임도 지지 않는다는 것에 동의합니다.
          </p>
          <p>
            8.3 쇼파드는 위임받은 정보를 보호하기 위해 적절하고 합리적인 범위
            안에서 기술적, 조직적, 행정적 보안 조처를 하고 있지만, 당사와
            이메일로 소통할 경우 인터넷 이메일의 보안이 확실하지 않다는 데
            유의하시기 바랍니다. 민감하거나 기밀 내용이 담긴 이메일 메시지를
            보낸다면, 사용자는 그러한 불확실성과 인터넷상에서 비밀 보장이 되지
            않을 수 있다는 점에 동의하는 것입니다.
          </p>
          <h4>9. 책임 제한</h4>
          <p>
            9.1 사용자는 본인의 책임 하에서 웹사이트에 접속하며, 이를 검색하고
            탐색합니다.
          </p>
          <p>
            9.2 쇼파드가 계약상 행동이나 불법행위 또는 기타 법이론 중에 정보나
            제품이나 문서나 기타 이 웹사이트의 다른 콘텐츠나 서비스의 사용 또는
            작동 불능으로 인해 손해가 발생하거나 그와 관련해 손해가 발생할 수
            있다는 것을 사전에 알고 있었더라도, 쇼파드와 그 계열사들은 어떤
            상황에서든지 관련 법률이 허용하는 한도 내에서 실제적, 직접적,
            간접적, 임시적, 징벌적, 부수적, 모범적, 결과적 손해 또는 모든 종류의
            어떤 손해에 대해서도 책임지지 않으며 사용자는 이를 인정합니다.
          </p>
          <p>
            9.3 웹사이트를 사용하는 과정에서 다른 어떤 방법으로든 다운로드하거나
            취득한 모든 자료, 정보, 콘텐츠는 사용자에게 전적인 책임이 있습니다.
            웹사이트의 자료, 정보, 콘텐츠에 접근하거나 그것을 사용 또는
            다운로드해서 발생하는 손해나 사용자의 컴퓨터 장비나 기타 자산에
            영향을 미치는 바이러스 또는 IT 시스템에 대한 불법 침입이나 개입에
            대해 쇼파드는 일체 책임 지지 않습니다.
          </p>
          <p>
            9.4 쇼파드는 웹사이트 기능의 일부 또는 전부를 중단할 권리를
            보유합니다. 쇼파드는 쇼파드나 제삼자의 행동 또는 부작위로 인해
            웹사이트 기능의 일부 또는 전부가 중단 또는 일시 중지되는 데 대해 그
            어떤 책임이나 법적 책임도 지지 않습니다.
          </p>
          <h4>10. 정보의 변경</h4>
          <p>
            10.1 웹사이트 콘텐츠는 오류나 오타를 포함할 수 있습니다. 쇼파드는
            사전 고지 없이 언제든지 그러한 콘텐츠를 변경, 수정 및/또는
            업데이트할 권리를 보유하지만, 그렇게 해야 할 책임은 없습니다.
          </p>
          <p>
            10.2 웹사이트에 포함된 쇼파드 제품 스타일, 도안 및 컬러는 사전 공지
            없이 수정될 수 있습니다.
          </p>
          <h4>11. 제품 및 서비스 이용 가능성</h4>
          <p>
            11.1 웹사이트에 포함된 제품은 쇼파드 컬렉션을 대표합니다. 그러나
            모든 쇼파드의 제품이 웹사이트에 포함되는 것은 아닙니다.
          </p>
          <p>
            11.2 웹사이트는 쇼파드의 제품과 서비스에 관한 정보를 포함하지만, 전
            지역에서 이용할 수 있는 것은 아닙니다. 웹사이트에 제품이나 서비스가
            언급되어 있다고 해서 해당 제품이나 서비스가 판매 중이거나 판매
            예정이라는 뜻은 아닙니다.
          </p>
          <p>
            11.3 이 웹사이트에 기술되어 있는 제품과 서비스는 재고 공급이
            이루어지는 동안 판매됩니다. 이 웹사이트에 나오는 제품의 이미지는
            제품을 대표하는 것일 뿐, 반드시 사용자가 받아볼 실제 제품의
            사진이라고 할 수 없습니다. 쇼파드는 모든 제품에 대해 생산을 중단할
            권리와 언제든지 통지 없이 모든 제품이나 서비스의 제공이나 판매를
            중단할 권리를 보유합니다. 특정 매장에서 이 웹사이트에 디스플레이된
            특정 제품이나 서비스를 판매한다고 표시되어 있어도 쇼파드는 해당
            제품이나 서비스를 해당 매장에서 구매할 수 있다고 보장하지 않습니다..
          </p>
          <p>
            11.4 이 웹사이트에 나오는 제품의 색, 디자인, 스타일, 참조번호, 설명,
            크기, 가격(해당할 경우)이 원래의 제품을 최대한 반영할 수 있도록
            최선을 다하지만 차이가 생길 수 있습니다. 이처럼 이 웹사이트에 있는
            제품과 서비스 설명은 때에 따라 다를 수 있고 인쇄상 오류가 발생할 수
            있으며 제품과 서비스 설명이 완전하고 정확하고 최신 상태가 아닐 수
            있습니다. 여기에는 가격(해당할 경우)도 포함되며 그에 국한되지
            않습니다. 쇼파드는 웹사이트에 표시된 설명, 참조번호, 가격(나와 있을
            때)과 제품의 사진 또는 시각적 표현상의 오류나 부정확성에 대해 책임이
            없으며 그와 똑같은 제품을 배송할 의무 및/또는 그 어떤 손해 보상금도
            지불할 의무가 없습니다. 제품에 대해 궁금한 점이 있으면 온라인상의 “
            <a
              href="https://www.chopard.com/ko-kr/contact.html"
              target="blank"
              data-content-page-id="contact"
            >
              문의하기(Contact Us)
            </a>
            ” 페이지를 통해 쇼파드 고객 서비스 담당 부서로 직접 문의할 수
            있습니다..
          </p>
          <h4>12. 쇼파드 소매 가격(해당할 경우)</h4>
          <p>
            12.1 쇼파드는 이 웹사이트에 포함된 제품과 서비스의 가격을 언제든지
            통지 없이 변경할 권리를 보유합니다.
          </p>
          <h4>13.&nbsp;링크</h4>
          <p>
            13.1 웹사이트는 쇼파드가 운영하거나 관리하지 않는 제삼자의 인터넷
            웹사이트 링크를 포함할 수 있습니다. 그러한 웹사이트 주소나 링크는
            사용자가 관심을 가질 만한 다른 인터넷 웹사이트를 찾는 데 도움을 주기
            위해서만 제공됩니다. 쇼파드가 링크를 제공하는 것은 편의상의 목적이며
            쇼파드와 관련이 있거나 쇼파드의 통제를 받거나 공동 지배되는 회사의
            웹사이트 링크를 비롯해 링크된 모든 웹사이트의 소유주나 콘텐츠를
            보증하거나 홍보하는 것이 아닙니다.
          </p>
          <p>
            13.2 쇼파드는 웹사이트에 연결된 다른 웹사이트를 검토하지 않습니다.
            쇼파드는 그러한 웹사이트의 이용 가능성이나 콘텐츠, 그러한 웹사이트에
            포함되거나 이를 통해 얻은 기사에 대한 책임을 지지 않습니다. 제삼자
            웹사이트의 링크를 따라가는 것은 사용자의 책임이라는 점에
            유의하십시오.
          </p>
          <h4>14. 관할권 및 준거법</h4>
          <p>
            14.1 현행 이용약관은 웹사이트 접속 및 사용, 웹사이트 콘텐츠에 대한
            쇼파드와 사용자 간 완전하게 합의된 내용을 포함하고 있습니다.
          </p>
          <p>
            14.2 이 이용 약관과 사용자의 웹사이트 사용, 사용자와 당사 간의
            합의에는 스위스 법이 적용되고 그에 따라 해석되며 그것이 법 원칙의
            상충에 영향을 미치지 않습니다. 스위스 연방 법원에 항소하는 경우를
            제외하고는 이 약관이나 이 약관과 관련해 발생하는 모든 논쟁이나 분쟁,
            청구의 전속 관할 법원은 스위스 제네바주의 일반 법원이며 당사자들은
            법원의 속인적, 전속 관할권과 재판 장소에 동의하고 재판을 받습니다.
          </p>
          <h4>15. 분리가능성</h4>
          <p>
            15.1 이 약관의 일부가 시행 불가능하다고 판명될 경우, 해당 조항은 이
            약관에서 분리되어 나머지 약관의 유효성과 시행 가능성에 영향을 미치지
            않을 것입니다.
          </p>
          <h4>16. 완전한 약관</h4>
          <p>
            16.1 이 약관은 참조로 통합된 다른 모든 문서들과 함께 사용자와 쇼파드
            사이의 완전한 합의를 구성한다는 점에 유의하십시오. 당사는 사용자에게
            명확하게 부여되지 않은 모든 권리를 보유합니다. 사용자는 이 약관에
            따라 부여받은 권리나 의무를 당사의 명시적 동의 없이 양도할 수
            없습니다. 당사는 이 약관에 포함된 조항 중 시행되지 않는 것이 있어도
            유보하지 않습니다. 이 약관은 당사가 수정 또는 종료할 때까지 계속
            유효합니다.
          </p>
          <h4>17. 문의하기</h4>
          <p>
            이 약관이나 당사의 업무에 관해 궁금한 점이 있으면 당사의 온라인 "
            <a
              href="https://www.chopard.com/ko-kr/contact.html"
              target="blank"
              data-content-page-id="contact"
            >
              문의하기(Contact Us)
            </a>
            " 페이지를 통해 연락하시거나 다음 주소로 문의해주십시오.
          </p>
          <p>Le petit-fils de L.U. Chopard &amp; Cie SA</p>
          <p>Attn: Digital Department</p>
          <p>Rte de Veyrot 8</p>
          <p>C.P. 85</p>
          <p>1217 Meyrin 1</p>
          <p>Switzerland</p>
          <p>이메일: info@chopard.ch</p>
          <p>Tel: +41 (0)22 719 31 31</p>
          <p>유효 날짜: 30.10.2019</p>
          <p>
            <br />
          </p>
          <p>
            <strong>Le Petit Fils de L.-U. Chopard et Cie S.A., Meyrin</strong>
          </p>
          <h4>
            <br />
          </h4>
          <p>
            <br />
          </p>
        </div>
      </div>
    </div>
  );

  const enPrivacy = (
    <div className="chopard-content">
      <h1>Privacy Policy</h1>
      <h2 className="chopard-serif">
        Please read the following information carefully before using the site
      </h2>

      <div>
        <div>
          <h2>1. Introduction</h2>
        </div>
      </div>
      <div>
        <div>
          <div>
            <p>
              <span>
                We, the Chopard Group, whose undertakings include Chopard’s
                parent company “Le petit-fils de L.U. Chopard &amp; Cie SA”
                located in Switzerland and its affiliated companies,
                (hereinafter “
              </span>
              <strong>Chopard</strong>
              <span>”, “</span>
              <strong>we</strong>
              <span>”, “</span>
              <strong>us</strong>
              <span>
                ”) are committed to protecting your privacy while continuously
                improving the services we offer you.
              </span>
            </p>
            <p>
              <span>The Chopard Privacy Policy (hereinafter the “</span>
              <strong>Privacy Policy</strong>
              <span>”) intends&nbsp;</span>
              <strong>
                to give you all the information regarding the processing
              </strong>
              <span>
                &nbsp;(e.g. collection, use, recording, storage or
                transfer)&nbsp;
              </span>
              <strong>of any personal data that we may collect</strong>
              <span>
                &nbsp;through our online platforms (website, applications,
                social networks) (hereinafter the “
              </span>
              <strong>Chopard Platforms</strong>
              <span>
                ”) or when you fill out the Chopard Client Form (hereinafter the
                “
              </span>
              <strong>Client Form</strong>
              <span>
                ”) in our boutiques or customer service centers, when we
                organise an event, or through any other form of interaction.
              </span>
            </p>
            <p>
              <span>
                The Privacy Policy does not apply to the personal data collected
                by other third party websites or applications that may provide
                links to or be accessible from any of the Chopard Platforms. We
                are not responsible or liable for the privacy policies and
                practices of third-party websites and applications.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div>
        <div>
          <h2>2. The Collection Of Your Personal Data By Chopard</h2>
        </div>
      </div>
      <div>
        <div>
          <ul>
            <li>
              <button>
                2.1 What is Personal Data and how do we collect it?
              </button>
              <div id="collapse-0">
                <div>
                  <div>
                    {' '}
                    <p>
                      <span>
                        We may collect personal information about you (as an
                        identified natural person) or which allows us to
                        identify you (hereinafter your “
                      </span>
                      <strong>Personal Data</strong>
                      <span>”) in various ways and circumstances:</span>
                    </p>
                    <p>
                      &nbsp;-when you visit any of our Chopard Platforms,
                      particularly when you register on our websites or fill out
                      our Contact Us Form ;
                    </p>
                    <p>
                      -when you visit our own boutiques, points of sale or
                      offices ;
                    </p>
                    <p>
                      -when you purchase a Chopard product or request customer
                      service ;
                    </p>
                    <p>-when you interact with us via email or telephone ;</p>
                    <p>
                      -when you fill out any forms in our boutiques, customer
                      service centers or during Chopard events, including the
                      Client Form (printed or electronic) ;
                    </p>
                    <p>
                      -through some of our commercial and advertising partners
                      (always with your explicit consent).
                    </p>
                    <p>
                      <br />
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul>
            <li>
              <button>2.2 What kind of Personal Data do we collect?</button>
              <div id="collapse-1">
                <div>
                  <div>
                    {' '}
                    <p>
                      <span>
                        Depending on the way you’ve chosen to interact with us,
                        we may collect information (that may constitute Personal
                        Data) which{' '}
                      </span>
                      <strong>you actively give us</strong>
                      <span>, such as:</span>
                    </p>
                    <p>
                      &nbsp;-information about your identity (first name,
                      surname, gender, date of birth, nationality)
                    </p>
                    <p>
                      -contact information (mailing address, email address and
                      telephone number);
                    </p>
                    <p>-information about your civil status (title);</p>
                    <p>
                      -information about purchases and payments (credit card
                      information and information required by
                      anti-money-laundering laws);
                    </p>
                    <p>
                      -other personal information, including information
                      regarding your preferences and personal and/or
                      professional interests, demographic category, your
                      experiences with our products and contact preferences,
                      your professional situation (position, company/employer,
                      professional contact details), your interests (sports,
                      leisure) and your lifestyle;
                    </p>
                    <p>
                      -Customer service information (such as customer service
                      requests, comments, feedback, complaints and repair
                      history);
                    </p>
                    <p>
                      -Information concerning your application (education,
                      training and professional experience).
                    </p>
                    <p>
                      &nbsp;
                      <span>
                        We may also collect other types of information (that may
                        constitute Personal Data) by automated means, or
                        indirectly from you or from third parties (websites or
                        social networks) such as:
                      </span>
                    </p>
                    <p>
                      <span>&nbsp;</span>-information relating to the use of
                      Chopard Platforms, including your IP address and other
                      browsing data (including the browser and type of device
                      used) through the use of cookies or similar technology
                      installed on your device. Certain cookies are needed to
                      ensure that Chopard Platforms function properly, and
                      others are used for analytic purposes, which help us to
                      provide you with more customised services and a better
                      digital experience. For more information about cookies and
                      how to change your preferences, please consult our cookie
                      usage policy ;
                    </p>
                    <p>
                      -images taken through our video surveillance system in
                      Chopard boutiques, points of sale or offices;
                    </p>
                    <p>
                      -information that you share publicly on social networks as
                      part of your interaction or not with Chopard.
                    </p>
                    <p>
                      <br />
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul>
            <li>
              <button>
                2.3 On which grounds do we collect your Personal Data?
              </button>
              <div id="collapse-2">
                <div>
                  <div>
                    {' '}
                    <p>
                      <span>
                        We guarantee that we process your Personal Data{' '}
                      </span>
                      <u>only if and to the extent</u>
                      <span>
                        {' '}
                        that at least one of the following legal provisions
                        applies:{' '}
                      </span>
                    </p>
                    <p>
                      <span>&nbsp;</span>-You have given us{' '}
                      <strong>explicit consent</strong>{' '}
                      <strong>to process some of your Personal Data</strong> for
                      one or more specific purposes (e.g. so that you can use
                      our Chopard websites, receive newsletters, or be contacted
                      through the “Contact Us” form);
                    </p>
                    <p>
                      -Processing is <strong>necessary for</strong>{' '}
                      <strong>the performance of a contract</strong> (related to
                      your purchase, repair order or other services) or in order
                      to take steps at your request prior to entering into such
                      a contract;
                    </p>
                    <p>
                      -Processing is{' '}
                      <strong>
                        necessary for compliance with a legal obligation
                      </strong>{' '}
                      to which we are subject;
                    </p>
                    <p>
                      <span>-</span>Processing is{' '}
                      <strong>necessary for the purposes of the</strong>{' '}
                      <strong>legitimate interests</strong> that we pursue (e.g.
                      security purposes,{' '}
                      <span>
                        personalized maintenance advices, personalized services
                        and products offers).
                      </span>
                    </p>
                    <p>
                      <br />
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <h2>3. The Use Of Your Personal Data By Chopard</h2>
        </div>
      </div>
      <div>
        <div>
          <ul>
            <li>
              <button>
                3.1 For what purposes do we use your Personal Data?
              </button>
              <div id="collapse-3">
                <div>
                  <ul>
                    {' '}
                    <li>
                      {' '}
                      <button>
                        {' '}
                        a) Marketing and promotional purposes{' '}
                      </button>{' '}
                      <div id="collapse-4">
                        {' '}
                        <div>
                          {' '}
                          <div>
                            {' '}
                            <p>
                              <span>-</span>Sending you personal invitations to
                              Chopard events ;
                            </p>
                            <p>
                              <span>-</span>Sending you company communications
                              including content and news about the Chopard world
                              (e.g. the opening of new boutiques) and marketing
                              communications (advertising offers including
                              custom audiences on social media, gifts and
                              catalogues) adapted to your interests and personal
                              preferences ;
                            </p>
                            <p>
                              <span>-</span>Sending you the Chopard e-newsletter
                              so that you will be the first to know about our
                              creations, events, initiatives and personalised
                              services adapted to your interests and personal
                              preferences.
                            </p>
                            <p>
                              <strong>
                                <u>Targeted advertising</u>
                              </strong>
                              : In order to adapt our communications and
                              advertising offers to your interests and personal
                              preferences and manage the effectiveness of our
                              marketing strategy, we use tools, technologies and
                              services from third parties such as advertising
                              agencies and analytical tool providers.
                            </p>
                            <p>
                              We can then create a summary of your preferences
                              based on information that you have provided us
                              with as well as your purchase history.
                            </p>
                            <p>
                              We can also create custom audiences (comprised of
                              people who filled in the Client Form, visited our
                              website or connected to the Chopard Platforms) on
                              platforms used by third-party applications and/or
                              social networks (Google, Facebook, Instragram)
                              through cookies or by converting your email
                              address (as well as other information communicated
                              in our Client Form) into a unique value
                              (anonymised) that can be matched by third-party
                              applications and/or social networks with a user on
                              their platform. This process is used to post
                              targeted advertising of our products and services,
                              intended for you and depending on your interests
                              and personal preferences, on these platforms.
                            </p>
                            <p>
                              You can choose to not receive these types of
                              advertisements by accessing the cookie control
                              tool and de-selecting the category “cookies for a
                              targeted advertisement”. However, we do not
                              control third-party targeting technologies even if
                              they are associated with the Chopard Platforms or
                              our advertisements. We suggest that you consult
                              the general use terms and conditions, usage
                              policies, privacy policies and other practices of
                              the third-party applications and social networks
                              with regards to the collection, storage and
                              sharing of your Personal Data.
                            </p>{' '}
                          </div>{' '}
                        </div>{' '}
                      </div>{' '}
                    </li>{' '}
                    <li>
                      {' '}
                      <button> b) Business purposes </button>{' '}
                      <div id="collapse-5">
                        {' '}
                        <div>
                          {' '}
                          <div>
                            {' '}
                            <p>
                              -Processing your orders, purchases and deliveries
                              ;
                            </p>
                            <p>-Providing you with customer service.</p>{' '}
                          </div>{' '}
                        </div>{' '}
                      </div>{' '}
                    </li>{' '}
                    <li>
                      {' '}
                      <button> c) Support purposes </button>{' '}
                      <div id="collapse-6">
                        {' '}
                        <div>
                          {' '}
                          <div>
                            {' '}
                            <p>
                              -Providing you with information about our Chopard
                              Platforms, products and services ;
                            </p>
                            <p>-Answering your requests ;</p>
                            <p>
                              -Managing, maintaining and optimizing our Chopard
                              Platforms, providing support and security
                              services.
                            </p>{' '}
                          </div>{' '}
                        </div>{' '}
                      </div>{' '}
                    </li>{' '}
                    <li>
                      {' '}
                      <button> d) Legal purposes </button>{' '}
                      <div id="collapse-7">
                        {' '}
                        <div>
                          {' '}
                          <div>
                            {' '}
                            <p>
                              -Preventing fraud and other prohibited or illegal
                              activities and guaranteeing the safety and
                              security of our Chopard platforms, boutiques and
                              offices ;
                            </p>
                            <p>
                              -Complying with our billing and accounting
                              requirements and other legal or regulatory
                              obligations (e.g. anti-money laundering
                              obligations).
                            </p>{' '}
                          </div>{' '}
                        </div>{' '}
                      </div>{' '}
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul>
            <li>
              <button>3.2 How long is your Personal Data stored?</button>
              <div id="collapse-8">
                <div>
                  <div>
                    {' '}
                    <p>
                      Unless required by law or because of security imperative
                      for another time period, we may store or otherwise process
                      your Personal Data <u>as long as</u> it is{' '}
                      <strong>
                        necessary to satisfy the purposes described hereinabove
                      </strong>{' '}
                      <u>and insofar as</u> you do{' '}
                      <strong>not withdraw your consent</strong> for its
                      processing (either for one or several purposes) n<u>or</u>
                      <strong> exercise your right to be forgotten</strong>{' '}
                      before the expiration of any of these time periods (by any
                      of the means described below under the “How can you
                      contact us?” section).&nbsp;Your Personal Data will be
                      stored in a manner compatible with legal obligations,
                      available technology and cost of implementation.
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul>
            <li>
              <button>
                3.3 Do we share your Personal Data with third parties? If so,
                with whom?
              </button>
              <div id="collapse-9">
                <div>
                  <div>
                    {' '}
                    <p>
                      We may only share your Personal Data{' '}
                      <u>within the Chopard Group</u> and{' '}
                      <u>with third partie</u>s in the few following cases:
                    </p>
                    <p>
                      <strong>&nbsp;</strong>-service{' '}
                      <strong>providers </strong>supporting us in our business
                      operations (e.g. to deliver products, allow payment
                      authorization, for the organization of events or to send
                      our Chopard e-newsletter, to support, manage and maintain
                      our Chopard Platforms, mitigate risk, help prevent fraud
                      and promote trust and safety)<span> ;</span>
                    </p>
                    <p>
                      -third parties,{' '}
                      <strong>
                        courts or regulators or any other third parties
                      </strong>{' '}
                      if it is required by law or in connection with a legal
                      claim or proceeding or to combat fraud.
                    </p>
                    <p>
                      We expect all these third parties to provide{' '}
                      <strong>
                        necessary guarantees of confidentiality and security
                      </strong>{' '}
                      and to take appropriate organizational and technical
                      measures in compliance with the applicable laws. Our
                      service providers may only process the Personal Data we
                      share with them according to and{' '}
                      <strong>within the limits of our instructions</strong>
                      .&nbsp;
                    </p>
                    <p>
                      <br />
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul>
            <li>
              <button>
                3.4 Do we transfer your Personal Data outside Switzerland or
                European Union?
              </button>
              <div id="collapse-10">
                <div>
                  <div>
                    {' '}
                    <p>
                      When we share your Personal Data under the circumstances
                      described hereinabove, we may transfer it to a destination
                      outside Switzerland or the European Union.&nbsp;In any
                      case, such transfers will be only executed if the
                      competent authorities have confirmed the adequacy of the
                      country's level of protection or if we can offer you other
                      appropriate safeguards. You can obtain additional
                      information on the appropriate safeguards by contacting us
                      (see the section “How can you contact us?” below).
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div></div>
      <div>
        <div>
          <h2>4. Your Rights</h2>
        </div>
      </div>
      <div>
        <div>
          <ul>
            <li>
              <button>
                4.1 What rights do you have regarding your Personal Data?
              </button>
              <div id="collapse-11">
                <div>
                  <div>
                    {' '}
                    <p>
                      -<strong>The right of access</strong>
                      <span>
                        : You have the right to know what Personal Data has been
                        collected about you and how such Personal Data has been
                        processed.
                      </span>
                    </p>
                    <p>
                      <strong>-</strong>
                      <strong>The right to rectification</strong>
                      <span>
                        : You have the right to request changes to inaccurate
                        Personal Data.
                      </span>
                    </p>
                    <p>
                      <strong>-</strong>
                      <strong>The right to object</strong>
                      <span>
                        : You may declare that you do not want your Personal
                        Data processed (in whole or in part) at any time.{' '}
                      </span>
                    </p>
                    <p>
                      <strong>-</strong>
                      <strong>The right to be forgotten</strong>
                      <span>
                        : You may exercise this right particularly when you no
                        longer consent to the processing of your Personal Data.
                        You have{' '}
                      </span>
                      <strong>
                        the right to withdraw your consent anytime
                      </strong>
                      <span>
                        {' '}
                        (by any of the means described below under the “How can
                        you contact us?” section).{' '}
                      </span>
                      <u>
                        The right to be forgotten is not an absolute right and
                        only applies in certain circumstances
                      </u>
                      <span>. </span>
                    </p>
                    <p>
                      <strong>-</strong>
                      <strong>The right to restrict processing</strong>
                      <span>
                        : You have the right to limit the processing of your
                        Personal Data, i.e. limit the way we use your Personal
                        Data. This is an alternative to exercising your right to
                        be forgotten. This right{' '}
                      </span>
                      <u>
                        is not an absolute right and only applies in certain
                        circumstances.
                      </u>
                    </p>
                    <p>
                      <strong>-</strong>
                      <strong>The right to be informed</strong>
                      <span>
                        : You have the right to obtain any and all information
                        regarding the processing of your Personal Data that is
                        clear, correct, comprehensive and easy to access.{' '}
                      </span>
                    </p>
                    <p>
                      <strong>-</strong>
                      <strong>The right to data portability</strong>
                      <span>
                        : You have the right to transfer (or to ask for the
                        transfer of) your Personal Data from one electronic
                        processing system to another electronic processing
                        system.
                      </span>
                    </p>
                    <p>
                      <strong>-</strong>
                      <strong>
                        The right in relation to automated decision making or
                        profiling{' '}
                      </strong>
                      <span>
                        You have the right not to be subject to a decision based
                        solely on automated processing, such as profiling.
                      </span>
                    </p>
                    <p>
                      <strong>-</strong>
                      <strong>
                        The right to lodge a complaint: You have the right to
                        lodge any complaint{' '}
                      </strong>
                      <span>
                        that you may have with a supervisory authority.
                      </span>
                    </p>
                    <p>
                      <span>These rights may be subject to </span>
                      <strong>exceptions or limitations </strong>
                      <span>
                        (e.g. when your Personal Data has been processed for
                        security purposes or stored for legal or contractual
                        obligations) and{' '}
                      </span>
                      <strong>
                        must account for available technology and the cost of
                        implementation
                      </strong>
                      <span>. </span>
                    </p>
                    <p>
                      <span>
                        In certain cases, we will need to verify your identity
                        before processing a request you may have based on the
                        above-mentioned rights.
                      </span>
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div>
        <div>
          <ul>
            <li>
              <button>
                4.2 To whom should you address any request about your Personal
                Data?
              </button>
              <div id="collapse-12">
                <div>
                  <div>
                    {' '}
                    <p>
                      All Personal Data collected by Chopard is controlled by
                      us. As a controller, we determine the purposes and means
                      of the processing of your Personal Data.
                    </p>
                    <p>
                      Specifically, Chopard’s controller is the company
                      <strong>
                        {' '}
                        Le petit-fils de L.U. Chopard &amp; Cie SA, rue de
                        Veyrot 8, 1217 Meyrin 1, Geneva, Switzerland
                      </strong>
                      .
                    </p>
                    <p>
                      If you would like to exercise any of your rights as
                      described in this Privacy Policy or if you have any{' '}
                      <strong>question or concerns</strong> about this Privacy
                      Policy, please contact us (see below).
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul>
            <li>
              <button>4.3 How can you contact us?</button>
              <div id="collapse-13">
                <div>
                  <div>
                    {' '}
                    <p>
                      -Through the “Contact Us” form (
                      <a
                        href="http://www.chopard.com/intl/contact-us"
                        target="_blank"
                      >
                        www.chopard.com/intl/contact-us
                      </a>
                      ) ;
                    </p>
                    <p>
                      -By writing to the Chopard Data Protection Office either
                      by email (
                      <a
                        href="mailto:dataprotection.office@chopard.ch"
                        target="_blank"
                      >
                        dataprotection.office@chopard.ch
                      </a>
                      ) or by posted letter (8, rue de Veyrot, 1217 Meyrin 1,
                      Genève, Suisse) ;
                    </p>
                    <p>
                      -By signing the consent withdrawal in the Client Form
                      (only to withdraw your consent).
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul>
            <li>
              <button>
                4.4 How do we guarantee the security of your Personal Data?
              </button>
              <div id="collapse-14">
                <div>
                  <div>
                    {' '}
                    <p>
                      We use the appropriate technical, organizational and
                      administrative security measures to protect any Personal
                      Data we process from loss, misuse, unauthorized access,
                      disclosure, alteration and destruction.
                    </p>
                    <p>
                      Nevertheless, even if we strive to protect your Personal
                      Data, we cannot guarantee the security of the information
                      you transmit on our Chopard Platforms when transferred
                      over the Internet on unsecure networks.
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul>
            <li>
              <button>
                4.5 How do we guarantee the protection of your Personal Data if
                you are under 16 ?
              </button>
              <div id="collapse-15">
                <div>
                  <div>
                    {' '}
                    <p>
                      <strong>
                        <u>Note to children under 16</u>
                      </strong>
                      <span>
                        : We will not knowingly collect or otherwise process
                        (particularly through our Chopard Website) any Personal
                        Data from anyone under the age of 16. If you are under
                        16, please ask one of your parents (or another legal
                        guardian) to register and/or fill out the Client Form
                        for you. Please do not submit any of your Personal Data
                        to us. Should we discover or be informed that we have
                        mistakenly collected your Personal Data, we will
                        immediately delete such information from our records.
                      </span>
                    </p>
                    <p>
                      <strong>
                        <u>Note to parents or other legal guardians:</u>{' '}
                      </strong>
                      <span>
                        We will not intentionally collect (or otherwise process)
                        any Personal Data from your children if they are under
                        16. If you are concerned that your child has registered
                        on our Chopard Website or submitted any of their
                        Personal Data to us and you wish to delete it, please
                        contact us via the methods set out hereinabove and
                        provide official documents establishing parental or
                        guardian status.
                      </span>
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div></div>
      <div>
        <div>
          <h2>5. Changes To This Privacy Policy</h2>
        </div>
      </div>
      <div>
        <div>
          <ul>
            <li>
              <button>5.1 Can we change this Privacy Policy?</button>
              <div id="collapse-16">
                <div>
                  <div>
                    {' '}
                    <p>
                      <span>
                        We reserve our right to change this Privacy Policy at
                        any time. A notification regarding any substantial
                        changes to this Privacy Policy will be posted on our
                        Chopard Platforms. Notwithstanding, it is your
                        responsibility to stay abreast of any changes made to
                        our Privacy Policy.
                      </span>
                    </p>
                    <p>
                      <strong>
                        <em>
                          This Privacy Policy was last updated on January 1
                        </em>
                      </strong>
                      <strong>
                        <em>st</em>
                      </strong>
                      <strong>
                        <em> 2022</em>
                      </strong>
                      <em>.</em>
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
  const krPrivacy = (
    <div className="chopard-content">
      <div>
        <div>
          <h1>개인정보 보호정책</h1>

          <h2>
            Please read the following information carefully before using the
            site
          </h2>
        </div>
      </div>
      <div>
        <div>
          <h2>1. 소개</h2>
        </div>
      </div>
      <div>
        <div>
          <div>
            <p>
              쇼파드 그룹은 쇼파드의 모회사이자 스위스에 소재한 'Le petit-fils
              de L.U. Chopard &amp; Cie SA'와 그 자회사들을 모두 포함하는
              기업으로(이하&nbsp;<strong>‘쇼파드’, ‘저희’, ‘당사’</strong>라
              칭함) 귀하에게 제공하는 서비스를 지속적으로 개선해나가는 한편,
              귀하의 개인 정보를 보호해드리기 위해 노력할 것을 약속 드립니다.
            </p>
            <p>
              쇼파드 개인정보 보호정책 (이하&nbsp;
              <strong>
                ‘개인정보 보호정책’이라 칭함)은 당사가 온라인 플랫폼(웹사이트,
                애플리케이션, 소셜 네트워크),(이하 ‘쇼파드 플랫폼’
              </strong>
              이라 칭함)을 매개로 수집하거나 당사의 부티크, 고객 서비스
              센터,&nbsp;
              <strong>당사가 주최한 행사, 기타 다른 모든 형태의</strong>
              &nbsp;상호작용에서 귀하가 작성한 쇼파드 고객 양식 (이하 ‘고객
              양식’이라 칭함)을 통해 수집하게 되는
              <strong>&nbsp;모든 개인 정보</strong>의 처리(예: 수집, 사용, 기록,
              보관, 이전)에 관한 일체의 정보를 귀하에게 제공하려는 의도입니다.
            </p>
            <p>
              <span>개인정보 보호정책은 다음 질문에 답변해드립니다:</span>
            </p>
            <p>-&nbsp;개인 정보란 무엇이고 어떻게 수집하는가?</p>
            <p>-&nbsp;어떤 유형의 개인 정보를 수집하는가?</p>
            <p>-&nbsp;어떤 근거로 개인 정보를 수집하는가?</p>
            <p>-&nbsp;개인 정보를 어떤 목적으로 사용하는가?</p>
            <p>-&nbsp;개인 정보를 얼마나 오래 보관하는가?</p>
            <p>
              -&nbsp;개인 정보를 제삼자와 공유하는가? 만약 그렇다면 누구와
              공유하는가?
            </p>
            <p>
              -&nbsp;개인 정보가 스위스나 유럽연합 이외에 다른 장소로 이전될 수
              있는가?
            </p>
            <p>-&nbsp;개인 정보에 대한 나의 권리는 무엇인가?</p>
            <p>
              -&nbsp;개인 정보에 관해 요청을 하고자 할 때 어디에 문의해야 하나?
            </p>
            <p>-&nbsp;문의하기</p>
            <p>-&nbsp;개인 정보에 대한 보안은 어떻게 보장되는가?</p>
            <p>-&nbsp;만 16세 미만 사용자의 개인 정보는 어떻게 보호되는가?</p>
            <p>-&nbsp;이 개인정보 보호정책이 변경될 수 있는가?</p>
            <p>
              <span>
                쇼파드 플랫폼에 연결되어 있거나 쇼파드 플랫폼으로부터 접근이
                가능한 다른 제삼자 웹사이트나 애플리케이션에 의해 수집된 개인
                정보는 개인정보 보호정책의 적용 대상이 아닙니다. 당사는 위와
                같은 제삼자 웹사이트와 애플리케이션의 개인정보 보호정책과 활동에
                대해서는 법적 또는 도의적인 책임이 없습니다.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div>
        <div>
          <h2>2. 쇼파드의 개인 정보 수집</h2>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>2.1개인 정보란 무엇이고 어떻게 수집하는가?</button>
              <div id="collapse-0,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      당사는 귀하(신원이 확인된 자연인)와 관련이 있거나 귀하의
                      신원을 확인해주는 사적인 정보(이하 귀하의&nbsp;
                      <strong>‘개인 정보</strong>’라 칭함)를 다음과 같이 다양한
                      방법과 상황에서 수집할 수 있습니다:
                    </p>
                    <p>
                      - 저희 쇼파드 플랫폼을 방문했을 때특히 웹사이트에
                      가입하거나 문의하기(Contact Us) 양식을 작성할 때,
                    </p>
                    <p>- 당사 직영 부티크, 판매처, 사무실을 방문했을 때,</p>
                    <p>- 쇼파드 제품을 구입하거나 고객 서비스를 요청했을 때,</p>
                    <p>- 이메일이나 전화를 통해 당사와 상호작용할 때,</p>
                    <p>
                      - 당사 부티크나 고객 서비스 센터 또는 행사 현장에서
                      (인쇄물이나 전자문서 형태의) 고객 양식을 포함해 모든
                      형태의 양식을 작성할 때,,
                    </p>
                    <p>
                      - 당사의 영업 파트너와 광고 파트너를 통해(이 경우 항상
                      명시적으로 동의를 득함).
                    </p>
                    <p>
                      <br />
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>2.2 어떤 유형의 개인 정보를 수집하는가?</button>
              <div id="collapse-1,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      당사와 상호작용하기 위해 선택한 이유 및 방식에 따라&nbsp;
                      <strong>귀하가 당사에 적극적으로 제공하는</strong>
                      &nbsp;다음과 같은 정보(개인 정보에 해당하는)를 수집할 수
                      있습니다.
                    </p>
                    <p>- 신원 정보(이름, 성, 성별, 생년월일, 국적)</p>
                    <p>- 연락처 정보(우편 주소, 이메일 주소,전화번호)</p>
                    <p>- 신분에 대한 정보(호칭)</p>
                    <p>
                      - 구매 및 결제 관련 정보(신용카드 정보 및 자금 세탁
                      방지법에서 요구하는 정보)
                    </p>
                    <p>
                      - 개인적 및/또는 직업적 선호도와 관심사를 비롯한 기타 개인
                      정보, 인구 통계학적 범주, 당사 제품에 대한 경험 및
                      선호하는 연락처, 직업적 상황(직무, 회사/고용주, 업무
                      연락처), 주 관심사(스포츠, 레저), 라이프 스타일
                    </p>
                    <p>
                      -고객 서비스 정보(예: 고객 서비스 문의, 의견, 반품, 불만,
                      수선 내역)
                    </p>
                    <p>-구직 관련 정보(연수 이력 및 업무 경험)</p>
                    <p>
                      또한 당사는 자동화된 수단이나 간접적인 방법을 통해 귀하나
                      제삼자(웹사이트 및 소셜 네트워크)를 통해 다음과 같은 다른
                      유형의 정보(개인 정보를 구성할 수 있음)를 수집할 수
                      있습니다.
                    </p>
                    <p>
                      - IP 주소를 비롯하여 쇼파드 플랫폼 사용에 관한 정보, 또는
                      쿠키나 귀하의 장치에 탑재된 유사 기술을 통한 기타 탐색
                      데이터(사용된 브라우저 및 장치 유형 포함). 일부 쿠키는
                      쇼파드 플랫폼의 올바른 작동을 위해 필요하며 다른 쿠키는
                      더욱 개인화된 서비스와 보다 나은 디지털 경험을 제공하는 데
                      도움이 되는 분석 목적으로 사용됩니다. 쿠키 및 기본 설정
                      변경 방법에 대한 자세한 내용은 당사의 쿠키 사용 정책을
                      참조하십시오.
                    </p>
                    <p>- 쇼파드 부티크나 매장 또는 사무실의 감시카메라 영상</p>
                    <p>
                      - 쇼파드와의 상호작용 여부와 관계없이 소셜 네트워크에서
                      공개적으로 공유하는 정보
                    </p>
                    <p>
                      <br />
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>2.3 어떤 근거로 개인 정보를 수집하는가?</button>
              <div id="collapse-2,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      당사는 다음과 같은 법적 근거 중 최소 한 가지에 해당할
                      경우에 한해&nbsp;귀하의 개인 정보를&nbsp;그 범위 안에서
                      처리하겠다고 약속드립니다.
                    </p>
                    <p>
                      - 귀하가 하나 이상의&nbsp;
                      <strong>
                        구체적인 목적을 위해 특정 개인 정보를 처리하는 것에 대해
                        명시적으로 동의했을 때&nbsp;
                      </strong>
                      (예: 쇼파드 웹사이트를 사용하기 위해, 뉴스레터를 수신하기
                      위해, ‘문의하기(Contact Us)’ 양식을 통해 연락을 받기 위해)
                    </p>
                    <p>
                      - 계약(구매, 수선 지시, 기타 서비스 관련&nbsp;
                      <strong>계약)을 이행하거나</strong>&nbsp;계약을 체결하기에
                      앞서 귀하의 요청에 따른 절차를 밟기 위해서는 개인 정보
                      처리가 필요합니다.
                    </p>
                    <p>
                      - 당사가&nbsp;
                      <strong>지켜야 하는 법적 의무를 준수하기 위해</strong>
                      &nbsp;개인 정보 처리가 필요합니다.
                    </p>
                    <p>
                      - 당사가 추구하는&nbsp;
                      <strong>합법적 이해관계를 위해 개인 정보 처리가</strong>
                      &nbsp;필요합니다(예: 보안 목적, 개인화된 유지보수 및
                      서비스 제공).
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <br />
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <h2>3. 쇼파드의 개인 정보 사용</h2>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>3.1 개인 정보를 어떤 목적으로 사용하는가?</button>
              <div id="collapse-3,00">
                <div>
                  <ul role="presentation">
                    {' '}
                    <li role="presentation">
                      {' '}
                      <button>
                        {' '}
                        3.1.1&nbsp;마케팅 및 판촉 목적:&nbsp;{' '}
                      </button>{' '}
                      <div id="collapse-4,00">
                        {' '}
                        <div>
                          {' '}
                          <div>
                            {' '}
                            <p>- 쇼파드 이벤트에 대한 개인 초청장 발송</p>
                            <p>
                              - 콘텐츠에 대한 기업 커뮤니케이션, 쇼파드 세계에
                              대한 소식(예: 신규 부티크 오픈), 개인적인 관심사
                              및 선호도에 부합하는 마케팅 커뮤니케이션(소셜
                              미디어 청중 통신, 선물, 카탈로그를 포함한 광고
                              제안) 발송
                            </p>
                            <p>
                              - 쇼파드 뉴스레터를 이메일로 발송, 개인적 관심사와
                              선호도에 맞는 당사의 작품, 이벤트, 제안, 개인화된
                              서비스를 미리 만나보는 기회 제공
                            </p>
                            <p>
                              <strong>표적 광고:&nbsp;</strong>당사의
                              커뮤니케이션 및 광고 제안을 귀하의 개인적인 관심과
                              선호도에 맞게 조정하고 마케팅 전략 효과를 관리하기
                              위해 당사는 광고 대행사와 분석 도구 공급업체와
                              같은 제삼자 도구, 기술, 서비스를 사용합니다.
                            </p>
                            <p>
                              그리고 귀하가 당사에 제공한 정보와 귀하의 구매
                              내역을 바탕으로 귀하의 선호 사항을 간추릴 수
                              있습니다.
                            </p>
                            <p>
                              당사는 또한, 쿠키를 통해 또는 귀하의 이메일
                              주소(당사 고객 양식을 통해 전달된 기타 정보와
                              같이)를 제삼자 애플리케이션 및/또는 소셜
                              네트워크를 통해 플랫폼 사용자와 통신할 수 있는
                              고유한 값(익명화된)으로 변환하여 제삼자
                              애플리케이션 및/또는 소셜 네트워크(예: 구글,
                              페이스북, 인스타그램)에서 운용하는 플랫폼에서
                              개인화된 청중(고객 양식을 작성했거나 당사
                              웹사이트를 방문했거나 쇼파드 플랫폼에서 상호작용한
                              사람들로 구성)을 생성할 수 있습니다. 이러한
                              방식으로 해당 플랫폼에서 귀하의 관심사와 선호도에
                              부합하는 당사 제품 및 서비스의 표적 광고를 표시할
                              수 있습니다.
                            </p>
                            <p>
                              "표적 광고용 쿠키" 카테고리를 선택 해제하여 쿠키
                              제어 도구를 통해 이러한 유형의 광고를 수신하지
                              않는 것을 선택할 수 있습니다. 하지만 당사는 제삼자
                              표적화 기술이 쇼파드 플랫폼 또는 당사의 광고와
                              관련이 있더라도 이를 통제하지 않습니다. 따라서
                              개인 정보의 수집, 저장, 공유와 관련하여 사용 약관,
                              사용 정책, 개인정보 보호 정책 그리고 제삼자
                              애플리케이션 및 소셜 네트워크의 기타 관행을
                              참조하는 것이 좋습니다.
                            </p>{' '}
                          </div>{' '}
                        </div>{' '}
                      </div>{' '}
                    </li>{' '}
                    <li role="presentation">
                      {' '}
                      <button> 3.1.2&nbsp;영업 목적: </button>{' '}
                      <div id="collapse-5,00">
                        {' '}
                        <div>
                          {' '}
                          <div>
                            {' '}
                            <p>
                              <span>- 주문, 구매, 배송 처리,</span>
                            </p>
                            <p>
                              <span>- 고객 서비스 제공</span>
                            </p>{' '}
                          </div>{' '}
                        </div>{' '}
                      </div>{' '}
                    </li>{' '}
                    <li role="presentation">
                      {' '}
                      <button> 3.1.3&nbsp;고객 지원 목적&nbsp;: </button>{' '}
                      <div id="collapse-6,00">
                        {' '}
                        <div>
                          {' '}
                          <div>
                            {' '}
                            <p>
                              <span>
                                - 쇼파드 플랫폼, 제품, 서비스에 대한 정보 제공,
                              </span>
                            </p>
                            <p>
                              <span>- 귀하의 요청에 응대,,</span>
                            </p>
                            <p>
                              <span>
                                - 쇼파드 플랫폼의 관리 운영, 최적화 및 지원 및
                                보안 서비스 제공.
                              </span>
                            </p>{' '}
                          </div>{' '}
                        </div>{' '}
                      </div>{' '}
                    </li>{' '}
                    <li role="presentation">
                      {' '}
                      <button> 3.1.4법률적 목적&nbsp;: </button>{' '}
                      <div id="collapse-7,00">
                        {' '}
                        <div>
                          {' '}
                          <div>
                            {' '}
                            <p>
                              <span>
                                - 사기를 비롯해 기타 금지된 활동이나 위법적인
                                활동을 방지하고 쇼파드 플랫폼, 부티크, 사무실의
                                안전과 보안을 보장,
                              </span>
                            </p>
                            <p>
                              <span>
                                - 대금 청구 및 회계 요건을 비롯해 기타 법적 또는
                                규제 관련 의무 사항(예: 자금세탁방지 의무) 준수.
                              </span>
                            </p>{' '}
                          </div>{' '}
                        </div>{' '}
                      </div>{' '}
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>3.2 개인 정보를 얼마나 오래 보관하는가?</button>
              <div id="collapse-8,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      <span>
                        위에 명시된 목적을 달성하는 데 필요한 기간이 만료되기
                        전에 귀하가 (‘문의처’ 항목에 명시되어 있는 방법 중
                        하나를 사용해) 개인 정보를&nbsp;(하나 또는 여러 가지
                        목적을 위해)&nbsp;
                      </span>
                      <strong>처리하는 것에 대해 동의를 철회하거나</strong>
                      <span>&nbsp;당사는 목적 달성에 필요한&nbsp;</span>
                      <strong>당사는 목적 달성에 필요한&nbsp;</strong>
                      <span>
                        기간 내내 귀하의 개인 정보를 보관 또는 처리할 수
                        있습니다. 단, 법률이 정하는 사항이거나 다른 기간을 위해
                        시급한 보안상 이유가 있는 경우는 예외입니다. 귀하의 개인
                        정보는 법적 의무와 사용이 가능한 기술 및 구현 비용을
                        감안한 범위내에서 적절하게 보관이 이루어질 것입니다.
                      </span>
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>
                3.3 개인 정보를 제삼자와 공유하는가? 만약 그렇다면 누구와
                공유하는가?
              </button>
              <div id="collapse-9,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      당사는 귀하의 개인 정보를&nbsp;쇼파드 그룹
                      내부와&nbsp;다음과 같은몇몇&nbsp;경우에
                      한해&nbsp;제삼자들과공유할 수 있습니다.
                    </p>
                    <p>
                      - 쇼파드의&nbsp;<strong>사업 활동을 지원하는</strong>
                      &nbsp;서비스 제공업체(예: 제품 배송, 결제 승인 중계,
                      이벤트 개최, 쇼파드 e-뉴스레터 발송, 쇼파드 플랫폼 지원,
                      관리, 유지 보수, 위험 완화, 사기 예방, 신뢰와 안전 촉진 및
                      장려 활동 지원),
                    </p>
                    <p>
                      - 법에 정해져 있거나&nbsp;<strong>법적 소송 또는</strong>
                      &nbsp;청구와 관련되어 있거나 사기 방지를 위한 목적일
                      경우에 제삼자, 법원, 규제 기관.
                    </p>
                    <p>
                      당사는 이런 제삼자들이 모두 비밀 유지와 보안에 필요한
                      보증을 제공하고 해당 법이 정하는 대로 구조적, 기술적으로
                      적절한 조치를 취할 것이라고 믿습니다. 당사의 서비스
                      제공업체들은 당사가 공유해주는 개인 정보를&nbsp;
                      <strong>
                        당사의 지침에 따라, 오직 그 범위 안에서만 처리할 수
                        있습니다.&nbsp;
                      </strong>
                      .
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <br />
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>
                3.4 개인 정보가 스위스나 유럽연합 이외에 다른 장소로 이전될 수
                있는가?
              </button>
              <div id="collapse-10,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      <span>
                        위에 명시된 상황에서 귀하의 개인 정보를 공유할 경우
                        당사는 귀하의 개인 정보를 스위스나 유럽연합이 아닌 다른
                        목적지로 이전할 수 있습니다. 어떤 상황이든, 관할 기관이
                        해당 국가의 정보 보호 수준이 충분하다고 공식
                        확인해주거나 당사가 귀하에게 다른 적절한 보호 장치를
                        제공할 수 있는 경우에만 그런 이전이 실행될 것입니다.
                        당사에 문의하시면 위에 명시된 적절한 보호 장치에 대해 좀
                        더 상세한 정보를 제공해드리겠습니다(아래 문의처 참조).
                      </span>
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div></div>
      <div>
        <div>
          <h2>4. 귀하의 권리</h2>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>4.1 개인 정보에 대한 나의 권리는 무엇인가?</button>
              <div id="collapse-11,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      -&nbsp;<strong>정보접근권</strong>: 귀하는 자신에 대해
                      어떤 개인 정보가 수집되었고 그런 개인 정보가 어떻게
                      처리되었는지 알 권리가 있습니다.
                    </p>
                    <p>
                      -&nbsp;<strong>정보정정권</strong>: 귀하는 올바르지 않은
                      개인 정보에 대해 수정을 요구할 권리가 있습니다
                    </p>
                    <p>
                      -&nbsp;<strong>이의 신청권:&nbsp;</strong>귀하는 자신의
                      개인 정보에 대한 (전체 또는 일부) 처리가 이루어지거나
                      계속되는 것을 원치 않으실 경우 언제든지 이에 관한 의사를
                      표시할 수 있습니다.
                    </p>
                    <p>
                      -&nbsp;<strong>잊혀질 권리:&nbsp;</strong>이 권리는 특히
                      개인 정보 처리에 더 이상 동의하지 않을 때 행사할 수
                      있으며, 귀하는 언제든지 동의를 철회할 권리가 있습니다(아래
                      ‘문의처’ 항목에 명시된 방법 중 하나를 사용).&nbsp;잊혀질
                      권리는 절대적인 권리가 아니고 특정 상황에만
                      적용됩니다.;&nbsp;.
                    </p>
                    <p>
                      -&nbsp;<strong>정보처리 제한권&nbsp;</strong>: 귀하는
                      자신의 개인 정보 처리를 제한할 권리, 즉 당사가 귀하의 개인
                      정보를 사용하는 방식을 한정할 권리가 있습니다. 이는 잊혀질
                      권리 대신 행사할 수 있는 권리입니다. 이
                      권리는&nbsp;절대적인 권리가 아니고 특정 상황에만
                      적용됩니다..
                    </p>
                    <p>
                      -&nbsp;<strong>통보 받을 권리(알 권리)&nbsp;</strong>:
                      귀하는 자신의 개인 정보 처리에 관한 모든 정보를 알 권리가
                      있으며 그 정보는 명확하고 올바르고 포괄적이며 접근하기
                      쉬워야 합니다.
                    </p>
                    <p>
                      -&nbsp;<strong>데이터 이동권:&nbsp;</strong>: 귀하는
                      귀하의 개인 정보를 한 전자 처리 시스템에서 다른 전자 처리
                      시스템으로 이전(또는 이전을 요청)할 권리가 있습니다.
                    </p>
                    <p>
                      -&nbsp;
                      <strong>
                        자동화된 의사결정 또는 프로파일링에 관한 권리:&nbsp;
                      </strong>
                      귀하는 프로파일링과 같이 오직 자동화된 처리에 기반한
                      결정에 종속되지 않을 권리가 있습니다.
                    </p>
                    <p>
                      -&nbsp;<strong>불만 사항에 대해 감독 기관에&nbsp;</strong>
                      제소할 권리.
                    </p>
                    <p>
                      이러한 권리들에는&nbsp;
                      <strong>예외나 제한이 있으며&nbsp;</strong>(예: 귀하의
                      개인 정보가 보안 목적으로 처리되었거나 법적 의무 또는
                      계약상 의무에 따라 보관된 경우)
                      <strong>
                        사용 가능한 기술과 구현 비용에 대한 고려가 이루어져야
                        합니다.
                      </strong>
                    </p>
                    <p>
                      경우에 따라 당사는 위에 언급된 권리들에 근거한 귀하의 요청
                      내용을 처리하기 전에 귀하의 신원을 확인할 수도 있습니다.
                    </p>
                    <p>
                      <br />
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>
                4.2 개인 정보에 관해 요청을 하고자 할 때 어디에 문의해야 하나?
              </button>
              <div id="collapse-12,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      <span>
                        쇼파드가 수집한 모든 개인 정보는 당사가 관리합니다.
                        당사는 관리자로서 귀하의 개인 정보를 처리하는 목적과
                        수단을 결정합니다.
                      </span>
                    </p>
                    <p>
                      <span>쇼파드의 관리자는 구체적으로&nbsp;</span>
                      <strong>
                        Le petit-fils de L.U. Chopard &amp; Cie SA(주소: rue de
                        Veyrot 8, 1217 Meyrin 1, Switzerland)라는 회사입니다.
                      </strong>
                      <span>.</span>
                    </p>
                    <p>
                      <span>
                        본 개인정보보호정책에 명시된 권리를 행사하고 싶거나 본
                        개인정보 보호정책에 관한 질문이나 용무가 있으신 분은
                        당사로 연락해 주십시오(아래 문의처 참조).
                      </span>
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>4.3 문의하기</button>
              <div id="collapse-13,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      <span>- ’</span>
                      <a
                        href="https://www.chopard.com/ko-kr/contact.html"
                        target="_blank"
                        data-content-page-id="contact"
                      >
                        문의하기
                      </a>
                      <span>’ 양식 이용, </span>
                    </p>
                    <p>
                      <span>
                        - 쇼파드 개인정보 보호책임자에게
                        이메일(dataprotection.office@chopard.ch)로 또는 서면으로
                        연락(주소: Rue de Veyrot 8, 1217 Meyrin 1, Geneva,
                        Switzerland),
                      </span>
                    </p>
                    <p>
                      <span>
                        - 고객 양식에서 동의 철회에 서명(동의 철회 목적일 경우에
                        한함).
                      </span>
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>4.4 개인 정보에 대한 보안은 어떻게 보장되는가?</button>
              <div id="collapse-14,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      <span>
                        당사는 당사가 처리하는 모든 개인 정보가 분실, 오용, 무단
                        접근, 공개, 변조, 파괴되지 않도록 기술적, 구조적,
                        행정적으로 적절한 보안 조치를 사용해 보호하고 있습니다.
                      </span>
                    </p>
                    <p>
                      <span>
                        그런데 귀하의 개인 정보를 보호하기 위해 최선의 노력을
                        기울이고 있음에도 불구하고 비보안 네트워크로부터
                        인터넷을 통해 정보가 이전될 경우, 귀하가 쇼파드
                        플랫폼으로 전송하는 정보의 보안을 보장해드릴 수
                        없습니다.
                      </span>
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>
                4.5만 16세 미만 사용자의 개인 정보는 어떻게 보호되는가?
              </button>
              <div id="collapse-15,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      <strong>만 16세 미만 미성년자 주의사항:&nbsp;</strong>
                      <span>
                        당사는 만 16세 미만인 미성년자들의 경우, 그 어떤 개인
                        정보도 (특히 쇼파드 웹사이트를 통해) 의도적으로
                        수집하거나 처리하는 일을 하지 않습니다. 16세 미만인 분은
                        부모님(또는 다른 법적 보호자)에게 회원 가입 및/또는 고객
                        양식 작성을 부탁하십시오. 귀하의 개인 정보를 당사에
                        제출하지 마십시오. 당사가 실수로 귀하의 개인 정보를
                        수집했다는 사실을 발견하거나 통보 받을 경우, 해당 정보는
                        당사의 기록에서 즉시 삭제됩니다.
                      </span>
                    </p>
                    <p>
                      <strong>
                        부모님 또는 기타 법적 보호자 주의사항:&nbsp;
                      </strong>
                      <span>
                        당사는 만 16세 미만인 귀하의 자녀들로부터 의도적으로
                        개인 정보를 수집 (또는 처리)하지 않습니다. 귀하의 자녀가
                        저희 쇼파드 웹사이트에 회원으로 가입했거나 자녀의 개인
                        정보가 당사에 제출된 것이 염려되어 취소를 원하시면 위에
                        정리되어 있는 바와 같이 당사에 연락해 부모 또는 보호자
                        지위를 입증할 수 있는 공식 서류를 제출해주십시오.
                      </span>
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div></div>
      <div>
        <div>
          <h2>5. 개인정보 보호정책 변경</h2>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>5.1 이 개인정보 보호정책이 변경될 수 있는가?</button>
              <div id="collapse-16,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      당사는 본 개인정보 보호정책을 언제든지 변경할 권리를 갖고
                      있습니다. 본 개인정보 보호정책이 조금이라면 변경되면 그에
                      대한 고지가 쇼파드 플랫폼에 게시됩니다. 그러나 당사
                      개인정보 보호정책의 변경 사항에 주의를 기울이는 것은
                      귀하의 책임입니다.
                    </p>
                    <p>
                      <strong>
                        <em>
                          본 개인정보 보호정책의 최종 업데이트일은 2021년 3월
                          1일입니다.
                        </em>
                      </strong>
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <h1>privacy policy - applicable to non-european residents</h1>
        </div>
      </div>
      <div>
        <div>
          <div>
            <p>
              This policy sets out the basis on which Chopard (Great Britain)
              Limited ("Chopard", "we" or "us") will process any personal data
              that we collect from you, or that you provide to us, through the
              website www.chopard.co.uk ("Website"). By using or accessing this
              Website you signify your acknowledgment and assent to this policy.
              If you do not agree to this policy, please do not use this
              Website.
            </p>
            <p>
              This Privacy Policy is intended to help you understand how Chopard
              and its affiliates collect, use and safeguard the information you
              provide on this Website. This Privacy Policy does not apply to the
              use or disclosure of information that is collected or obtained by
              us through means other than this Website.
            </p>
            <p>
              For the purposes of the Data Protection Act 1998, the data
              controller is Chopard (Great Britain), Ltd of 28 Welbeck Street,
              London W1G 8EW. Telephone number: +44 (0) 20 7467 4200, fax
              number: +44 (0) 20 7467 4291. If you have any questions about this
              Privacy Policy you can contact us at the postal address and
              telephone number set out above. You may also contact us by e-mail
              at “
              <a
                href="https://www.chopard.com/ko-kr/contact.html"
                target="_blank"
                data-content-page-id="contact"
              >
                CONTACT US
              </a>
              ”.
            </p>
            <p>
              <br />
            </p>
          </div>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>changes to this policy</button>
              <div id="collapse-17,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      <span>
                        Chopard may revise this privacy policy from time to
                        time. Any changes to this privacy policy will be posted
                        on this Website and, where appropriate, notified to you
                        by e-mail. Unless you agree otherwise, we will process
                        your personal data collected through this Website in
                        accordance with the privacy policy posted on this
                        Website at the time that your personal data is
                        collected.
                      </span>
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>personal data</button>
              <div id="collapse-18,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      If you browse our Website, you may generally do so
                      anonymously without providing any personal data. However,
                      there are cases where we may collect personal data from
                      you. When you place an order for a product through this
                      Website, we collect your name, address, e-mail address,
                      phone number and payment details and any other information
                      about you necessary to fulfil your order. We also collect
                      information about any person who will receive any gift you
                      send (for example, that person’s name and address) and
                      retain a record of your purchases. We may also collect any
                      other personal data provided by you through this Website
                      (for example, when you register for an online account with
                      us).
                    </p>
                    <p>
                      We also use cookies and track IP addresses so we can
                      improve our services and enhance your Chopard experience.
                      Please see the 'Cookies, web analytics and tracking'
                      section below for more information. In case you change
                      your mind or wish us to update or delete your personal
                      data that we hold, Chopard will deal with such requests in
                      accordance with applicable laws and regulations. For any
                      request concerning your personal information, contact our
                      Customer Support department at the contact points
                      specified above. If you create an account under “MY
                      CHOPARD” you will be able to update your account
                      information online.
                    </p>
                    <p>
                      <br />
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>note to minors</button>
              <div id="collapse-19,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      <span>
                        Chopard will not knowingly collect personally
                        identifiable information from anyone under the age of
                        18. If you are under 18, please ask your parents to
                        register and/or use the Online Store. Please do not
                        submit any personal details if you are under the age of
                        18. Should we discover or be informed that a minor has
                        registered his/her or another minor’s personal details,
                        we will delete such information from our records.
                      </span>
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>note to parents</button>
              <div id="collapse-20,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      <span>
                        Chopard will not intentionally collect personally
                        identifiable information from children under age 18. If
                        you, as a parent or legal guardian, are concerned that
                        your child has registered on our Website, wish to cancel
                        your child’s registration and have all details deleted,
                        please contact us by email or by mail as set out above
                        providing official documents establishing parental or
                        guardian status.
                      </span>
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>use and storage of personal data</button>
              <div id="collapse-21,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      We use the personal information that is collected through
                      our Website to:
                    </p>
                    <p>
                      - carry out our obligations in relation to any products
                      that you purchase from us;
                    </p>
                    <p>
                      - provide you with information about our Website, products
                      and services; and
                    </p>
                    <p>
                      - better serve our users, customize their shopping
                      experience and improve the content of our Website.
                    </p>
                    <p>
                      Personal data that you provide will be used for marketing
                      and promotional purposes only by Chopard and its
                      subsidiaries and affiliates. Chopard may share your
                      personal data within the Chopard organization, so that you
                      may be informed of other products that may be of interest
                      to you. Further, in order to maintain our Website and
                      provide for customer communications, Chopard contracts
                      with selected third parties, solely for the purpose of
                      assisting Chopard in these endeavors and subject to
                      contractual confidentiality obligations. We do not
                      otherwise disclose your information to third parties,
                      unless required by law or in connection with a legal claim
                      or proceeding. We may share your personal information as
                      follows:
                    </p>
                    <p>- Among our affiliates and related companies;</p>
                    <p>
                      - With third party agents we have hired to help us provide
                      a good or service you have requested (for example, in
                      connection with your order, we would provide your credit
                      card number to a payment authorization service and your
                      address to a shipping company);
                    </p>
                    <p>- In the Special cases set out below.</p>
                    <p>
                      We do not otherwise rent or sell to third parties your
                      personally identifiable information entered on this
                      Website. We will send you announcements or communications
                      by email only if you give us your permission by submitting
                      your personal details. If you register for a Website
                      account or make a purchase, you will automatically receive
                      promotional emails from Chopard or affiliated companies,
                      unless you opt-out from receiving promotional emails. To
                      remove yourself from our email list, you can: click the
                      unsubscribe link in any of our promotional emails you
                      receive; change your email preferences by logging into the
                      "MY CHOPARD" section of the Website; or contact a Customer
                      Service representative. Otherwise, we use your information
                      only to review our performance and to improve our services
                      to you, or to communicate with you about your order if you
                      have placed an online order. We may share statistical or
                      aggregated non-personal information about our users with
                      advertisers, business partners, sponsors and other third
                      parties. This data is used to customize our Website
                      content and advertising to deliver a better experience to
                      our users. Additionally, when you access our website, we
                      or companies we work with, may use technologies to collect
                      information about your computer or other access device to
                      mitigate risk, help prevent fraud and promote trust and
                      safety.
                    </p>
                    <p>
                      <br />
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <ul role="presentation">
            <li role="presentation">
              <button>security</button>
              <div id="collapse-22,00">
                <div>
                  <div>
                    {' '}
                    <p>
                      <span>
                        We use security measures consistent with industry
                        practice to protect the loss, misuse and alteration of
                        the information under our control. Additionally, we or
                        companies we work with, may use technologies to collect
                        information about your computer or other access device
                        to mitigate risk, help prevent fraud and promote trust
                        and safety. All Internet transactions with Chopard are
                        performed on a server that uses industry standard
                        encryption technology to protect your credit card
                        information from being read by unauthorized third
                        parties. We will comply with all privacy laws and make
                        any legally required disclosures regarding breaches of
                        the security, confidentiality, or integrity of personal
                        data consistent with our ability to determine the scope
                        of a breach and our obligations to law enforcement.
                      </span>
                    </p>{' '}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div></div>
      <div></div>
    </div>
  );

  const terms = isKorean ? krTerm : enTerm;
  const privacy = isKorean ? krPrivacy : enPrivacy;
  const content = type === 'terms' ? terms : privacy;
  return (
    <div className={`fixed-full ${type ? 'show' : ''}`}>
      <div className="backdrop" onClick={onClose} />
      <div className="content">
        <div className="lang-switcher">
          <span
            onClick={() => setIsKorean(true)}
            className={`language ${isKorean ? 'underline' : ''} noselect`}
          >
            한국어
          </span>
          |
          <span
            onClick={() => setIsKorean(false)}
            className={`language ${!isKorean ? 'underline' : ''} noselect`}
          >
            ENGLISH
          </span>
        </div>
        {content}
      </div>
      {type && (
        <div className="close-button" onClick={onClose}>
          <BsX color="#666" size={40} />
        </div>
      )}
      <style jsx>{`
        .lang-switcher {
          margin-bottom: 40px;
        }
        .language {
          cursor: pointer;
          display: inline-block;
          margin-right: 20px;
        }
        .language:last-child {
          margin-left: 20px;
        }
        .underline {
          font-weight: bold;
          border-bottom: 2px solid #333;
        }
        .fixed-full {
          pointer-events: none;
          overflow: hidden;
          opacity: 0;
          z-index: 2;
          transition: opacity 0.3s ease-in-out;
        }
        .backdrop {
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }
        .fixed-full.show {
          pointer-events: auto;
          opacity: 1;
        }
        .content {
          transition: transform 0.3s ease-in-out;
          transform: translateY(100%);
          width: 100%;
          border-radius: 15px 15px 0;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          overflow: auto;
          background: #fff;
          color: #333;
          text-align: left;
          padding: 20px;
          font-size: 14px;
        }

        .content :global(p),
        .content :global(li) {
          letter-spacing: 0 !important;
          font-size: 0.9em !important;
        }
        .content :global(h2),
        .content :global(h3),
        .content :global(h4) {
          font-family: 'EngraversGothicBTW03-Rg', 'Source Sans Pro', sans-serif !important;
          font-weight: normal !important;
          font-size: 1.4em;
          letter-spacing: 0.1em;
          margin-top: 50px;
        }
        .content :global(h1 + h2) {
          font-style: italic;
          margin-top: 0;
          font-size: 1em;
          font-family: 'Walbaum', 'Garamond', 'Baskerville',
            'Baskerville Old Face', 'Hoefler Text', 'Times New Roman', serif !important;
          font-weight: normal !important;
        }
        .show .content {
          transform: translateY(10%);
        }
        .content :global(button) {
          background: none;
          color: inherit;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
        }
        .close-button {
          position: absolute;
          right: 5px;
          top: calc(10% + 5px);
          cursor: pointer;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 100px;
        }
        @media (orientation: landscape) {
          .content {
            transform: translateX(-100%);
            width: 50%;
            max-width: 600px;
            border-radius: 0;
            padding: 40px;
          }
          .show .content {
            transform: translateX(0);
          }
          .close-button {
            left: min(600px, 50%);
            right: auto;
            margin-left: -45px;
            top: 5px;
          }
        }
      `}</style>
    </div>
  );
};

export default CHTermsPrivacy;
