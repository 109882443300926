import Router from 'next/router';
import React, { useMemo } from 'react';
import Share from './Sharing/Share';
import { IMainState } from '../interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { actionOpenARviewer, actionDidBackButton } from '../redux/actions';
import { PageView, getPath } from '../utils/pageView';
import { FaPlay } from 'react-icons/fa';
import { isEmbeddedInStreamingStudio } from '../utils/streamingstudio';
import { getVBUrlByStoreId, INSPIFY_LIGHT_LOGO } from '../config';
import { isUserOnDesktop } from '../utils/deviceDetector';
import {
  getARModelViewerUrl,
  IObjectSpotMap
} from './PixelStream/Chopard/CHConfig';
import { logEvent } from '../analytics';
import { DID_CLICK_AR_BUTTON } from '../utils/constants';
import { ARIcon } from './PixelStream/Chopard/CHIcons';
import { useTranslation } from 'next-i18next';

export interface ShareHeaderProps {
  title: string;
  shareTitle: string;
  type: PageView;
  id: string;
  shareImage?: string;
  showShare?: boolean;
  storeId?: string;
  productSpotConfig?: IObjectSpotMap;
  productModelCode?: string;
}

const HeaderChopard = ({
  title,
  shareTitle,
  shareImage,
  type,
  id,
  showShare = true,
  storeId,
  productSpotConfig,
  productModelCode
}: ShareHeaderProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [SspEmbed, setSspEmbed] = React.useState(true);
  const { i18n } = useTranslation();
  const language = `${i18n?.language || 'en'}`;
  const pathHistory =
    useSelector((state: IMainState) => state.clientState.pathHistory) || [];

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    setSspEmbed(isEmbeddedInStreamingStudio());
  }, []);

  const arViewerURl = useMemo(() => {
    if (!productSpotConfig?.arConfig || !productModelCode) {
      return '';
    }
    return getARModelViewerUrl(productModelCode, productSpotConfig.arConfig);
  }, [productModelCode, productSpotConfig]);

  return (
    <header className="text-center hide-for-client-meeting chopard-header">
      {pathHistory.length > 1 && (
        <span
          className="btn prev"
          onClick={() => {
            dispatch(actionDidBackButton(type));
            const path = pathHistory[pathHistory.length - 1];
            if (!path?.pageView) {
              return;
            }
            if (path.pageView === PageView.WISHLIST) {
              Router.push(path.url);
            } else if (path.pageView === PageView.PICK_OF_THE_MONTH) {
              Router.push(
                `/${getPath(
                  path.pageView
                )}/[brand]/[month]?language=${language}`,
                path.url
              );
            } else {
              Router.push(
                `/${getPath(path.pageView)}/[id]?language=${language}`,
                path.url
              );
            }
          }}
        >
          <FaPlay />
        </span>
      )}
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 noselect"></div>
          {title ? (
            <div className="pagetitle col-lg-9 col-md-8 noselect">{title}</div>
          ) : (
            <img src={INSPIFY_LIGHT_LOGO} alt="Inspify" className="logo" />
          )}
        </div>
      </div>
      {showShare && !SspEmbed && (
        <Share
          hasBackButton={pathHistory.length > 1}
          shareText={shareTitle}
          shareImage={shareImage}
          open={open}
          onOpen={handleOpenModal}
          onClose={handleCloseModal}
          type={type}
          id={id}
          externalUrl={getVBUrlByStoreId(storeId)}
        />
      )}
      {productSpotConfig?.arConfig &&
        (isUserOnDesktop() ? (
          <button
            className="btn btn-scan"
            onClick={() => {
              dispatch(actionOpenARviewer(arViewerURl));
            }}
          >
            <ARIcon />
          </button>
        ) : (
          <button
            className="btn btn-open-ar"
            onClick={() => {
              logEvent(DID_CLICK_AR_BUTTON, DID_CLICK_AR_BUTTON, {
                productId: productSpotConfig.id
              });
              dispatch(actionOpenARviewer(arViewerURl));
            }}
          >
            <ARIcon />
          </button>
        ))}
      <style jsx>
        {`
          .btn.prev {
            border: none;
          }
          .pagetitle {
            color: #fff;
            letter-spacing: 1px;
            margin: auto;
          }
          .btn-scan,
          .btn-open-ar {
            position: absolute;
            left: 50px;
            top: 0;
            border: none;
          }

          .btn-scan:focus,
          .btn-open-ar:focus {
            box-shadow: none;
            outline: none;
          }

          @media (min-width: 768px) {
            .pagetitle {
              margin: auto;
            }
          }
          .logo {
            width: 80px;
            margin: auto;
          }

          header {
            background: #fff;
            border-bottom: 1px solid #ddd;
          }
          @media (min-width: 768px) {
            .logo {
              width: 100px;
            }
          }
          header :global(svg) {
            fill: #000;
          }
          header :global(.spinner-border) {
            color: #000;
          }
          @media (max-width: 768px) {
            .pagetitle {
              display: none;
            }
          }
          header .btn {
            position: fixed;
            padding: 0;
            z-index: 101;
          }
          header .btn img {
            filter: invert(1);
            width: 100%;
            height: auto;
          }
          header .prev {
            left: 0;
            width: 40px;
            top: 9px;
            color: #fff;
          }
          header .prev :global(svg) {
            transform: rotate(180deg);
            height: 20px;
            width: auto;
          }
          @media (min-width: 768px) {
            header .prev {
              top: 11px;
            }
          }
        `}
      </style>
    </header>
  );
};

export default HeaderChopard;
